import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { EMAIL_REGEX } from "../../utils/regexValidation";
import { useForgetPasswordMutation } from "./authApiSlice";

const ForgetPassword = () => {

    const [forgetPassword, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useForgetPasswordMutation();

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        if (isSuccess) {
            setEmail('')
            // navigate('/dashboard/users')
        }
    }, [isSuccess, navigate])

    const onEmailChanged = e => setEmail(e.target.value)

    const regexFulfilment = [validEmail].every(Boolean) && !isLoading

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        if (regexFulfilment) {
            await forgetPassword({ email })
        }
    }

    const errClass = isError ? "text-danger text-center mx-3" : ""
    // const errClass = isError ? "errmsg" : "offscreen"
    // const validEmailClass = !validEmail ? 'form__input--incomplete' : ''

    const content = (
        <>
            { isSuccess ? (
                <main className="container">
                    <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center bg-body-tertiary">
                        <div className="p-lg-5 mx-auto">
                            <h1>Notifications</h1>
                            <hr />
                            <div className="row pt-5">
                                <h5>Veuillez cliquer sur le lien réinitialisation du mot de passe que nous vous avons envoyé à votre courriel</h5>
                                <p>
                                    Un courriel contenant un lien de réinitialisation du mot de passe vous a été envoyé à votre adresse courriel. Veuillez cliquer sur le lien pour réinitialiser le mot de passe de votre compte. Si vous n'avez pas reçu le courriel d'ici quelques minutes, veuillez vérifier votre dossier spam.
                                </p>
                                <hr />
                                <h5>Please click the activation link we sent to your email</h5>
                                <p>
                                    An email has been sent to your email address containing an activation link. Please click on the link to reset your account password. If you do not receive the email within a few minutes, please check your spam folder.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            ) : (
                <main className="py-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-8">
                            <div className="card">
                                <div className="card-header pt-3">
                                    <h3>Mot de passe oublié?</h3>
                                    <p>Inscrivez le courriel associée à votre compte ici chez Ongle Jessica. Dans les prochaines minutes, vous recevrez un courriel contenant un lien pour réinitialiser votre mot de passe.</p>
                                </div>
                                <br />
                                <div className={errClass}>
                                    <h6>{error?.data?.message}</h6>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={onSubmitHandler}>
                                        <div className="form-floating mb-3">
                                            <input 
                                                type="email"
                                                className={`form-control form-control-lg ${!email ? '' : validEmail ? 'is-valid' : 'is-invalid'}`}
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={onEmailChanged}
                                                autoComplete="off"
                                                placeholder="Adresse courriel"
                                                required
                                            />
                                            <label htmlFor="email">Adresse courriel</label>
                                        </div>
                                        <br />
                                        <div className="d-grid gap-2">
                                            <button
                                                className="btn btn-lg btn-danger text-uppercase"
                                                disabled={!regexFulfilment}
                                            >
                                                Envoyer
                                            </button>
                                            <hr />
                                            <Link to='/login' className="btn btn-lg btn-light">Annuler</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    )

    return content
}
export default ForgetPassword