import { Link, NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useSelector } from "react-redux";
import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import logo from '../images/logo.png'

function Header() {
    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation();

    const { name, isManager, isAdmin } = useAuth();
    const { token } = useSelector((state) => state.auth);
    const { cartItems } = useSelector((state) => state.cart);
    const { cardItems } = useSelector((state) => state.card);

    const logoutButton = (
        <button
            className="dropdown-item"
            title="Logout"
            onClick={sendLogout}
        >
            Logout
        </button>
    )

    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg navbar-light shadow text-uppercase">
                <div className="container-fluid d-flex justify-content-between align-items-center">

                    <Link to='/' className="navbar-brand align-sefl-center">
                        <img className="img-fluid rounded" style={{height:45}} src={logo} alt='Logo' />
                    </Link>
                   
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navMain" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navMain">
                        <div className="flex-fill">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink to='/reservation-location' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>Rendez-vous</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/helps' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>Aide</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to='/reservation-panier' 
                                        className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}
                                    >
                                        {cardItems.length > 0 && (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="30" fill="currentColor" className="bi bi-cart2 text-dark" viewBox="0 0 18 18">
                                                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
                                                </svg>
                                    
                                                <div style={{marginLeft: "20px", marginTop: "-36px"}}>
                                                    <span className="badge bg-warning text-dark rounded-pill">
                                                        <span className="fs-6">
                                                            {cardItems.reduce((a, c) => a + c.qty, 0)}
                                                        </span>
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="navbar justify-content-start d-inline-flex d-flex align-items-start">
                            <ul className="navbar-nav ms-auto list-group">
                                {
                                    token ? (
                                        <li className="nav-item dropdown">
                                            <button id="navbarDropdown" className="nav-link dropdown-toggle btn btn-outline-primary fw-bold" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {name} <span className="caret"></span>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                                 <Link to='/dashboard' className="dropdown-item">Dashboard</Link>
                                                <div className="dropdown-divider"></div>
                                                <Link to='/dashboard/profile' className="dropdown-item">Profile</Link>
                                                <div className="dropdown-divider"></div>
                                                {logoutButton}
                                            </div>
                                        </li>
                                    ) : (
                                        <>
                                            <li className="nav-item">
                                                <Link to='/login' className="btn btn-outline-primary rounded-5 me-2">Se connecter</Link>
                                            </li>
                                            <br />
                                            <li className="nav-item">
                                                <Link to='/register' className="btn btn-primary rounded-5 opacity-75">S'inscrire</Link>
                                            </li>
                                        </>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header