import { memo } from 'react';
import { useGetPublicServicesQuery } from './servicesApiSlice';

const ServicePublicSelect = ({ serviceId }) => {

    const { service } = useGetPublicServicesQuery("publicServices", {
        selectFromResult: ({ data }) => ({
            service: data?.entities[serviceId]
        }),
    })

    if (service) {
        // const handleEdit = () => navigate(`/service/${serviceId}`)
        // const reserveServiceHandler = async(service) => {
        //     dispatch(saveReservationService({
        //         serviceId: service._id,
        //         titre: service.titre,
        //         duration: service.duration,
        //         price: service.price,
        //         capacity: service.capacity,
        //         qty
        //     }))
        //     navigate ('/reservation-service')
        // }


        return (
            <>
                <option
                    value={service._id}
                    required
                >
                    {service.titre} (${service.price} | {service.duration} min)
                </option>
                <hr />
            </>
        )

    } else return null
}

const memoizedServicePublicSelect = memo(ServicePublicSelect)

export default memoizedServicePublicSelect