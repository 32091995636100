import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAddNewUserMutation } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../config/roles";

const USER_REGEX = /^[A-z]{3,20}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/

const NewUserForm = () => {

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation();

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [roles, setRoles] = useState(["Client"]);

    useEffect(() => {
        setValidName(USER_REGEX.test(name))
    }, [name])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess) {
            setName('')
            setEmail('')
            setPassword('')
            setRoles([])
            navigate('/dashboard/users')
        }
    }, [isSuccess, navigate])

    const onNameChanged = e => setName(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions, //HTMLCollection 
            (option) => option.value
        )
        setRoles(values)
    }

    const canSave = [roles.length, validName, validPassword].every(Boolean) && !isLoading

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewUser({ name, email, password, roles })
            // await createUser({ name, password, roles })
        }
    }

    const options = Object.values(ROLES).map(role => {
        return (
            <option key={role} value={role}>{role}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validUserClass = !validName ? 'form__input--incomplete' : ''
    const validPwdClass = !validPassword ? 'form__input--incomplete' : ''
    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''


    const content = (
        <>
            <main className="container">
                <p className={errClass}>{error?.data?.message}</p>
                <Link to={'/dashboard/users'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <h2>Add New User</h2>
                <form className="form" onSubmit={onSaveUserClicked}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="name">Username: <span className="nowrap">[3-20 letters]</span></label>
                        <input
                            className={`form-control form__input ${validUserClass}`}
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="off"
                            value={name}
                            onChange={onNameChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">Email: <span className="nowrap">[3-20 letters]</span></label>
                        <input
                            className={`form-control form__input ${validUserClass}`}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="off"
                            value={email}
                            onChange={onEmailChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form__label" htmlFor="password">Password: <span className="nowrap">[4-12 chars incl. !@#$%]</span></label>
                        <input
                            className={`form-control form__input ${validPwdClass}`}
                            id="password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={onPasswordChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="roles">ASSIGNED ROLES:</label>
                        <select
                            id="roles"
                            name="roles"
                            className={`form-select form__select ${validRolesClass}`}
                            multiple={true}
                            size="4"
                            value={roles}
                            onChange={onRolesChanged}
                        >
                            {options}
                        </select>
                    </div>
                    <div className="form__title-row">
                        <div className="form__action-buttons">
                            <button
                                className="btn btn-primary"
                                title="Save"
                                disabled={!canSave}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </main>
        </>
    )

    return content
}
export default NewUserForm