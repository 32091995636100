import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { PASSWORD_REGEX } from "../../utils/regexValidation";
// import { NAME_REGEX, EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/regexValidation";
import useAuth from '../../hooks/useAuth';
import { useUpdateProfileMutation } from "./authApiSlice";
import './Profile.module.css';
import LoadingSpinner from "../../components/LoadingSpinner";


const Profile = () => {
    const [updateProfile, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateProfileMutation();

    const { _id, name, email } = useAuth();

    const navigate = useNavigate();

    // const [name, setName] = useState('');
    // const [validName, setValidName] = useState(false);
    // const [nameFocus, setNameFocus] = useState(false);
    
    // const [email, setEmail] = useState('');
    // const [validEmail, setValidEmail] = useState(false);
    // const [emailFocus, setEmailFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatchPassword, setValidMatchPassword] = useState(false);
    const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);

    useEffect(() => {
        setValidPassword(PASSWORD_REGEX.test(password))
        setValidMatchPassword(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {
        if (isSuccess) {
            setPassword('')
            setMatchPassword('')
        }
    }, [isSuccess])

    // const regexFulfilment = [validName, validEmail, validPassword, validMatchPassword].every(Boolean) && !isLoading
    const regexFulfilment = [validPassword, validMatchPassword].every(Boolean) && !isLoading

    const updateProfileHandler = async (e) => {
        e.preventDefault();
        if (regexFulfilment) {
            await updateProfile({ id: _id, name, email, password })
        }
    }

    return (
        <>
            {isLoading && <LoadingSpinner />}
            <main className="container">
                <section>
                    <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center">
                        <div className="mx-auto">
                            <h1 className="mb-5">Profile</h1>
                            <form onSubmit={updateProfileHandler}>
                                <div className="form-floating mb-3">
                                    <input 
                                        type="text" 
                                        className="form-control form-control-lg"
                                        id="name"
                                        name="name"
                                        value={name}
                                        // onChange={(e) => setName(e.target.value)}
                                        autoComplete="off"
                                        placeholder="Votre nom"
                                        required
                                        disabled
                                        // aria-invalid={validName ? "false" : "true"}
                                        // aria-describedby="namenote"
                                        // onFocus={() => setNameFocus(true)}
                                        // onBlur={() => setNameFocus(false)}
                                    />
                                    <label htmlFor="name">Votre nom</label>
                                    {/* <div id="namenote" className={nameFocus && !validName ? "invalid-feedback" : "visually-hidden"}>
                                        Veuillez spécifier votre prénom et nom en characters.
                                    </div> */}
                                </div>
                                <div className="form-floating mb-3">
                                    <input 
                                        type="email"
                                        className="form-control form-control-lg"
                                        id="email"
                                        name="email"
                                        value={email}
                                        // onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="off"
                                        placeholder="Adresse courriel"
                                        required
                                        disabled
                                        // aria-invalid={validEmail ? "false" : "true"}
                                        // aria-describedby="emailnote"
                                        // onFocus={() => setEmailFocus(true)}
                                        // onBlur={() => setEmailFocus(false)}
                                    />
                                    <label htmlFor="email">Adresse courriel</label>
                                    {/* <div id="emailnote" className={emailFocus && !validEmail ? "invalid-feedback" : "visually-hidden"}>
                                        Veuillez spécifier un courriel valide.
                                    </div> */}
                                </div>
                                <div className="mb-3">
                                    <fieldset>
                                        <legend className="h5">Modifier le mot de passe ?</legend>
                                    </fieldset>
                                </div>
                                {
                                    isError && (
                                        <div className="d-grid gap-2 mb-5">
                                            <button
                                                className="btn btn-lg btn-warning"
                                            >
                                                <h3>{error?.data?.message}</h3>
                                            </button>
                                        </div>
                                     )
                                }
                                { isSuccess ? 
                                    (
                                        <div className="mt-3">
                                            <h3>Mot de passe mis à jour avec succès!</h3>
                                            <h5>Password updated successfully!</h5>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="form-floating mb-3">
                                                <input 
                                                    type="password"
                                                    className={`form-control form-control-lg ${!password ? '' : validPassword ? 'is-valid' : 'is-invalid'}`}
                                                    id="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Mot de passe"
                                                    required
                                                    aria-invalid={validPassword ? "false" : "true"}
                                                    aria-describedby="pwdnote"
                                                    onFocus={() => setPasswordFocus(true)}
                                                    onBlur={() => setPasswordFocus(false)}
                                                />
                                                <label htmlFor="password">Nouveau mot de passe</label>
                                                <div id="pwdnote" className={passwordFocus && !validPassword ? "invalid-feedback" : "visually-hidden"}>
                                                    Veuillez entrer un mot de passe entre 8 et 24 caractères.<br />
                                                    Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial. Caractères spéciaux requis sont: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                                </div>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input 
                                                    type="password"
                                                    className={`form-control form-control-lg ${!matchPassword ? '' : validMatchPassword && matchPassword ? 'is-valid' : 'is-invalid'}`}
                                                    id="password2"
                                                    name="password2"
                                                    value={matchPassword}
                                                    onChange={(e) => setMatchPassword(e.target.value)}
                                                    placeholder="Confirmer mot de passe"
                                                    required
                                                    aria-invalid={validMatchPassword ? "false" : "true"}
                                                    aria-describedby="pwd2note"
                                                    onFocus={() => setMatchPasswordFocus(true)}
                                                    onBlur={() => setMatchPasswordFocus(false)}
                                                />
                                                <label htmlFor="password2">Confirmer nouveau mot de passe</label>
                                                <div id="pwd2note" className={matchPasswordFocus && !validMatchPassword ? "invalid-feedback" : "visually-hidden"}>
                                                    La confirmation du mot de passe doit être identique au mot de passe entré.
                                                </div>
                                            </div>
                                            <br />
                                            <div className="d-grid gap-2">
                                                <button
                                                    className="btn btn-lg btn-danger text-uppercase"
                                                    disabled={!regexFulfilment}
                                                >
                                                    Enregistrer
                                                </button>
                                            </div>
                                        </>
                                    )
                                }
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
};

export default Profile;