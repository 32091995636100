import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewNoteMutation } from "./notesApiSlice";

const NewNoteForm = ({ users }) => {

    const [addNewNote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewNoteMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess) {
            setTitle('')
            setText('')
            setUserId('')
            navigate('/dashboard/notes')
        }
    }, [isSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onTextChanged = e => setText(e.target.value)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, text, userId].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewNote({ user: userId, title, text })
        }
    }

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}
            >{user.name}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !text ? "form__input--incomplete" : ''

    const content = (
        <>
            <main className="container">
                <p className={errClass}>{error?.data?.message}</p>
                <Link to={'/dashboard/notes'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <h2 className="pt-3">Add New Note</h2>
                <form className="form" onSubmit={onSaveNoteClicked}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="title">Title:</label>
                        <input
                            className={`form-control form__input ${validTitleClass}`}
                            id="title"
                            name="title"
                            type="text"
                            autoComplete="off"
                            value={title}
                            onChange={onTitleChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="text">Text:</label>
                        <textarea
                            className={`form-control form__input form__input--text ${validTextClass}`}
                            id="text"
                            name="text"
                            value={text}
                            onChange={onTextChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label form__checkbox-container" htmlFor="name">Assigned to:</label>
                        <select
                            id="name"
                            name="name"
                            className="form-select"
                            value={userId}
                            onChange={onUserIdChanged}
                        >
                            {options}
                        </select>
                    </div>
                    <div className="mb-3">
                        <button
                            className="btn btn-primary"
                            title="Save"
                            disabled={!canSave}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </main>
        </>
    )

    return content
}

export default NewNoteForm