import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetPublicServicesByCategoryQuery, } from "./servicesApiSlice";
import Service from "./ServicePublicByCategoryItem";
import ReservationSteps from '../../components/ReservationSteps';
import LoadingSpinner from "../../components/LoadingSpinner";
// import noimage from '../../images/noimage.jpg';
import ServiceCategoryList from '../reservations/ServiceCategoryList';
// import ServiceCategoryList from './ServiceCategoryList';

const ServicePublicByCategory = () => {

    const {
        data: services,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPublicServicesByCategoryQuery();

    const { reservationServiceCategory } = useSelector((state) => state.card);

    // const [search, setSearch] = useState('')
    const [categoryReserved, setCategoryReserved] = useState(reservationServiceCategory?.categoryReserved || 'manicure');

    const categoryClickHandler = (value) => {
        setCategoryReserved(value);
    }

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {

        const cardContent = services?.length && services.filter(service => (service.category === categoryReserved)).map((service) => <Service key={service.id} service={service} />)

        content = (
            <main className="container py-5">
                <ReservationSteps step1 step2 />
                <div className="text-center py-3">
                    <h1>Sélectionnez le service souhaité.</h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Nous offrons des services professionnels, tels que la manucure, la pédicure et la pose d'ongles, dans une ambiance chaleureuse.
                        </p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <ServiceCategoryList 
                                className = {`${categoryReserved === "manicure" ? 'btn-primary' : 'btn-outline-primary'}`} 
                                value="manicure" 
                                onClick={categoryClickHandler} 
                            />
                            <ServiceCategoryList 
                                className = {`${categoryReserved === "pedicure" ? 'btn-primary' : 'btn-outline-primary'}`} 
                                value="pedicure" 
                                onClick={categoryClickHandler} 
                            />
                            <ServiceCategoryList 
                                className = {`${categoryReserved === "other" ? 'btn-primary' : 'btn-outline-primary'}`} 
                                value="other" 
                                onClick={categoryClickHandler} 
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="justify-content-center">
                    {cardContent}
                </div>
                <hr />
                <div className="col-lg-6 mx-auto">
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <ServiceCategoryList 
                            className = {`${categoryReserved === "manicure" ? 'btn-primary' : 'btn-outline-primary'}`} 
                            value="manicure" 
                            onClick={categoryClickHandler} 
                        />
                        <ServiceCategoryList 
                            className = {`${categoryReserved === "pedicure" ? 'btn-primary' : 'btn-outline-primary'}`} 
                            value="pedicure" 
                            onClick={categoryClickHandler} 
                        />
                        <ServiceCategoryList 
                            className = {`${categoryReserved === "other" ? 'btn-primary' : 'btn-outline-primary'}`} 
                            value="other" 
                            onClick={categoryClickHandler} 
                        />
                    </div>
                </div>
            </main>
        )
    }

    return content
}
export default ServicePublicByCategory