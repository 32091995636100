import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../config/roles";
import { NAME_REGEX, EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/regexValidation";

// const USER_REGEX = /^[A-z]{3,20}$/
// const NAME_REGEX = /^[a-zA-Z]+ [a-zA-Z ]+$/;
// const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/
// const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const EditUserForm = ({ user }) => {

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const navigate = useNavigate()

    const [name, setName] = useState(user.name)
    const [validName, setValidName] = useState(false)
    const [nameFocus, setNameFocus] = useState(false)

    const [email, setEmail] = useState(user.email)
    const [validEmail, setValidEmail] = useState(false)
    const [emailFocus, setEmailFocus] = useState(false)

    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [passwordFocus, setPasswordFocus] = useState(false)

    const [roles, setRoles] = useState(user.roles)
    const [status, setStatus] = useState(user.status)

    // useEffect(() => {
    //     setValidName(NAME_REGEX.test(name))
    // }, [name])

    useEffect(() => {
        setValidName(NAME_REGEX.test(name))
        setValidEmail(EMAIL_REGEX.test(email))
    }, [name, email])

    useEffect(() => {
        setValidPassword(PASSWORD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setName('')
            setEmail('')
            setPassword('')
            setRoles([])
            navigate('/dashboard/users')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onNameChanged = e => setName(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setRoles(values)
    }

    const onStatusChanged = () => setStatus(prev => !prev)

    const onSaveUserClicked = async (e) => {
        if (password) {
            await updateUser({ id: user.id, name, email, password, roles, status })
        } else {
            await updateUser({ id: user.id, name, email, roles, status })
        }
    }

    const onDeleteUserClicked = async () => {
        await deleteUser({ id: user.id, name })
    }

    const options = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })

    let regexFulfilment
    if (password) {
        regexFulfilment = [roles.length, validName, validPassword].every(Boolean) && !isLoading
    } else {
        regexFulfilment = [roles.length, validName].every(Boolean) && !isLoading
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    // const validUserClass = !validName ? 'form__input--incomplete' : ''
    // const validPwdClass = password && !validPassword ? 'form__input--incomplete' : ''
    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <main className="container">
                <p className={errClass}>{errContent}</p>
                <Link to={'/dashboard/users'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <div className="row py-3">
                    <div className="col-md-6">
                        <h2>Edit User</h2>
                        <form className="form" onSubmit={e => e.preventDefault()}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="name">Name: <span className="nowrap">[3-20 letters]</span></label>
                                <input
                                    className={`form-control ${!name ? '' : validName ? 'is-valid' : 'is-invalid'}`}
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    value={name}
                                    onChange={onNameChanged}
                                    required
                                    aria-invalid={validName ? "false" : "true"}
                                    aria-describedby="namenote"
                                    onFocus={() => setNameFocus(true)}
                                    onBlur={() => setNameFocus(false)}
                                />
                                 <div id="namenote" className={nameFocus && !validName ? "invalid-feedback" : "visually-hidden"}>
                                    Veuillez spécifier votre prénom et nom en characters.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="email">Email: <span className="nowrap">[8-24 letters]</span></label>
                                <input
                                    className={`form-control ${!email ? '' : validEmail ? 'is-valid' : 'is-invalid'}`}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="off"
                                    value={email}
                                    onChange={onEmailChanged}
                                    disabled
                                    aria-invalid={validEmail ? "false" : "true"}
                                    aria-describedby="emailnote"
                                    onFocus={() => setEmailFocus(true)}
                                    onBlur={() => setEmailFocus(false)}
                                />
                                 <div id="emailnote" className={emailFocus && !validEmail ? "invalid-feedback" : "visually-hidden"}>
                                    Veuillez spécifier un courriel valide.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="password">
                                    Password: <span className="nowrap">[empty = no change]</span> <span className="nowrap">[8-24 chars incl. !@#$%]</span></label>
                                <input
                                    className={`form-control ${!password ? '' : validPassword ? 'is-valid' : 'is-invalid'}`}
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={password}
                                    onChange={onPasswordChanged}
                                    aria-invalid={validPassword ? "false" : "true"}
                                    aria-describedby="pwdnote"
                                    onFocus={() => setPasswordFocus(true)}
                                    onBlur={() => setPasswordFocus(false)}
                                />
                                 <div id="pwdnote" className={passwordFocus && !validPassword ? "invalid-feedback" : "visually-hidden"}>
                                    Veuillez entrer un mot de passe entre 8 et 24 caractères.<br />
                                    Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial. Caractères spéciaux requis sont: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-check-label me-2" htmlFor="user-status">STATUS (ACTIVE?):</label>
                                <input
                                    className="form-check-input"
                                    id="user-status"
                                    name="user-status"
                                    type="checkbox"
                                    checked={status}
                                    onChange={onStatusChanged}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="roles">ASSIGNED ROLES:</label>
                                <select
                                    id="roles"
                                    name="roles"
                                    className={`form-select form__select ${validRolesClass}`}
                                    multiple={true}
                                    size="4"
                                    value={roles}
                                    onChange={onRolesChanged}
                                >
                                    {options}
                                </select>
                            </div>

                            <div className="form__title-row">
                                <div className="form__action-buttons">
                                    <button
                                        className="btn btn-primary"
                                        title="Save"
                                        onClick={onSaveUserClicked}
                                        disabled={!regexFulfilment}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <h2>Delete User</h2>
                        <br />
                        <button className="btn btn-info" data-bs-toggle="modal" data-bs-target={`#delete${user._id}`}>
                            Do you want to DELETE this user? Click here
                        </button>
                            
                        <div className="modal fade" id={`delete${user._id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-100" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-info">
                                        <h4 className="modal-title" id="staticBackdropLabel">User Delele Confirmation</h4>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <h5>Are you sure you want to <span className="text-uppercase">DELETE</span> the user with the name <span className="text-uppercase">{user.name}</span>?</h5>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger" title="Delete" onClick={onDeleteUserClicked}>
                                            Delete
                                        </button>
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )

    return content
}
export default EditUserForm