export const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCard = (state) => {
    state.itemsPrice = addDecimals(state.cardItems.reduce((acc, item) => acc + item.price * item.qty, 0));;

    state.totalPrice = (Number(state.itemsPrice)).toFixed(2);

    localStorage.setItem('card', JSON.stringify(state));

    return state;
}