import { Link } from "react-router-dom";
import { useGetNotesQuery } from "./notesApiSlice"
import Note from "./Note"
import useAuth from "../../hooks/useAuth"

const NotesList = () => {

    const { name, isManager, isAdmin } = useAuth()

    const {
        data: notes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNotesQuery('notesList', {
        // 1 second = 1000
        pollingInterval: 30 * 60 * 1000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <p>Loading...</p>

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {
        const { ids, entities } = notes

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            // filteredIds = ids.filter(noteId => entities[noteId].email === email)
            filteredIds = ids.filter(noteId => entities[noteId].name === name)
        }

        const tableContent = ids?.length && filteredIds.map(noteId => <Note key={noteId} noteId={noteId} />)

        content = (
            <main className="container py-3">
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>List of Notes</h2>
                        </div>
                        <div>
                            <Link to='/dashboard/notes/new' className="btn btn-primary">
                                Add New Note
                            </Link>
                        </div>
                    </div>
                </section>
                <hr />
                <table className="table striped bordered hover responsive">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th note__title">Title</th>
                            <th scope="col" className="table__th note__created">Created</th>
                            <th scope="col" className="table__th note__updated">Updated</th>
                            <th scope="col" className="table__th note__username">Owner</th>
                            <th scope="col" className="table__th note__status">Status</th>
                            <th scope="col" className="table__th note__edit">Edit</th>
                        </tr>

                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </main>
        )
    }

    return content
}
export default NotesList