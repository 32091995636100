import { createSlice } from "@reduxjs/toolkit";
import { updateCard } from "../../utils/cardUtils";

// const initialState = localStorage.getItem('card') ? JSON.parse(localStorage.getItem('card')) : { reservationService: {}, reservationLocation: {}, reservationDate: {}, reservationClientInfo: {} };

const initialState = localStorage.getItem('card') ? JSON.parse(localStorage.getItem('card')) : { 
    cardItems: [], 
    reservationLocation: {},
    reservationServiceCategory: {},
    reservationService: {},
    reservationDate: {},
    reservationClientInfo: {} 
};

const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        saveReservationService: (state, action) => {
            const item = action.payload
            const existItem = state.cardItems?.find((x) => x.serviceId === item.serviceId);
            
            if (existItem) {
                state.cardItems = state.cardItems.map((x) => x.serviceId === existItem.serviceId ? item : x)
            } else {
                state.cardItems = [...state.cardItems, item]
            }

            return updateCard(state, item);
        },

        removeFromCard: (state, action) => {
            state.cardItems = state.cardItems.filter((item) => item.serviceId !== action.payload);
            return updateCard(state);
        },

        quantityIncrement: (state, action) => {
            state.cardItems = state.cardItems.map((item) => item.serviceId === action.payload ? ({ ...item, qty: item.qty + 1 }) : item);
            return updateCard(state);
        },

        quantityDecrement: (state, action) => {
            state.cardItems = state.cardItems.map((item) => item.serviceId === action.payload ? ({ ...item, qty: item.qty - 1 }) : item).filter(item => item.qty > 0); // Remove item if quantity becomes zero
            return updateCard(state);
        },

        clearCardItems: (state, action) => {
            state.cardItems = [];
            return updateCard(state);
        },

        saveReservationLocation: (state, action) => {
            state.reservationLocation = action.payload;
            return updateCard(state);
        },

        saveReservationServiceCategory: (state, action) => {
            state.reservationServiceCategory = action.payload;
            return updateCard(state);
        },

        saveReservationDate: (state, action) => {
            state.reservationDate = action.payload;
            return updateCard(state);
        },

        saveReservationClientInfo: (state, action) => {
            state.reservationClientInfo = action.payload;
            return updateCard(state);
        },
    },
    extraReducers: (builder) => {

    }
});

export const { 
    saveReservationService, 
    removeFromCard, 
    clearCardItems,
    quantityIncrement,
    quantityDecrement,
    saveReservationLocation,
    saveReservationServiceCategory,
    saveReservationDate, 
    saveReservationClientInfo 
} = cardSlice.actions;

export default cardSlice.reducer;