import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function Layout() {
    const location = useLocation();

    const renderNavbar = () => {
        if (location.pathname === '/about') {
          return;
        } else {
          return <Header />;
        }
    };
    
    return (
        <>
            {/* <Header /> */}
            {renderNavbar()}
            <div>
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default Layout;