import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const productsAdapter = createEntityAdapter({
    // Assume IDs are stored in a field other than `product.id` - default implementation
    selectId: (product) => product.productId,

    // Keep the "all IDs" array sorted based on product names
    sortComparer: (a, b) => a.name.localeCompare(b.name),
    // sortComparer: (a, b) => (a.status === b.status) ? 0 : a.status ? 1 : -1
})

const initialState = productsAdapter.getInitialState()

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: () => ({
                url: '/orders',
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
        }),

        getPublicProducts: builder.query({
            query: () => ({
                url: '/products/public',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            // transformResponse: responseData => {
            //     const loadedServices = responseData.map(service => {
            //         service.id = service._id
            //         return service
            //     });
            //     return servicesAdapter.setAll(initialState, loadedServices)
            // },
            providesTags: ['Product'],
            // providesTags: (result, error, arg) => {
            //     if (result?.ids) {
            //         return [
            //             { type: 'Service', id: 'LIST' },
            //             ...result.ids.map(id => ({ type: 'Service', id }))
            //         ]
            //     } else return [{ type: 'Service', id: 'LIST' }]
            // }
        }),

        getOrderDetails: builder.query({
            query: (orderId) => ({
                url: `/orders/${orderId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
                // providesTags: ['Service']
                // providesTags: (result, error, id) => {
                //     return [{ type: 'Service', id: id }]
                // },
            }),
            keepUnusedDataFor: 5
        }),

        createOrder: builder.mutation({
            query: (order) => ({
                url: '/orders',
                method: 'POST',
                body: order,
                // body: { ...order }
            }),
            // invalidatesTags: ['Product'],
            // invalidatesTags: [{ type: 'Product', id: "LIST" }],
        }),

        payOrder: builder.mutation({
            query: (orderId, details) => ({
                url: `/orders/${orderId}/pay`,
                method: 'PUT',
                body: { ...details },
            }),
        }),

        deliverOrder: builder.mutation({
            query: (orderId) => ({
                url: `/orders/${orderId}/deliver`,
                method: 'PATCH',
            }),
        }),

        deleteProduct: builder.mutation({
            query: ({ id }) => ({
                url: `/products`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Product', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetOrdersQuery,
    useGetPublicProductsQuery,
    useGetOrderDetailsQuery,
    useCreateOrderMutation,
    useDeliverOrderMutation,
    useDeleteProductMutation,
} = ordersApiSlice;

// returns the query result object
// export const selectProductsResult = productsApiSlice.endpoints.getProducts.select()

// creates memoized selector
// const selectProductsData = createSelector(
//     selectProductsResult,
//     productsResult => productsResult.data
// )

//getSelectors creates these selectors and we rename them with aliases using destructuring
// export const {
//     selectAll: selectAllServices,
//     selectById: selectServiceById,
//     selectIds: selectServiceIds
//     // Pass in a selector that returns the notes slice of state
// } = productsAdapter.getSelectors(state => selectProductsData(state) ?? initialState)