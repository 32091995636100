function TimeSlot(props) {
    return (
        <button 
            className={`btn ${props.className} mx-3 my-2`} 
            onClick={() => props.onClick(props.value)}
            disabled={`${props.disabledButton}`}
        >
            <div>
                {props.value} {''} <span className={`${props.disabledButton ? 'badge text-bg-danger' : 'visually-hidden'}`}>X</span>
            </div>
        </button>
    )
}

export default TimeSlot