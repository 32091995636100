import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useGetOrdersQuery } from "./ordersApiSlice";

function OrderList() {

    const { data: orders, isLoading, isSuccess, isError, error } = useGetOrdersQuery();

    let content

    if (isLoading) content = <LoadingSpinner />

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="text-danger">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {

        content = (
            <main className="container py-3">
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>List of Orders</h2>
                        </div>
                        {/* <div>
                            <Link to='' className="btn btn-primary">
                                Add New Order
                            </Link>
                        </div> */}
                    </div>
                </section>
                <hr />
                <table className="table striped bordered hover responsive">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">User</th>
                            <th scope="col">Date</th>
                            <th scope="col">Total</th>
                            <th scope="col">Paid</th>
                            <th scope="col">Delivered</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders?.map((order, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{order.user && order.user.name}</td>
                                    <td>{order.createdAt.substring(0, 10)}</td>
                                    <td>{order.totalPrice}</td>
                                    <td>
                                        { order.isPaid ? (
                                            order.paidAt.substring(0, 10)
                                        ) : (
                                            <i className="bi bi-x"></i>
                                        )}
                                    </td>
                                    <td>
                                        { order.isDelivered ? (
                                            order.deliveredAt.substring(0, 10)
                                        ) : (
                                            <i className="bi bi-x"></i>
                                        )}
                                    </td>
                                    <td>
                                        <Link to={`/order/${order._id}`}>
                                            <button
                                                className="btn btn-secondary btn-sm"
                                            >
                                                Details
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        }
                        {/* {tableContent} */}
                    </tbody>
                </table>
            </main>
        )
    }

    return content
}

export default OrderList