import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { EMAIL_REGEX } from '../../utils/regexValidation';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';
import usePersist from '../../hooks/usePersist';

// const EMAIL_REGEX = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
// const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PASSWORD_REGEX = /^[A-z0-9!@#$%]{6,24}$/

const Login = () => {
    const userRef = useRef()
    const errRef = useRef()
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const redirect = searchParams.get("redirect") || '/dashboard';

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
        setValidPassword(PASSWORD_REGEX.test(password))
    }, [email, password])


    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const regexFulfilment = [validEmail, validPassword].every(Boolean) && !isLoading
    // const regexFulfilment = [validEmail].every(Boolean) && !isLoading

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ email, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setEmail('')
            setPassword('')
            navigate (redirect, {replace: true})
            // navigate('/dashboard')
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Tous les champs sont requis');
            } else if (err.status === 401) {
                setErrMsg('Le courriel ou le mot de passe que vous avez entré n\'est pas valide.');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setEmail(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    // const errClass = errMsg ? "errmsg" : "offscreen"
    const errClass = errMsg ? "alert alert-warning" : ""

    if (isLoading) return <p>Loading...</p>

    const content = (
        <>
            <main className="py-5">
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-8">
                        <div className="card">
                            <div className="card-header pt-3">
                                <h3>Se connecter</h3>
                            </div>
                            <br />
                            <div ref={errRef} className={errClass} aria-live="assertive">
                                <h6 className="text-center">{errMsg}</h6>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-floating mb-3">
                                        <input
                                            // className="form-control form-control-lg"
                                            className={`form-control form-control-lg ${!email ? '' : validEmail ? '' : 'is-invalid'}`}
                                            type="email"
                                            id="email"
                                            name="email"
                                            ref={userRef}
                                            value={email}
                                            onChange={handleUserInput}
                                            autoComplete="off"
                                            placeholder="Adresse courriel"
                                            required
                                            aria-invalid={validEmail ? "false" : "true"}
                                            aria-describedby="emailnote"
                                            onFocus={() => setEmailFocus(true)}
                                            onBlur={() => setEmailFocus(false)}
                                        />
                                        <label htmlFor="email">Adresse courriel</label>
                                        <div id="emailnote" className={emailFocus && !validEmail ? "invalid-feedback" : "visually-hidden"}>
                                            Veuillez spécifier un courriel valide.
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control form-control-lg"
                                            // className={`form-control form-control-lg ${!password ? '' : validPassword ? '' : 'is-invalid'}`}
                                            type="password"
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={handlePwdInput}
                                            placeholder="Mot de passe"
                                            required
                                            aria-invalid={validPassword ? "false" : "true"}
                                            aria-describedby="pwdnote"
                                            onFocus={() => setPasswordFocus(true)}
                                            onBlur={() => setPasswordFocus(false)}
                                        />
                                        <label htmlFor="password">Mot de passe</label>
                                        <div id="pwdnote" className={passwordFocus && !validPassword ? "invalid-feedback" : "visually-hidden"}>
                                            Veuillez entrer un mot de passe valide.
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-sm-6">
                                            <div className="form-check pt-3">
                                                {/* <input className="form-check-input" type="checkbox" name="remember" id="rememberMe" /> */}
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="persist"
                                                    onChange={handleToggle}
                                                    checked={persist}
                                                />
                                                <label htmlFor="persist" className="form-label"><small>Se souvenir de moi</small></label>
                                                {/* <label htmlFor="rememberMe"><small>Remember Me</small></label> */}
                                            </div>
                                        </div>
                                    
                                        <div className="col-sm-6 mt-2">
                                            <Link to='/password-forget' className="btn btn-link">
                                                <small>Mot de passe oublié?</small>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button 
                                            className="btn btn-lg btn-danger text-uppercase"
                                            disabled={!regexFulfilment}
                                        >
                                            Se connecter
                                        </button>
                                        <hr />
                                        <Link to={redirect ? `/register?redirect=${redirect}` : '/register'} className="btn btn-lg btn-light">Nouveau? Créer un compte</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )

    return content
}

export default Login