import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useGetReservationsQuery } from "./reservationsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
// import { currentDate } from "../../utils/dateUtils";
import ReservationListItem from './ReservationListItem';
import Paginate from '../../components/Paginate';

function ReservationList() {
    const {
        data: reservations,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetReservationsQuery();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [pageNumberLimit, setPageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

    const handleClick = (e) => {
        setCurrentPage(Number(e.target.id));
    }

    const pages = [];
    for (let i =1; i <= Math.ceil(reservations?.length/itemsPerPage); i++) {
        pages.push(i);
    }

    const indexOfLastItem = currentPage*itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = reservations?.slice(indexOfFirstItem, indexOfLastItem);

    // const renderReservationList = currentItems

    const renderPageNumbers = pages.map(number => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li className="page-item" key={number}  >
                    <Link
                        id={number} 
                        className={currentPage === number ? "page-link active" : "page-link"}
                        onClick={handleClick}
                    >
                        {number}
                    </Link>
                </li>
            )
        } else {
            return null;
        }
    });

    const handleNextButton = () => {
        setCurrentPage(currentPage + 1);

        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevButton = () => {
        setCurrentPage(currentPage - 1);

        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    let pageIncrementButton = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementButton = <li className='btn' onClick={handleNextButton}> &hellip; </li>
    }

    let pageDecrementButton = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementButton = <li className='btn' onClick={handlePrevButton}> &hellip; </li>
    }

   

    // <nav aria-label="Page navigation example">
    //     <ul className="pagination">
    //         { [...Array(pages).keys()].map((x) => (
    //             <li className="page-item" key={x + 1}>
    //                 <NavLink
    //                     className={({isActive}) => isActive ? "page-link active" : "page-link"}
    //                     to={`/dashboard/reservations/page/${x + 1}`}
    //                 >
    //                     { x + 1 }
    //                 </NavLink>
    //             </li>   
    //         ))}
    //     </ul>
    // </nav>

    // const { pageNumber } = useParams();
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageOptions, setPageOptions] = useState({
        pageNumber: 1,
        pageSize: 5,
    });

    const [currentPageSize, setCurrentPageSize] = useState(pageOptions.pageSize);



    const [status, setStatus] = useState('');
    const [locationReserved, setLocationReserved] = useState('');
    
    const [search, setSearch] = useState('');

    // useEffect(() => {
    //     if (data) {
    //         const totalRecords = data.totalRecords;
    //         setTotalRecords(totalRecords);
    //     }
    // }, [data]);

    const getPageDetails = () => {
        const dataStartNumber = (pageOptions.pageNumber - 1) * pageOptions.pageSize + 1;
        const dataEndNumber = pageOptions.pageNumber * pageOptions.pageSize;

        return `${dataStartNumber} - ${dataEndNumber < totalRecords ? dataEndNumber : totalRecords} of ${totalRecords}`
    };

    const handlePageOptionChange = (direction, pageSize) => {
        if (direction === "prev") {
            setPageOptions({ pageSize: 5, pageNumber: pageOptions.pageNumber - 1 });
        } else if (direction === "next") {
            setPageOptions({ pageSize: 5, pageNumber: pageOptions.pageNumber + 1 });
        } else if (direction === "change") {
            setPageOptions({
                pageSize: pageSize ? pageSize : 5,
                pageNumber: 1,
            });
        }
    };

    // let checkStatus;
    // if (!status) {
    //     checkStatus =  
    // } else {
    //     checkStatus = status
    // }

    // const handleFilters = (search) => {
    //     let initialReservationItems = [...reservations];
    //     // Search functionality
    //     if (search) {
    //         const searchReservationItems = initialReservationItems;
    //         const initialReservationItems = searchReservationItems.filter((reservation) => 
    //             reservation.reservationClientInfo.client.toLowerCase().includes(search.toLowerCase())
    //         );
    //     }

    //     return initialReservationItems;
    // }

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }
    
    if (isSuccess) {
        content = (
            <main className='container py-3'>
                <section>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h2>Reservations</h2>
                        <div className="d-flex align-items-center w-25">
                            <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Search..."
                                
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                            <span style={{ position: "relative", left: "-27px" }}>
                                <i className="bi bi-search"></i>
                            </span>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            {/* <div className='me-2'>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div> */}
                            <div className="me-2">
                                <select
                                    className="form-select"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option value="" selected>All reservations</option>
                                    <hr />
                                    <option value="present">Reservations d'attend</option>
                                    <hr />
                                    <option value="pending" >Reservations à faire</option>
                                    <hr />
                                    <option value="done">Resrvations fait</option>
                                    <hr />
                                    <option value="cancel">Reservations annullé</option>
                                </select>
                            </div>
                            <div className="me-2">
                                <select
                                    className="form-select"
                                    value={locationReserved}
                                    onChange={(e) => setLocationReserved(e.target.value)}
                                >
                                    <option value="" selected>All locations</option>
                                    <hr />
                                    <option value="1" >La Pyramide</option>
                                    <hr />
                                    <option value="2">Plaza Laval</option>
                                </select>
                            </div>
                            <div>
                                <Link to='/dashboard/reservations/new' className="btn btn-outline-secondary">
                                    Add
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    { isLoading ? <LoadingSpinner /> : isError ? {error} : (
                        <table className="table striped bordered hover responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Performer</th>
                                    {/* <th scope="col">Comment</th> */}
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {   
                                    (currentItems?.length > 0 && !status) ?
                                    ((currentItems.filter(reservation => (
                                        ((locationReserved === '1' || locationReserved === "2") ? reservation.reservationLocation.locationReserved === locationReserved : reservation.reservationLocation.locationReserved !== locationReserved) && (reservation.reservationClientInfo.client?.toLowerCase().includes(search.toLowerCase()) || reservation.reservationClientInfo.phone?.toLowerCase().includes(search))
                                    )).sort((a, b) => b.reservationDate.dateReserved.replaceAll('-', '') - a.reservationDate.dateReserved.replaceAll('-', '')).map((reservation, index) => (
                                            <ReservationListItem reservation={reservation} key={index} />
                                        ))
                                    )) : (
                                        (currentItems.filter(reservation => (
                                            reservation.status === status &&
                                            ((locationReserved === '1' || locationReserved === "2") ? reservation.reservationLocation.locationReserved === locationReserved : reservation.reservationLocation.locationReserved !== locationReserved) && (reservation.reservationClientInfo.client?.toLowerCase().includes(search.toLowerCase()) || reservation.reservationClientInfo.phone?.toLowerCase().includes(search))
                                        )).sort((a, b) => b.reservationDate.dateReserved.replaceAll('-', '') - a.reservationDate.dateReserved.replaceAll('-', '')).map((reservation, index) => (
                                                <ReservationListItem reservation={reservation} key={index} />
                                            ))
                                        )
                                    )
                                }
                            </tbody>
                        </table>
                    )}
                </section>
                <section className='pt-3'>
                    <div className='d-flex mx-5 justify-content-center'>
                        <div>Rows per page:</div>
                        <div className='mx-3'>
                            <select 
                                className='form-select'
                                value={itemsPerPage}
                                // onChange={(e) => handleLoadMore(Number(e.target.value))}
                                onChange={(e) => setItemsPerPage(e.target.value)}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value={75}>75</option>
                            </select>
                        </div>
                        <nav>
                            <ul className='pagination'>
                                <button
                                    className='btn btn-outline-primary px-3 mx-2'
                                    disabled={currentPage === pages[0] ? true : false}
                                    onClick={handlePrevButton}
                                >
                                    <i className='bi bi-chevron-left'></i>
                                </button>
                                {pageDecrementButton}
                                {renderPageNumbers}
                                {pageIncrementButton}
                                <button
                                    className='btn btn-outline-primary px-3 mx-2'
                                    disabled={currentPage === pages[pages.length - 1] ? true : false}
                                    onClick={handleNextButton}
                                >
                                    <i className='bi bi-chevron-right'></i>
                                </button>
                            </ul>
                        </nav>
                    </div>
                </section>
                {/* <Paginate pages={data.pages} page={data.currentPage} /> */}
                {/* <div className='d-flex mx-5 justify-content-end align-items-center'>
                    <div>Rows per page:</div>
                    <div>
                        <select 
                            className='form-select mx-2'
                            value={currentPageSize}
                            onChange={(e) => {
                                handlePageOptionChange("change", Number(e.target.value));
                                setCurrentPageSize(Number(e.target.value));
                            }}
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value={75}>75</option>
                        </select>
                    </div>
                    {getPageDetails()}
                    <button
                        className='btn btn-outline-primary px-3 mx-2'
                        disabled={pageOptions.pageNumber === 1}
                        onClick={() => handlePageOptionChange("prev")}
                    >
                        <i className='bi bi-chevron-left'></i>
                    </button>
                    <button
                        className='btn btn-outline-primary px-3 mx-2'
                        disabled={pageOptions.pageNumber * pageOptions.pageSize >= totalRecords}
                        onClick={() => handlePageOptionChange("next")}
                    >
                        <i className='bi bi-chevron-right'></i>
                    </button>
                </div> */}
            </main>
        )
    }

    return content
}

export default ReservationList;