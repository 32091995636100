import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveReservationLocation } from "./cardSlice";
import ReservationSteps from '../../components/ReservationSteps';
import PyramideShowcase from '../../components/carousels/locations/PyramideShowcase';
import PlazaShowcase from '../../components/carousels/locations/PlazaShowcase';
import LocationList from './LocationList';

function ReservationLocation() {
  const { reservationLocation } = useSelector((state) => state.card);
  // const [locationReserved, setLocationReserved] = useState(reservationLocation?.locationReserved || '');
  const [locationReserved, setLocationReserved] = useState(reservationLocation?.locationReserved || '');
  // const [validLocation, setValidLocation] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locationClickHandler = (value) => {
    // alert('handle location click' + value);
    setLocationReserved(value);
  }

  const locationDisplay = () => {
    if (locationReserved === "1") {
      return (
        <div>
          La Pyramide
        </div>
      )
    } else if (locationReserved === "2") {
      return (
        <div>
          Plaza Laval
        </div>
      )
    } else {
      return (
        <div>
          Veuillez spécifier un adresse
        </div>
      )
    }
  }

  const locationSubmitHandler = (e) => {
    e.preventDefault();
    if(locationReserved) {
      dispatch(saveReservationLocation({ locationReserved }))
    }
    
    navigate('/reservation-services')
    // navigate('/reservation-date')
    // navigate('/reservation-service-category')
  }

  return (
      <div className="container py-5">
        <ReservationSteps step1 />
        <div className="text-center py-3">
          <h1>Sélectionnez l'adresse qui vous convient.</h1>
          <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">
                Nos salons sont situés dans des zones centrales, offrant un accès facile en voiture, en transports en commun et à pied.
              </p>
            
              {/* <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                  <ServiceCategoryList 
                      className = {`${categoryReserved === "manicure" ? 'btn-primary' : 'btn-outline-primary'}`} 
                      value="manicure" 
                      onClick={categoryClickHandler} 
                  />
                  <ServiceCategoryList 
                      className = {`${categoryReserved === "pedicure" ? 'btn-primary' : 'btn-outline-primary'}`} 
                      value="pedicure" 
                      onClick={categoryClickHandler} 
                  />
                  <ServiceCategoryList 
                      className = {`${categoryReserved === "other" ? 'btn-primary' : 'btn-outline-primary'}`} 
                      value="other" 
                      onClick={categoryClickHandler} 
                  />
              </div> */}
          </div>
      </div>
      <hr />
      <div className="row align-items-md-stretch">
          <div className="col-md-6 mb-4">
            <div className="h-100 p-lg-5 p-3 bg-light border rounded">
              <div className="m-1 pb-3">
                <PyramideShowcase />
              </div>
              <h3>La Pyramide</h3>
              <p>Adresse: 2360 chemin Ste-Foy, Québec, G1V 4H2</p>
              <LocationList className={`${locationReserved === "1" ? 'btn-primary' : 'btn-outline-primary'}`} value="1" onClick={locationClickHandler} />
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="h-100 p-lg-5 p-3 bg-light border rounded-3">
              <div className="m-1 pb-3">
                <PlazaShowcase />
              </div>
              <h3>Plaza Laval</h3>
              <p>Adresse: 2750 chemin Ste-Foy, Québec, G1V 1V6</p>
              <LocationList className={`${locationReserved === "2" ? 'btn-primary' : 'btn-outline-primary'}`} value="2" onClick={locationClickHandler} />
            </div>
          </div>
      </div>
      <form onSubmit={locationSubmitHandler}>
        <button 
          type='submit' 
          className="btn btn-info btn-lg w-100"
          disabled={!locationReserved}
          >
            {locationDisplay()} <span className='text-uppercase'>Continuer</span>
        </button>
      </form>
    </div>
  )
}

export default ReservationLocation