import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewHelpMutation } from "./helpsApiSlice";

const NewHelpForm = ({ users }) => {

    const [addNewHelp, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewHelpMutation()

    const navigate = useNavigate()

    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')
    const [status, setStatus] = useState('private')
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess) {
            setQuestion('')
            setAnswer('')
            setStatus('')
            setUserId('')
            navigate('/dashboard/helps')
        }
    }, [isSuccess, navigate])

    const onQuestionChanged = e => setQuestion(e.target.value)
    const onAnswerChanged = e => setAnswer(e.target.value)
    const onStatusChanged = e => setStatus(e.target.value)
    // const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [question, answer, status, userId].every(Boolean) && !isLoading

    const onSaveHelpClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewHelp({ user: userId, question, answer, status })
        }
    }

    // const options = users.map(user => {
    //     return (
    //         <option
    //             key={user.id}
    //             value={user.id}
    //         >{user.name}</option >
    //     )
    // })

    const errClass = isError ? "errmsg" : "offscreen"
    const validQuestionClass = !question ? "form__input--incomplete" : ''
    const validAnswerClass = !answer ? "form__input--incomplete" : ''

    const content = (
        <>
            <main className="container">
                <p className={errClass}>{error?.data?.message}</p>
                <Link to={'/dashboard/helps'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <h2 className="pt-3">Add New Help</h2>
                <form className="form" onSubmit={onSaveHelpClicked}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="question">Question:</label>
                        <input
                            className={`form-control form__input ${validQuestionClass}`}
                            id="question"
                            name="question"
                            type="text"
                            autoComplete="off"
                            value={question}
                            onChange={onQuestionChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="text">Answer:</label>
                        <textarea
                            className={`form-control form__input form__input--text ${validAnswerClass}`}
                            id="answer"
                            name="answer"
                            value={answer}
                            onChange={onAnswerChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="status" className="form-label me-3">Status (Access Level)</label>
                        <div className="form-check form-check-inline">
                            <input 
                                id="statusRadio1" 
                                name="status" 
                                className="form-check-input" 
                                type="radio" 
                                value="public"
                                onChange={onStatusChanged}
                            />
                            <label htmlFor="statusRadio1" className="form-check-label">Public</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input 
                                id="statusRadio2" 
                                name="status"
                                className="form-check-input" 
                                type="radio" 
                                value="private"
                                onChange={onStatusChanged}
                                checked
                            />
                            <label htmlFor="statusRadio2" className="form-check-label">Private</label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <button
                            className="btn btn-primary"
                            title="Save"
                            disabled={!canSave}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </main>
        </>
    )

    return content
}

export default NewHelpForm