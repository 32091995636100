import { Link } from "react-router-dom";
import { useGetUsersQuery } from "./usersApiSlice";
import User from './User';
import useTitle from "../../hooks/useTitle";
import PulseLoader from 'react-spinners/PulseLoader';

function UsersList() {

    useTitle('Ongles-Jessica: Users List')

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 15 * 60 * 1000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {

        const { ids } = users

        const tableContent = ids?.length && ids.map(userId => <User key={userId} userId={userId} />)

        content = (
            <main className="container py-3">
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>List of Users</h2>
                        </div>
                        <div>
                            <Link to='/dashboard/users/new' className="btn btn-primary">
                                Add New User
                            </Link>
                        </div>
                    </div>
                </section>
                <hr />
                <table className="table striped bordered hover responsive">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th user__username">Name</th>
                            <th scope="col" className="table__th user__email">Email</th>
                            <th scope="col" className="table__th user__roles">Roles</th>
                            <th scope="col" className="table__th user__status">Status</th>
                            <th scope="col" className="table__th user__edit">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </main>
        )
    }

    return content
}
export default UsersList