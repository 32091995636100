import { useEffect } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import useAuth from '../hooks/useAuth';

function SidebarAdmin() {

    const { isManager, isAdmin } = useAuth()

    const navigate = useNavigate()

    const [sendLogout, {
        // isLoading,
        isSuccess,
        // isError,
        // error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])

    return (
        <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
            <ul className="nav flex-column">
                {
                    (isManager || isAdmin) ? (
                        <>
                            <div className="accordion" id="accordionTop">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTopOne" aria-expanded="true" aria-controls="collapseTopOne">
                                            <h6>Reservations</h6>
                                        </button>
                                    </h2>
                                    <div id="collapseTopOne" className="accordion-collapse collapse" data-bs-parent="#accordionTop">
                                        <div className="accordion-body">
                                            <Link to='/dashboard/reservations/present' className='nav-link'>
                                                Liste d'attente
                                            </Link>
                                            <Link to='/dashboard/reservations/pending' className='nav-link'>
                                                À faire/ To do
                                            </Link>
                                            <Link to='/dashboard/reservations' className='nav-link'>
                                                Rapport/ Report
                                            </Link>
                                            <Link to='/dashboard/reservations/new' className='nav-link'>
                                                Ajuster/ Add
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTopTwo" aria-expanded="false" aria-controls="collapseTopTwo">
                                            <h6>Services</h6>
                                        </button>
                                    </h2>
                                    <div id="collapseTopTwo" className="accordion-collapse collapse" data-bs-parent="#accordionTop">
                                        <div className="accordion-body">
                                            <Link to='/dashboard/services' className='nav-link'>
                                                Voir/ View
                                            </Link>
                                            <Link to='/dashboard/services/new' className='nav-link'>
                                                Ajuster/ Add
                                            </Link>
                                            <Link to='/dashboard/services' className='nav-link'>
                                                Rapport/ Report
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTopThree" aria-expanded="false" aria-controls="collapseTopThree">
                                        <h6>Helps</h6>
                                    </button>
                                    </h2>
                                    <div id="collapseTopThree" className="accordion-collapse collapse" data-bs-parent="#accordionTop">
                                        <div className="accordion-body">
                                            <Link to='/dashboard/helps' className='nav-link'>
                                                Voir/ View
                                            </Link>
                                            <Link to='/dashboard/helps/new' className='nav-link'>
                                                Ajuster/ Add
                                            </Link>
                                            <Link to='/dashboard/helps' className='nav-link'>
                                                Rapport/ Report
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTopFour" aria-expanded="false" aria-controls="collapseTopFour">
                                        <h6>Users</h6>
                                    </button>
                                    </h2>
                                    <div id="collapseTopFour" className="accordion-collapse collapse" data-bs-parent="#accordionTop">
                                        <div className="accordion-body fs-small">
                                            <Link to='/dashboard/users' className='nav-link'>
                                                Gestion/ Manage
                                            </Link>
                                            <Link to='' className='nav-link'>
                                                Ajuster/ Add
                                            </Link>
                                            <Link to='/dashboard/users' className='nav-link'>
                                                Rapport/ Report
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTopFive" aria-expanded="false" aria-controls="collapseTopFive">
                                        <h6>Post</h6>
                                    </button>
                                    </h2>
                                    <div id="collapseTopFive" className="accordion-collapse collapse" data-bs-parent="#accordionTop">
                                        <div className="accordion-body">
                                            <Link to='/dashboard/posts' className='nav-link'>
                                                Voir/ View
                                            </Link>
                                            <Link to='/dashboard/posts/new' className='nav-link'>
                                                Ajuster/ Add
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTopSix" aria-expanded="false" aria-controls="collapseTopSix">
                                            <h6>Products</h6>
                                        </button>
                                    </h2>
                                    <div id="collapseTopSix" className="accordion-collapse collapse" data-bs-parent="#accordionTop">
                                        <div className="accordion-body">
                                            <Link to='/dashboard/products' className='nav-link'>
                                                Voir/ View
                                            </Link>
                                            <Link to='/dashboard/products/new' className='nav-link'>
                                                Ajuster/ Add
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTopSeven" aria-expanded="false" aria-controls="collapseTopSeven">
                                            <h6>Orders</h6>
                                        </button>
                                    </h2>
                                    <div id="collapseTopSeven" className="accordion-collapse collapse" data-bs-parent="#accordionTop">
                                        <div className="accordion-body">
                                            <Link to='/dashboard/orders' className='nav-link'>
                                                Voir/ View
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="accordion" id="accordionDown">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDownOne" aria-expanded="true" aria-controls="collapseDownOne">
                                            <h6>Réservations</h6>
                                        </button>
                                    </h2>
                                    <div id="collapseDownOne" className="accordion-collapse collapse" data-bs-parent="#accordionDown">
                                        <div className="accordion-body">
                                            <NavLink to='/dashboard/reservations/future' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>
                                                À venir
                                            </NavLink>
                                            <Link to='/dashboard/reservations/past' className='nav-link'>
                                                Archive
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </ul>
            <ul className="nav flex-column my-2">
                <div className="accordion" id="accordionBottom">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBottomOne" aria-expanded="true" aria-controls="collapseBottomOne">
                                <h6>Accounts</h6>
                            </button>
                        </h2>
                        <div id="collapseBottomOne" className="accordion-collapse collapse show" data-bs-parent="#accordionBottom">
                            <div className="accordion-body">
                                <Link to='/dashboard/profile' className='nav-link'>
                                    Profil/ Profile
                                </Link>
                                <Link className="nav-link" title="Logout" onClick={sendLogout}>
                                    Logout
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    )
}

export default SidebarAdmin