import { useActivateMutation } from "./authApiSlice";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

function Activate() {
    const [activate, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useActivateMutation();

    const navigate = useNavigate();

    const { token } = useParams();
    let { name } =  jwtDecode(token);

    useEffect(() => {
        if (isSuccess) {
            navigate('/login')
        }
    }, [isSuccess, navigate])

    const onClickHandler = async (e) => {
        e.preventDefault();
        await activate({ token });
    }

    const activationLink = () => (
        <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center bg-body-tertiary">
            <div className="p-lg-5 mx-auto">
                <h3>Bonjour | Hello</h3>
                <h2 className="text-uppercase">{name}</h2>
                <hr />
                <p className="fw-bold">Veuillez cliquer sur le bouton ci-dessous pour terminer le processus d'inscription.</p>
                <p>Please click on the button below to complete the registration process.</p>
                <br />
                <button className="btn btn-lg btn-outline-primary text-uppercase" onClick={onClickHandler}>
                    Activer le compte | Activate account
                </button>
            </div>
        </div>
    )

    return (
        <main className="py-5">
            {activationLink()}
        </main>
    )
}

export default Activate