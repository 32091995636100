import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Message from '../../components/Message';
import ProductRating from "../../components/ProductRating";
// import { getproduct, createproductReview, reset } from "../features/products/productSlice";
import useAuth from "../../hooks/useAuth";
import { useGetProductDetailsQuery } from "./productsApiSlice";
import { addToCart } from "./cartSlice";
// import { reserveproduct } from "../reservations/cardSlice";
// import BackButton from "../components/BackButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import noimage from '../../images/noimage.jpg';

function ProductDetails() {
    // const { UserInfo } = useSelector((state) => state.auth);
    // const { product, isLoading, isError, isproductReviewError, isproductReviewSuccess, message } = useSelector((state) => state.products);

    const { id: productId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: product,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProductDetailsQuery(productId);

    const { cartItems } = useSelector((state) => state.cart);

    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const [qty, setQty] = useState(1);
    // const [isAddToCartSuccess, setIsAddToCartSuccess] = useState(false);
    
    const handleIncrement = () => {
        setQty(qty + 1);
    };
    
    const handleDecrement = () => {
        if (qty > 1) {
            setQty(qty - 1);
        }
    };

    // useEffect(() => {
    //     if (setIsAddToCartSuccess) {
    //         setQty(1);
    //         navigate('/shoppingcart')
    //     }
    // }, [setIsAddToCartSuccess, navigate])

    const { isClient, isManager, isAdmin } = useAuth()
    // const { UserInfo } = useAuth()

    // const { product } = useGetPublicproductsQuery("publicproducts", {
    //     selectFromResult: ({ data }) => ({
    //         product: data?.entities[productId]
    //     }),
    // })



    // const productReviewCreate = useSelector((state) => state.productReviewCreate)
    // const {
    // //   success: successproductReview,
    // //   loading: loadingproductReview,
    // //   error: errorproductReview
    // } = productReviewCreate
  
    // useEffect(() => {
    //     if(isproductReviewSuccess) {
    //         // alert('Review Submitted');
    //         setRating(0)
    //         setComment('')
    //         dispatch(reset())
    //     }
    //     // dispatch(getproduct(productId));
    //     // dispatch(createproductReview(productId))

    //     // eslint-disable-next-line
    // }, [dispatch, isproductReviewSuccess, productId])

    const submitHandler = (e) => {
        e.preventDefault();

        // dispatch(createproductReview({
        //     productId,
        //     rating,
        //     comment
        // }))
    } 
    
    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                <h1 className="text-danger">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {



    const addToCartHandler = async(product) => {
        dispatch(addToCart({
            productId: product._id,
            name: product.name,
            price: product.price,
            stock: product.stock,
            qty
        }));
        // setQty(1);
        // navigate('/shoppingcart')
    }

        content = (
            <main className="container py-5">
                <div className="breadcrumb">
                    <ul className="text-uppercase fw-bold">
                        <Link to={'/products'} className="text-decoration-none">
                            <i className="bi bi-caret-left-fill ms-1"></i> Retour | Back
                        </Link>
                    </ul>
                </div>
                <section>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            { product.image ? (
                                <img className="img-fluid rounded" src={`https://i.ytimg.com/vi/${product.image}/maxresdefault.jpg`} alt="" />
                            ) : (
                                <img className="img-fluid rounded" src={noimage} alt="" />
                            )}
                        </div>
                        
                        <div className="col-md-5">
                            <h5 className="text-uppercase">{product.name}</h5>
                            <hr className="my-4"/>
                            <p>{product.description}</p>
                            <hr className="my-4"/>
                            <ProductRating
                                value={product.rating} 
                                text={`${product.numReviews} 
                                ${product.numReviews > 1 ? 'reviews' : 'review'}`} 
                            />

                            <h5 className="card-title pt-4">
                                <span className="text-muted">Prix</span> ${product.price.toFixed(2)}
                            </h5>
                            <div className="d-flex justify-content-between align-items-center pt-2">
                                {cartItems?.find(item => (item.productId === product._id)) ? (
                                    <>
                                        <Link
                                            to='/products'
                                            type='button'
                                            role='button'
                                            className='btn btn-outline-primary my-3'
                                        >
                                            Continuer les achats
                                        </Link>

                                        <div>
                                            <Link
                                                to='/shoppingcart'
                                                className='btn btn-primary my-3'
                                                type='button'
                                            >
                                                Aller au panier
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="btn btn-outline-dark rounded-pill py-0 my-3 bg-light" role="toolbar" aria-label="Toolbar with button groups">
                                            <div className="btn-group" role="group" aria-label="Third group">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-light"
                                                    onClick={handleDecrement}
                                                >
                                                    -
                                                </button>
                                                <button type="button" className="btn btn-light">{qty}</button>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-light"
                                                    onClick={handleIncrement}
                                                    disabled={qty >= product.stock}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <button 
                                                type="button" 
                                                className="btn btn-secondary position-relative me-3"
                                                onClick={() => addToCartHandler(product)}
                                                // data-bs-dismiss="modal"
                                            >
                                                Ajuster au panier
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary fs-6">
                                                    {qty}
                                                    <span className="visually-hidden">quantité</span>
                                                </span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-5">
                    <div className="row">
                        <div className="col-md-9">
                            <ul className="list-group mb-3">
                                <li className="list-group-item d-flex justify-content-between lh-sm bg-light">
                                    <div>
                                        <h4>Détails sur le produit</h4>
                                        <div className='text-muted pb-3'>
                                            <p>À venir</p>
                                        </div>
                                    </div>
                                    <span className="text-muted text-center">
                                        <button className="btn btn-outline-secondary ms-1 fw-bold" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightDetail" aria-controls="offcanvasRightDetail">
                                            <i className="bi bi-arrow-right"></i>
                                            <hr className='py-2'/>
                                            <i className="bi bi-three-dots-vertical"></i>
                                        </button>

                                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRightDetail" aria-labelledby="offcanvasRightDetailLabel">
                                            <div className="offcanvas-header">
                                                <h4 className="offcanvas-title" id="offcanvasRightDetailLabel">Détails sur le produit</h4>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            </div>
                                            <div className="offcanvas-body">
                                                <img className="img-fluid rounded" src={noimage} alt='Images Not Available'/>
                                                ...
                                                <h5>À venir</h5>
                                            </div>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>                       
                </section>
                <section className="mt-3">
                    <div className="row">
                        <div className="col-md-9">
                            <ul className="list-group mb-3">
                                <li className="list-group-item d-flex justify-content-between lh-sm bg-light">
                                    <div>
                                        <h4>Avis</h4>
                                        <div className='pb-3'>
                                            <ProductRating
                                                value={product.rating} 
                                                text={`(${product.numReviews})`} 
                                            />
                                        </div>
                                    </div>
                                    <span className="text-muted">
                                        <button className="btn btn-outline-secondary ms-1 fw-bold" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightEvaluation" aria-controls="offcanvasRightEvaluation">
                                            <i className="bi bi-arrow-right"></i>
                                            <hr className='py-2'/>
                                            <i className="bi bi-three-dots-vertical"></i>
                                        </button>

                                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRightEvaluation" aria-labelledby="offcanvasRightEvaluationLabel">
                                            <div className="offcanvas-header">
                                                <h4 className="offcanvas-title" id="offcanvasRightEvaluationLabel">Évaluations</h4>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            </div>
                                            <div className="offcanvas-body">
                                                <h3>4.5</h3>
                                                <div className='pb-3'>
                                                    <ProductRating
                                                        value={product.rating} 
                                                        text={`(${product.numReviews})`} 
                                                    />
                                                </div>
                                                <ul className="list-group">
                                                    {product.reviews && product.reviews.map((review) => (
                                                    <li className="list-group-item" key={review._id}>
                                                        <span><strong>{review.name}</strong> | {review.createdAt.substring(0, 10)}</span>
                                                        <ProductRating value={review.rating} text={review.comment} />
                                                        {/* <p>{review.comment}</p> */}
                                                    </li>
                                                    ))}

                                                    <li className="list-group-item">
                                                        <h5>Rédiger une évaluation</h5>
                                                        {/* {isproductReviewError && (<Message variant='danger'>{message}</Message>)} */}
                                                        { isAdmin ? (
                                                            <form onSubmit={submitHandler}>
                                                                {/* <div controlId='rating' className='mb-3'></div> */}
                                                                <div className='mb-3'>
                                                                    <label htmlFor="rating" className="form-label">Classement</label>
                                                                    <select className="form-select" id="rating" name="rating" value={rating} onChange={(e) => setRating(e.target.value)}>
                                                                        <option value=''>Sélectionner...</option>
                                                                        <option value='1'>1 - Paible/Poor</option>
                                                                        <option value='2'>2 - Acceptable/Fair</option>
                                                                        <option value='3'>3 - Bon/Good</option>
                                                                        <option value='4'>4 - Très bon/Very Good</option>
                                                                        <option value='5'>5 - Superbe/Excellent</option>
                                                                    </select>
                                                                </div>
                                                                {/* <div controlId='comment' className='mb-3'> */}
                                                                <div className='mb-3'>
                                                                    <label>Évaluation</label>
                                                                    <textarea 
                                                                        className="form-control" 
                                                                        row='3'
                                                                        id="comment"
                                                                        name="comment"
                                                                        value={comment} 
                                                                        onChange={(e) => setComment(e.target.value)}
                                                                        required
                                                                    ></textarea>
                                                                    <div className="form-text text-muted">
                                                                        Quel est votre avis sur cet article?
                                                                    </div>
                                                                </div>

                                                                <button 
                                                                    type='submit' 
                                                                    className="btn btn-primary"
                                                                    disabled
                                                                >
                                                                    Soumettre
                                                                </button>
                                                            </form>
                                                        ) : (
                                                            <Message>Veuillez <Link to='/login'>vous connecter</Link> pour rédiger un avis</Message>
                                                        )}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>                       
                </section>
            </main>
        )
       
        return content
    }
}

export default ProductDetails