import { Link } from "react-router-dom";
import { useGetProductsQuery } from "./productsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import Product from "./Product";

const ProductList = () => {

    const {
        data: products,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProductsQuery('productsList', {
        // 1 second = 1000
        pollingInterval: 24 * 60 * 60 * 1000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    let content

    if (isLoading) content = <LoadingSpinner />

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="text-danger">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {
        // const handleEdit = () => navigate(`/dashboard/products/${productId}`)
        const { ids } = products

        const tableContent = ids?.length && ids.map(productId => <Product key={productId} productId={productId} />)

        content = (
            <main className="container py-3">
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>List of Products</h2>
                        </div>
                        <div>
                            <Link to='/dashboard/products/new' className="btn btn-primary">
                                Add New Product
                            </Link>
                        </div>
                    </div>
                </section>
                <hr />
                <table className="table striped bordered hover responsive">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Brand</th>
                            <th scope="col">Stock</th>
                            <th scope="col">Price Type</th>
                            <th scope="col">Price (CAD)</th>
                            <th scope="col">Status</th>
                            <th scope="col">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </main>
        )
    }

    return content
}

export default ProductList