import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { NAME_REGEX, EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/regexValidation";
import { useRegisterMutation } from "./authApiSlice";

const Register = () => {
    const [register, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRegisterMutation();

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);
    
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatchPassword, setValidMatchPassword] = useState(false);
    const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);

    useEffect(() => {
        setValidName(NAME_REGEX.test(name))
        setValidEmail(EMAIL_REGEX.test(email))
    }, [name, email])

    useEffect(() => {
        setValidPassword(PASSWORD_REGEX.test(password))
        setValidMatchPassword(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {
        if (isSuccess) {
            setName('')
            setEmail('')
            setPassword('')
            setMatchPassword('')
        }
    }, [isSuccess, navigate])

    const regexFulfilment = [validName, validEmail, validPassword, validMatchPassword].every(Boolean) && !isLoading

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        if (regexFulfilment) {
            await register({ name, email, password })
        }
    }

    const errClass = isError ? "alert alert-warning" : ""

    const content = (
        <>
            { isSuccess ? (
                <main className="container py-5">
                    <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center bg-body-tertiary">
                        <div className="p-lg-5 mx-auto my-5">
                            <h1>Notifications</h1>
                            <hr />
                            <div className="row pt-5">
                                <h5>Veuillez cliquer sur le lien d'activation que nous vous avons envoyé à votre courriel</h5>
                                <p>
                                    Un courriel contenant un lien d'activation vous a été envoyé à l'adresse . Veuillez cliquer sur le lien pour activer votre compte. Si vous ne cliquez pas sur le lien, votre compte restera inactif et vous ne recevrez pas d'autres courriels. Si vous n'avez pas reçu le courriel d'ici quelques minutes, veuillez vérifier votre dossier spam.
                                </p>
                                <hr />
                                <h5>Please click the activation link we sent to your email</h5>
                                <p>
                                    An email has been sent to your email address containing an activation link. Please click on the link to activate your account. If you do not click the link your account will remain inactive and you will not receive further emails. If you do not receive the email within a few minutes, please check your spam folder.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            ) : (
                <main className="py-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-8">
                            <div className="card">
                                <div className="card-header pt-3">
                                    <h3>Créer un compte</h3>
                                </div>
                                <br />
                                <div className={errClass}>
                                    <h3 className="text-center">{error?.data?.message}</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={onSubmitHandler}>
                                        <div className="form-floating mb-3">
                                            <input 
                                                type="text" 
                                                className={`form-control form-control-lg ${!name ? '' : validName ? 'is-valid' : 'is-invalid'}`}
                                                id="name"
                                                name="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                autoComplete="off"
                                                placeholder="Votre nom"
                                                required
                                                aria-invalid={validName ? "false" : "true"}
                                                aria-describedby="namenote"
                                                onFocus={() => setNameFocus(true)}
                                                onBlur={() => setNameFocus(false)}
                                            />
                                            <label htmlFor="name">Votre nom</label>
                                            <div id="namenote" className={nameFocus && !validName ? "invalid-feedback" : "visually-hidden"}>
                                                Veuillez spécifier votre prénom et nom en characters.
                                            </div>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input 
                                                type="email"
                                                className={`form-control form-control-lg ${!email ? '' : validEmail ? 'is-valid' : 'is-invalid'}`}
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                autoComplete="off"
                                                placeholder="Adresse courriel"
                                                required
                                                aria-invalid={validEmail ? "false" : "true"}
                                                aria-describedby="emailnote"
                                                onFocus={() => setEmailFocus(true)}
                                                onBlur={() => setEmailFocus(false)}
                                            />
                                            <label htmlFor="email">Adresse courriel</label>
                                            <div id="emailnote" className={emailFocus && !validEmail ? "invalid-feedback" : "visually-hidden"}>
                                                Veuillez spécifier un courriel valide.
                                            </div>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input 
                                                type="password"
                                                className={`form-control form-control-lg ${!password ? '' : validPassword ? 'is-valid' : 'is-invalid'}`}
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Mot de passe"
                                                required
                                                aria-invalid={validPassword ? "false" : "true"}
                                                aria-describedby="pwdnote"
                                                onFocus={() => setPasswordFocus(true)}
                                                onBlur={() => setPasswordFocus(false)}
                                            />
                                            <label htmlFor="password">Mot de passe</label>
                                            <div id="pwdnote" className={passwordFocus && !validPassword ? "invalid-feedback" : "visually-hidden"}>
                                                Veuillez entrer un mot de passe entre 8 et 24 caractères.<br />
                                                Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial. Caractères spéciaux requis sont: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                            </div>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input 
                                                type="password"
                                                className={`form-control form-control-lg ${!matchPassword ? '' : validMatchPassword && matchPassword ? 'is-valid' : 'is-invalid'}`}
                                                id="password2"
                                                name="password2"
                                                value={matchPassword}
                                                onChange={(e) => setMatchPassword(e.target.value)}
                                                placeholder="Confirmer mot de passe"
                                                required
                                                aria-invalid={validMatchPassword ? "false" : "true"}
                                                aria-describedby="pwd2note"
                                                onFocus={() => setMatchPasswordFocus(true)}
                                                onBlur={() => setMatchPasswordFocus(false)}
                                            />
                                            <label htmlFor="password2">Confirmer mot de passe</label>
                                            <div id="pwd2note" className={matchPasswordFocus && !validMatchPassword ? "invalid-feedback" : "visually-hidden"}>
                                                La confirmation du mot de passe doit être identique au mot de passe entré.
                                            </div>
                                        </div>
                                        <br />
                                        <div className="d-grid gap-2">
                                            <button
                                                className="btn btn-lg btn-danger text-uppercase"
                                                disabled={!regexFulfilment}
                                            >
                                                S'inscrire
                                            </button>
                                            <hr />
                                            <Link to='/login' className="btn btn-lg btn-light">Déjà un compte? Se connecter</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    )

    return content
}

export default Register