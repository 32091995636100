import { Link } from "react-router-dom";
import useAuth from '../hooks/useAuth';

function Dashboard() {
    const { isManager, isAdmin } = useAuth();

    const content = (
        <main className='container py-3'>
            <section>
                <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center bg-body-tertiary">
                    <div className="p-lg-5 mx-auto my-5">
                        <h1 className="display-5 fw-bold text-uppercase">Bienvenue</h1>
                        <h2 className="display-6 text-uppercase">Welcome</h2>
                        <hr className="py-3" />
                        <Link to='/reservation-location' type="button" className="btn btn-outline-primary btn-lg px-4 gap-3 text-uppercase">
                            Réservation en ligne
                        </Link>
                        {
                             (isManager || isAdmin) && (
                                <>
                                  <Link to='/dashboard/reservations/new' className="btn btn-lg btn-outline-primary mx-4">
                                        <span className="text-uppercase">Prendre Rendez-vous</span>
                                    </Link>
                                    <Link to='/dashboard/reservations/pending' className="btn btn-lg btn-outline-primary">
                                        <span className="text-uppercase">Réservations À faire</span>
                                    </Link>
                                </>
                             )
                        }
                    </div>
                </div>
            </section>
        </main>
    )

    return content
}

export default Dashboard;