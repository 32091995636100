function GiftPublic() {
 
    const content = (
        <main className='container py-5'>
            <section className="text-center">
                <h1 className="text-uppercase">Offir une carte-cadeau</h1>
                <hr />
                <h3 className="pt-5">La carte-cadeau idéale pour toutes les occasions</h3>
                <div className="row text-white py-5">
                    <div className="col-md-3 mb-3">
                        <div className="card bg-secondary p-3">
                            <h5>Fête des mères</h5>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="card bg-secondary p-3">
                            <h5>Anniversaire</h5>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="card bg-secondary p-3">
                            <h5>Félicitation</h5>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="card bg-secondary p-3">
                            <h5>Remerciements</h5>
                        </div>
                    </div>
                </div>
                <br />
                <h3>À venir | Coming soon</h3>
            </section>
        </main>
    )

    return content
}

export default GiftPublic;