import PropTypes from 'prop-types';

function Rating({ value, text, color }) {
  
    return (
        <div className='my-3'>
            <span>
                <i style={{ color }} className={value >= 1 ? 'fas fa-star' : value >= 0.5 ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
            </span>
            <span>
                <i style={{ color }} className={value >= 2 ? 'fas fa-star' : value >= 1.5 ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
            </span>
            <span>
                <i style={{ color }} className={value >= 3 ? 'fas fa-star' : value >= 2.5 ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
            </span>
            <span>
                <i style={{ color }} className={value >= 4 ? 'fas fa-star' : value >= 3.5 ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
            </span>
            <span>
                <i style={{ color }} className={value >= 5 ? 'fas fa-star' : value >= 4.5 ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
            </span>
            <span className='ms-3'>{text && text }</span>
            {/* <span className='ms-3'>{text ? text : ''}</span> */}
            {/* <i className="bi bi-star"></i> */}
            {/* <i className="bi bi-star-half"></i> */}
        </div>
    )
}

Rating.defaultProps = {
  color: '#f8e825',
//   color: '#666666',
}

Rating.propTypes = {
//   value: PropTypes.number.isRequired,
  value: PropTypes.number,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default Rating