import { NavLink } from "react-router-dom";

const ReservationSteps = ({ step1, step2, step3, step4, step5, step6, step7 }) => {
  return (
    <div>
      <ul className="nav justify-content-center">
        <li className="mb-3">
          {step1 ? (
            <NavLink className="btn btn-outline-info me-2" to='/reservation-location'>
              Endroit
            </NavLink>
          ) : (<NavLink className="btn btn-outline-info me-2 disabled">Endroit</NavLink>)}
        </li>

        <li className="mb-3">
          {step2 ? (
            <NavLink className="btn btn-outline-info me-2" to='/reservation-services'>
              Service
            </NavLink>
          ) : (<NavLink className="btn btn-outline-info me-2 disabled">Service</NavLink>)}
        </li>

        <li className="mb-3">
          {step3 ? (
            <NavLink className="btn btn-outline-info me-2" to='/reservation-panier'>
              Panier
            </NavLink>
          ) : (<NavLink className="btn btn-outline-info me-2 disabled">Panier</NavLink>)}
        </li>

        <li className="mb-3">
          {step4 ? (
            <NavLink className="btn btn-outline-info me-2" to='/reservation-date'>
              Date
            </NavLink>
          ) : (<NavLink className="btn btn-outline-info me-2 disabled">Date</NavLink>)}
        </li>

        <li className="mb-3">
          {step5 ? (
            <NavLink className="btn btn-outline-info me-2" to='/reservation-client-info'>
              Client Info
            </NavLink>
          ) : (<NavLink className="btn btn-outline-info me-2 disabled">Client Info</NavLink>)}
        </li>

        <li className="mb-3">
          {step6 ? (
            <NavLink className="btn btn-outline-info me-2" to='/reservation-summary'>
              Vérifier
            </NavLink>
          ) : (<NavLink className="btn btn-outline-info me-2 disabled">Vérifier</NavLink>)}
        </li>

        <li className="mb-3">
          {step7 ? (
            <NavLink className="btn btn-outline-info me-2" to='/reservation-confirmation'>
              Terminer
            </NavLink>
          ) : (<NavLink className="btn btn-outline-info me-2 disabled">Terminer</NavLink>)}
        </li>
      </ul>
    </div>
  )
}

export default ReservationSteps;