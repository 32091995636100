import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const servicesAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.status === b.status) ? 0 : a.status ? 1 : -1
})

const initialState = servicesAdapter.getInitialState()

export const servicesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getServices: builder.query({
            query: () => ({
                url: '/services',
                // validateStatus: (response, result) => {
                //     return response.status === 200 && !result.isError
                // },
            }),
            transformResponse: responseData => {
                const loadedServices = responseData.map(service => {
                    service.id = service._id
                    return service
                });
                return servicesAdapter.setAll(initialState, loadedServices)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Service', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Service', id }))
                    ]
                } else return [{ type: 'Service', id: 'LIST' }]
            }
        }),

        getPublicServices: builder.query({
            query: () => ({
                url: '/services/public',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedServices = responseData.map(service => {
                    service.id = service._id
                    return service
                });
                return servicesAdapter.setAll(initialState, loadedServices)
            },
            // providesTags: ['Service']
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Service', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Service', id }))
                    ]
                } else return [{ type: 'Service', id: 'LIST' }]
            }
        }),

        getPublicServicesByCategory: builder.query({
            query: () => ({
                url: '/services/publicListByCategory',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            // transformResponse: responseData => {
            //     const loadedServices = responseData.map(service => {
            //         service.id = service._id
            //         return service
            //     });
            //     return servicesAdapter.setAll(initialState, loadedServices)
            // },
            providesTags: ['Service']
            // providesTags: (result, error, arg) => {
            //     if (result?.ids) {
            //         return [
            //             { type: 'Service', id: 'LIST' },
            //             ...result.ids.map(id => ({ type: 'Service', id }))
            //         ]
            //     } else return [{ type: 'Service', id: 'LIST' }]
            // }
        }),

        getServiceDetails: builder.query({
            query: (id) => ({
                url: `/services/public/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
                providesTags: (result, error, id) => [{ type: 'Service', id }],
            }),
        }),

        addNewService: builder.mutation({
            query: initialService => ({
                url: '/services',
                method: 'POST',
                body: {
                    ...initialService,
                }
            }),
            invalidatesTags: [
                { type: 'Service', id: "LIST" }
            ]
        }),

        updateService: builder.mutation({
            query: initialService => ({
                url: '/services',
                method: 'PATCH',
                body: {
                    ...initialService,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Service', id: arg.id }
            ]
        }),

        deleteService: builder.mutation({
            query: ({ id }) => ({
                url: `/services`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Service', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetServicesQuery,
    useGetPublicServicesQuery,
    useGetPublicServicesByCategoryQuery,
    useAddNewServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation,
} = servicesApiSlice

// returns the query result object
export const selectServicesResult = servicesApiSlice.endpoints.getServices.select()

// creates memoized selector
const selectServicesData = createSelector(
    selectServicesResult,
    servicesResult => servicesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllServices,
    selectById: selectServiceById,
    selectIds: selectServiceIds
    // Pass in a selector that returns the notes slice of state
} = servicesAdapter.getSelectors(state => selectServicesData(state) ?? initialState)