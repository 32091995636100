import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUpdateHelpMutation, useDeleteHelpMutation } from "./helpsApiSlice";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const EditHelpForm = ({ help, users }) => {
    const { isManager, isAdmin } = useAuth()

    const [updateHelp, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateHelpMutation()

    const [deleteHelp, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteHelpMutation()

    const navigate = useNavigate()

    const [question, setQuestion] = useState(help.question)
    const [answer, setAnswer] = useState(help.answer)
    const [status, setStatus] = useState(help.status)
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setQuestion('')
            setAnswer('')
            setStatus('')
            setUserId('')
            navigate('/dashboard/helps')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onQuestionChanged = e => setQuestion(e.target.value)
    const onAnswerChanged = e => setAnswer(e.target.value)
    const onStatusChanged = e => setStatus(e.target.value)

    const canSave = [question, answer, status, userId].every(Boolean) && !isLoading

    const onSaveHelpClicked = async (e) => {
        if (canSave) {
            await updateHelp({ id: help.id, user: userId, question, answer, status })
        }
    }

    const onDeleteHelpClicked = async () => {
        await deleteHelp({ id: help.id })
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validQuestionClass = !question ? "form__input--incomplete" : ''
    const validAnswerClass = !answer ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="btn btn-danger"
                title="Delete"
                onClick={onDeleteHelpClicked}
            >
                Delete Help
            </button>
        )
    }

    const content = (
        <>
            <main className="container py-3">
                <Link to={'/dashboard/helps'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <p className={errClass}>{errContent}</p>
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>Edit Help</h2>
                        </div>
                        <div>
                            {deleteButton}
                        </div>
                    </div>
                </section>

                <form className="form" onSubmit={e => e.preventDefault()}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="help-question">Question:</label>
                        <input
                            className={`form-control form__input ${validQuestionClass}`}
                            id="help-question"
                            name="question"
                            type="text"
                            autoComplete="off"
                            value={question}
                            onChange={onQuestionChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="help-text">Answer:</label>
                        <textarea
                            className={`form-control form__input form__input--answer ${validAnswerClass}`}
                            id="help-answer"
                            name="answer"
                            value={answer}
                            onChange={onAnswerChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="status" className="form-label me-3">Status (Access Level)</label>
                        <div className="form-check form-check-inline">
                            <input 
                                id="statusRadio1" 
                                name="status" 
                                className="form-check-input" 
                                type="radio" 
                                value="public"
                                onChange={onStatusChanged}
                                checked = {status === 'public'}
                            />
                            <label htmlFor="statusRadio1" className="form-check-label">Public</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input 
                                id="statusRadio2" 
                                name="status"
                                className="form-check-input" 
                                type="radio" 
                                value="private"
                                onChange={onStatusChanged}
                                checked = {status === 'private'}
                            />
                            <label htmlFor="statusRadio2" className="form-check-label">Private</label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <button
                            className="btn btn-primary"
                            title="Save"
                            onClick={onSaveHelpClicked}
                            disabled={!canSave}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </main>
        </>
    )

    return content
}

export default EditHelpForm