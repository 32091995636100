import { useState } from "react";
import { useGetPostsQuery } from "./postsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import NewPost from "./NewPost";
import Modal from "./Modal";
import Post from "./Post";


function PostList() {
    const {
        data: posts,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPostsQuery();

    const [modalIsVisible, setModalIsVisible] = useState(false);
    
    // const [posts, setPosts] = useState([]);

    function hideModalHandler() {
        setModalIsVisible(false);
    }

    function showModalHandler() {
        setModalIsVisible(true);
    }

    // function addPostHandler(postData) {
    //     // setPosts((existingPosts) => [postData, ...existingPosts]);
    // }

    // let modalContent;
    // if (modalIsVisible) {
    //     modalContent =  <Modal onClose={hideModalHandler}>
    //         <NewPost 
    //             onTitleChange={handleTitleChange} 
    //             onContentChange={handleContentChange} 
    //         />
    //     </Modal>
    // }

    return (
        <>
            <main className="container py-3">
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>List of Posts</h2>
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={showModalHandler}>
                                Add New Post
                            </button>
                        </div>
                    </div>
                </section>
                <hr />
                { modalIsVisible && (
                    <Modal onClose={hideModalHandler}>
                        {/* <NewPost onCancel={hideModalHandler} onAddPost={addPostHandler} /> */}
                        <NewPost onCancel={hideModalHandler} />
                    </Modal>
                )}
                {/* {posts?.length > 0 && (
                    <ul>
                      {posts.map((post, index) => <Post key={index} title={post.title} content={post.content} />)}
                    </ul>
                )}
                {posts?.length === 0 && (
                    <div>
                        <h2>There is no posts yet</h2>
                        <p>Start adding some!</p>
                    </div>
                )} */}
                <section>
                    { isLoading ? <LoadingSpinner /> : isError ? {error} : (
                        <table className="table striped bordered hover responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posts.map((post, index) => <Post key={index} title={post.title} content={post.content} status={post.status} />)}
                            </tbody>
                        </table>
                    )}
                </section>
            </main>
        </>
    )
}

export default PostList;