import { memo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
// import { useGetPublicServicesQuery } from './servicesApiSlice';
import Rating from "../../components/Rating";
import noimage from '../../images/noimage.jpg';
import { saveReservationService, removeFromCard } from "../reservations/cardSlice";

const Service = ({ service }) => {

    const [qty, setQty] = useState(1)
    const dispatch = useDispatch()

    const { cardItems } = useSelector((state) => state.card);

    const handleIncrement = () => {
        setQty(qty + 1);
    };
    
    const handleDecrement = () => {
        if (qty > 1) {
            setQty(qty - 1);
        }
    };

    const removeFromCardHandler = async(serviceId) => {
        dispatch(removeFromCard(serviceId));
    };

    if (service) {
        const reserveServiceHandler = async(serviceId) => {
            dispatch(saveReservationService({
                serviceId: service._id,
                titre: service.titre,
                duration: service.duration,
                price: service.price,
                capacity: service.capacity,
                qty
            }));

            // navigate ('/reservation-date')
            // navigate (`/reservation-service`)
        }

        return (
            <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between lh-sm bg-light mb-3">
                    <div>
                        <Link to='#' className='link-primary text-decoration-none' data-bs-toggle="offcanvas" data-bs-target={`#offcanvasService${service._id}`} aria-controls="offcanvasRightDetail">
                            {/* {service.titre} ({service.duration} minutes) . . . */}
                            <h5>{service.titre} ({service.duration} minutes)</h5>
                        </Link>
                        <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasService${service._id}`} aria-labelledby="offcanvasRightDetailLabel">
                            <div className="offcanvas-header">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="offcanvas" aria-label="Close">
                                    Fermer
                                </button>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body text-center">
                                <img className="img-fluid rounded" src={noimage} alt='Images Not Available'/>
                                <h5 className="card-title text-uppercase pt-3">{service.titre}</h5>
                                <h5 className="card-title pb-3">
                                    ({service.duration} minutes)
                                </h5>
                                <p>{service.contenu}</p>
                                <h5 className="card-title py-3">
                                    <span className="text-muted">Prix</span> ${service.price.toFixed(2)}
                                </h5>
                                <hr />
                                <div>
                                    <Rating 
                                        value={service.rating} 
                                        text={`${service.numReviews} 
                                        ${service.numReviews > 1 ? 'reviews' : 'review'}`} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='text-muted pb-3'>
                            <small>Prix: ${Number(service.price).toFixed(2)} / unité</small>
                        </div>
                        {cardItems?.find(item => (item.serviceId === service._id)) ? (
                            <button 
                                className="btn btn-outline-danger btn-sm" 
                                type='button' 
                                variant='light' 
                                onClick={() => removeFromCardHandler(service._id)}
                            >
                                <i className='fas fa-trash'></i>
                            </button>
                        ) : (
                            <div className="btn btn-outline-dark rounded-pill p-0 bg-light" role="toolbar" aria-label="Toolbar with button groups">
                                <div className="btn-group" role="group" aria-label="Second group">
                                    <button 
                                        type="button" 
                                        className="btn btn-light border rounded-start-pill px-3"
                                        onClick={handleDecrement}
                                    >
                                        <span className="fw-bold">
                                            -
                                        </span>
                                    </button>
                                    <button type="button" className="btn btn-light border">{qty}</button>
                                    <button 
                                        type="button" 
                                        className="btn btn-light border rounded-end-pill px-3"
                                        onClick={handleIncrement}
                                        disabled={qty >= service.capacity}
                                    >
                                        <span className="fw-bold">
                                            +
                                        </span>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <span className="text-muted text-center">
                        <h5>${Number(service.price * qty).toFixed(2)}</h5>
                        <hr className='pt-2'/>
                        {cardItems?.find(item => (item.serviceId === service._id)) ? (
                            <Link
                                to='/reservation-panier'
                                className='btn btn-warning'
                                type='button'
                            >
                                Aller au panier
                            </Link>
                        ) : (
                            <button 
                                type="button" 
                                className="btn btn-secondary"
                                onClick={() => reserveServiceHandler(service)}
                            >
                                Ajouter au panier
                            </button>
                        )}
                    </span>
                </li>
            </ul>
        )
    } else return null
}

const memoizedService = memo(Service)

export default memoizedService