import { useState, useEffect } from "react";
import { Link, useParams, useNavigate} from 'react-router-dom';
import { PASSWORD_REGEX } from "../../utils/regexValidation";
import { useResetPasswordMutation } from "./authApiSlice";

const ResetPassword = () => {

    const [resetPassword, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useResetPasswordMutation();

    const { token } = useParams();
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatchPassword, setValidMatchPassword] = useState(false);
    const [matchPasswordFocus, setMatchPasswordFocus] = useState(false);

    useEffect(() => {
        setValidPassword(PASSWORD_REGEX.test(password))
        setValidMatchPassword(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {
        if (isSuccess) {
            setPassword('')
            navigate('/login')
        }
    }, [isSuccess, navigate])

    // const onPasswordChanged = e => setPassword(e.target.value)

    const regexFulfilment = [validPassword, validMatchPassword].every(Boolean) && !isLoading

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        if (regexFulfilment) {
            await resetPassword({ resetPasswordLink: token, password })
        }
    }

    const errClass = isError ? "alert alert-warning" : ""
    // const validPwdClass = !validPassword ? 'form__input--incomplete' : ''

    const content = (
        <>
            <main className="py-5">
                <p className={errClass}>{error?.data?.message}</p>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-8">
                        <div className="card">
                            <div className="card-header pt-3">
                                <h3>Réinitialisation du mot de passe</h3>
                            </div>
                            <br />
                            <div className="card-body">
                                <form onSubmit={onSubmitHandler}>
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="password"
                                            className={`form-control form-control-lg ${!password ? '' : validPassword ? 'is-valid' : 'is-invalid'}`}
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Nouveau mot de passe"
                                            required
                                            aria-invalid={validPassword ? "false" : "true"}
                                            aria-describedby="pwdnote"
                                            onFocus={() => setPasswordFocus(true)}
                                            onBlur={() => setPasswordFocus(false)}
                                        />
                                        <label htmlFor="password">Nouveau mot de passe</label>
                                        <div id="pwdnote" className={passwordFocus && !validPassword ? "invalid-feedback" : "visually-hidden"}>
                                            Veuillez entrer un mot de passe entre 8 et 24 caractères.<br />
                                            Doit inclure des lettres majuscules et minuscules, un chiffre et un caractère spécial. Caractères spéciaux requis sont: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="password"
                                            className={`form-control form-control-lg ${!matchPassword ? '' : validMatchPassword && matchPassword ? 'is-valid' : 'is-invalid'}`}
                                            id="password2"
                                            name="password2"
                                            value={matchPassword}
                                            onChange={(e) => setMatchPassword(e.target.value)}
                                            placeholder="Confirmer nouveau mot de passe"
                                            required
                                            aria-invalid={validMatchPassword ? "false" : "true"}
                                            aria-describedby="pwd2note"
                                            onFocus={() => setMatchPasswordFocus(true)}
                                            onBlur={() => setMatchPasswordFocus(false)}
                                        />
                                        <label htmlFor="password2">Confirmer nouveau mot de passe</label>
                                        <div id="pwd2note" className={matchPasswordFocus && !validMatchPassword ? "invalid-feedback" : "visually-hidden"}>
                                            La confirmation du mot de passe doit être identique au mot de passe entré.
                                        </div>
                                    </div>
                                    <br />
                                    <div className="d-grid gap-2">
                                        <button
                                            className="btn btn-lg btn-danger text-uppercase"
                                            disabled={!regexFulfilment}
                                        >
                                            Réinitialiser
                                        </button>
                                        <hr />
                                        <Link to='/login' className="btn btn-lg btn-light">Annuler</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )

    return content
}

export default ResetPassword