import NewHelpForm from './NewHelpForm'
import { useGetUsersQuery } from '../users/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
// import useTitle from '../../hooks/useTitle'

const NewHelp = () => {
    // useTitle('techHelps: New Help')

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!users?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewHelpForm users={users} />

    return content
}
export default NewHelp