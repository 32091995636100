import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useGetPublicProductsQuery } from "./productsApiSlice";
import { addToCart } from "./cartSlice";
// import Product from "./ProductPublicItem";
import LoadingSpinner from "../../components/LoadingSpinner";
import ProductRating from "../../components/ProductRating";
import noimage from '../../images/noimage.jpg';
// import './ProductPublic.css';

const ProductPublic = () => {
    
    const {
        data: products,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPublicProductsQuery();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { cartItems } = useSelector((state) => state.cart);

    const [qty, setQty] = useState(1);

    const handleIncrement = () => {
        setQty(qty + 1);
    };
    
    const handleDecrement = () => {
        if (qty > 1) {
            setQty(qty - 1);
        }
    };

    const showcaseText = {
        fontSize: "5rem",
        fontFamily: "'Monoton', cursive",
        textShadow: "0.3rem 0.3rem 0.3rem #888",
        padding: "0.8rem 1.6rem 1.8rem 0.8rem",
        borderRadius: "0.8rem",
        opacity: "0.72"
    }

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                <h1 className="text-danger">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {
      
        const addToCartHandler = async(product) => {
            dispatch(addToCart({
                productId: product._id,
                name: product.name,
                price: product.price,
                stock: product.stock,
                qty
            }));
            setQty(1);
            navigate ('/products');
        }

        // const { ids } = products

        // const cardContent = ids?.length && ids.map((productId) => <Product key={productId} productId={productId} />)

        content = (
            <main className="container py-3">
                <section>
                    <div className="position-relative overflow-hidden p-md-5 my-4 text-center bg-body-tertiary">
                        <div className="p-lg-5 mx-auto my-5">
                            <h1 style={showcaseText}>Produits Pour Ongles</h1>
                        </div>
                    </div>
                </section>
                <div className="row">
                    {products.map((product, index) =>
                        <div key={index} className="col-lg-4 col-md-6 my-3">
                            <div className="card bg-light m-1">
                                { product.image ? (
                                    <img 
                                        className="card-img-top" 
                                        src={`https://onglesjessica.s3.ca-central-1.amazonaws.com/services/${product.image}.jpg`} 
                                        alt={product.name} 
                                    />
                                ) : (
                                    <img className="card-img-top" src={noimage} alt="Non disponible" />
                                )}
                                <div className="card-body text-center">
                                    {/* <h4 className="card-title pt-3"><sup className="fs-6">$</sup>{product.price.toFixed(2)}</h4> */}
                                    <h6 className="card-title text-uppercase py-3">{product.name}</h6>
                                    <h5 className="card-title">
                                        <span className="text-muted">Prix</span> ${product.price.toFixed(2)}
                                    </h5>
                                    <hr />
                                    <div>
                                        <ProductRating
                                            value={product.rating} 
                                            text={`${product.numReviews} 
                                            ${product.numReviews > 1 ? 'reviews' : 'review'}`} 
                                        />
                                    </div>
                                   
                                    <div className="d-flex justify-content-between align-items-center pt-2">
                                        <div className="btn-group">
                                            <Link
                                                to={`/product/${product._id}`}
                                                type='button'
                                                className='btn btn-secondary'
                                            >
                                                + Info
                                            </Link>
                                            {/* {cartItems?.find(item => (item.productId === product._id)) ? (
                                                <Link
                                                    to='/shoppingcart'
                                                    className='btn btn-primary'
                                                    type='button'
                                                >
                                                    <i className="bi bi-cart-check"></i>
                                                </Link>
                                            ) : (
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary'
                                                    onClick={() => addToCartHandler(product)}
                                                >
                                                    Ajuster
                                                </button>
                                            )} */}
                                        </div>
                                        <div>
                                            {cartItems?.find(item => (item.productId === product._id)) ? (
                                                <Link
                                                    to='/shoppingcart'
                                                    className='btn btn-primary'
                                                    type='button'
                                                >
                                                    {/* <i className="bi bi-cart-check"></i> */}
                                                    Aller au panier
                                                </Link>
                                            ) : (
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary'
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`#product${index}`}
                                                >
                                                    Acheter
                                                </button>
                                            )}
                                       
                                            <div className="modal fade" id={`product${index}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                    <div className="modal-content">
                                                        {/* <div className="modal-header">
                                                            <h1 className="modal-title fs-5" id="staticBackdropLabel">{product.name}</h1>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div> */}
                                                        <div className="modal-body">
                                                            <div className="custom-card">
                                                                { product.image ? (
                                                                    <img
                                                                        className="card-img-top"
                                                                        style={{ height: "225px" }}
                                                                        src={`https://onglesjessica.s3.ca-central-1.amazonaws.com/products/${product.image}.jpg`} 
                                                                        alt={product.name} 
                                                                    />
                                                                ) : (
                                                                    <img 
                                                                        className="card-img-top"
                                                                        src={noimage}
                                                                        style={{ height: "225px" }}
                                                                        alt="Non disponible" 
                                                                    />
                                                                )}
                                                            </div>
                                                            <h5 className="card-title pt-5">
                                                                <span className="text-muted">Prix</span> ${product.price.toFixed(2)}
                                                            </h5>
                                                            <div className="btn btn-outline-dark rounded-pill py-0 my-3 bg-light" role="toolbar" aria-label="Toolbar with button groups">
                                                                <div className="btn-group" role="group" aria-label="Second group">
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-light"
                                                                        onClick={handleDecrement}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button type="button" className="btn btn-light">{qty}</button>
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-light"
                                                                        onClick={handleIncrement}
                                                                        disabled={qty >= product.stock}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <h6 className="card-title text-uppercase py-3">{product.name}</h6>
                                                            <p>{product.description}</p>
                                                        </div>
                                                        <div className="modal-header">
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-secondary" 
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Fermer
                                                            </button>
                                                            
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-secondary position-relative me-3"
                                                                onClick={() => addToCartHandler(product)}
                                                                data-bs-dismiss="modal"
                                                            >
                                                                Ajuster au panier
                                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary fs-6">
                                                                    {qty}
                                                                    <span className="visually-hidden">quantité</span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        )
    }

    return content
}

export default ProductPublic
