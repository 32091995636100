import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

import { useGetOrderDetailsQuery, useDeliverOrderMutation } from "./ordersApiSlice";

function OrderDetails () {

    const { id: orderId } = useParams();

    const { data: order, refetch, isLoading, isSuccess, isError, error } = useGetOrderDetailsQuery(orderId);

    const [ deliverOrder, { isLoading: loadingDeliver } ] = useDeliverOrderMutation();

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                <h1 className="text-danger">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {
        content = (
            <main className="container py-5">
                <div className="row">
                    <h1 className="text-center">Confirmation de la commande</h1>
                    <p className="text-center fs-5 pt-3">
                        Merci d'avoir magasiné chez nous. <br />
                        Nous vous enverrons une confirmation une fois que votre article aura été expédié. <br />
                        Les détails de votre commande sont indiqués ci-dessous.
                    </p>
                    <br/>
                    <hr />
                    <div className="col-md-8">
                        <h5>Mode de paiement</h5>
                        <p>
                            Method: {' '}
                            <strong>{order.paymentMethod}</strong>
                        </p>
                        {order.isPaid ? (
                            <p>
                                Paid on: {' '}
                                <strong>{order.paidAt}</strong>
                            </p>
                        ) : (
                            <button 
                                className="btn btn-warning"
                                disabled
                            >
                                Not Paid
                            </button>
                        )}
                        <hr />
                        <h5>Order Items</h5>
                        <ul className="list-group">
                            {order.orderItems.map((item, index) => (
                                <div key={index}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="list-group mb-3">
                                                <li className="list-group-item d-flex justify-content-between lh-sm bg-light">
                                                    <div>
                                                        <Link 
                                                            to={`/product/${item.product}`}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                        <div className='text-muted pb-3'>
                                                            <small>({item.qty} x ${Number(item.price).toFixed(2)})</small>
                                                        </div>
                                                    </div>
                                                    <span className="text-muted text-center">
                                                        <h6>${Number(item.price * item.qty).toFixed(2)}</h6>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ul>
                        <h5>Information de livraison</h5>
                        <p>
                            Votre commande sera expédiée à:
                        </p>
                        <p>
                            <strong>
                                {order.user.name}
                            </strong>
                        </p>
                        <p>
                            <strong>
                                {order.shippingAddress.address}, {' '} 
                                {order.shippingAddress.city}, {' '} 
                                {order.shippingAddress.postalCode}, {' '}
                                {order.shippingAddress.country}
                            </strong>
                        </p>

                        {order.isDelivered ? (
                            <p>
                                Delivered on: {' '}
                                <strong>{order.deliveredAt}</strong>
                            </p>
                        ) : (
                            <button 
                                className="btn btn-warning"
                                disabled
                            >
                                Not Delivered
                            </button>
                        )}
                    </div>
                    <div className="col-md-4">
                        <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Prix des articles </h6>
                                    <small className="text-body-secondary">Avant taxes</small>
                                </div>
                                <span className="text-body-secondary">${order.itemsPrice}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Frais de livraison</h6>
                                    <small className="text-body-secondary">Gratuit à l'achat de plus 100 dollars</small>
                                </div>
                                <span className="text-body-secondary">${order.shippingPrice}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">Taxes</h6>
                                    <small className="text-body-secondary">(GST and QST)</small>
                                </div>
                                <span className="text-body-secondary">${order.taxPrice}</span>
                            </li>
                            {/* <li className="list-group-item d-flex justify-content-between bg-body-tertiary">
                                <div className="text-success">
                                    <h6 className="my-0">Promo code</h6>
                                    <small>EXAMPLECODE</small>
                                </div>
                                <span className="text-success">---</span>
                            </li> */}
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">Total (CAD)</span>
                                <strong>${order.totalPrice}</strong>
                            </li>
                        </ul>
                        <hr className="my-4"/>
                        <div className="row gy-3">
                            <div className="col-md-6">
                                <label htmlFor="cc-name" className="form-label">Name on card</label>
                                <input type="text" className="form-control" id="cc-name" placeholder="" required />
                                {/* <small className="text-body-secondary">Full name as displayed on card</small> */}
                                <div className="invalid-feedback">
                                    Name on card is required
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="cc-number" className="form-label">Credit card number</label>
                                <input type="text" className="form-control" id="cc-number" placeholder="" required />
                                <div className="invalid-feedback">
                                    Credit card number is required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="cc-expiration" className="form-label">Expiration</label>
                                <input type="text" className="form-control" id="cc-expiration" placeholder="" required />
                                <div className="invalid-feedback">
                                    Expiration date required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="cc-cvv" className="form-label">CVV</label>
                                <input type="text" className="form-control" id="cc-cvv" placeholder="" required />
                                <div className="invalid-feedback">
                                    Security code required
                                </div>
                            </div>
                        </div>
                        <hr className="my-4" />
                        <button 
                            className="w-100 btn btn-primary btn-lg" 
                            type="button"
                            // onClick={paymentHandler}
                            disabled
                        >
                            {isLoading ? (<LoadingSpinner />) : 'Payer'}
                        </button>
                    </div>
                </div>
            </main>
        )
    }

    return content
};

export default OrderDetails;

