import { Outlet } from "react-router-dom";
import HeaderAdmin from "./HeaderAdmin";
import SidebarAdmin from "./SidebarAdmin";
// import FooterAdmin from "./FooterAdmin";

function LayoutAdmin() {

    return (
        <>
            <HeaderAdmin />
            <div className="row">
                <div className="col-sm-2 bg-light">
                    <SidebarAdmin />
                </div>
                <div className="col-sm-10">
                    <Outlet />
                </div>
            </div>
            {/* <FooterAdmin /> */}
        </>
    )
}

export default LayoutAdmin;