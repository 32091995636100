import { Link } from "react-router-dom";
import { useGetServicesQuery } from "./servicesApiSlice";
import Service from "./Service";

const ServiceList = () => {

    const {
        data: services,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetServicesQuery('servicesList', {
        // 1 second = 1000
        pollingInterval: 24 * 60 * 60 * 1000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <p>Loading...</p>

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {
        const { ids } = services

        const tableContent = ids?.length && ids.map(serviceId => <Service key={serviceId} serviceId={serviceId} />)

        content = (
            <main className="container py-3">
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>List of Services</h2>
                        </div>
                        <div>
                            <Link to='/dashboard/services/new' className="btn btn-primary">
                                Add New Service
                            </Link>
                        </div>
                    </div>
                </section>
                <hr />
                <table className="table striped bordered hover responsive">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col">Category</th>
                            <th scope="col" className="table__th note__title">Title</th>
                            <th scope="col" className="table__th note__username">Content</th>
                            <th scope="col" className="table__th note__username">Duration (minutes)</th>
                            <th scope="col" className="table__th note__username">Price Type</th>
                            <th scope="col" className="table__th note__username">Price (CAD)</th>
                            <th scope="col" className="table__th note__username">Capacity (Seats)</th>
                            <th scope="col" className="table__th note__status">Status</th>
                            <th scope="col" className="table__th note__edit">Edit</th>
                        </tr>

                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </main>
        )
    }

    return content
}
export default ServiceList