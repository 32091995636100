import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const helpsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.status === b.status) ? 0 : a.status ? 1 : -1
})

const initialState = helpsAdapter.getInitialState()

export const helpsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getHelps: builder.query({
            query: () => ({
                url: '/helps',
                // validateStatus: (response, result) => {
                //     return response.status === 200 && !result.isError
                // },
            }),
            transformResponse: responseData => {
                const loadedHelps = responseData.map(help => {
                    help.id = help._id
                    return help
                });
                return helpsAdapter.setAll(initialState, loadedHelps)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Help', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Help', id }))
                    ]
                } else return [{ type: 'Help', id: 'LIST' }]
            }
        }),

        getPublicHelps: builder.query({
            query: () => ({
                url: '/helps/public',
                // validateStatus: (response, result) => {
                //     return response.status === 200 && !result.isError
                // },
            }),
            // transformResponse: responseData => {
            //     const loadedHelps = responseData.map(help => {
            //         help.id = help._id
            //         return help
            //     });
            //     return helpsAdapter.setAll(initialState, loadedHelps)
            // },
            // providesTags: (result, error, arg) => {
            //     if (result?.ids) {
            //         return [
            //             { type: 'Help', id: 'LIST' },
            //             ...result.ids.map(id => ({ type: 'Help', id }))
            //         ]
            //     } else return [{ type: 'Help', id: 'LIST' }]
            // }
        }),

        addNewHelp: builder.mutation({
            query: initialHelp => ({
                url: '/helps',
                method: 'POST',
                body: {
                    ...initialHelp,
                }
            }),
            invalidatesTags: [
                { type: 'Help', id: "LIST" }
            ]
        }),

        updateHelp: builder.mutation({
            query: initialHelp => ({
                url: '/helps',
                method: 'PATCH',
                body: {
                    ...initialHelp,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Help', id: arg.id }
            ]
        }),

        deleteHelp: builder.mutation({
            query: ({ id }) => ({
                url: `/helps`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Help', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetHelpsQuery,
    useGetPublicHelpsQuery,
    useAddNewHelpMutation,
    useUpdateHelpMutation,
    useDeleteHelpMutation,
} = helpsApiSlice

// returns the query result object
export const selectHelpsResult = helpsApiSlice.endpoints.getHelps.select()

// creates memoized selector
const selectHelpsData = createSelector(
    selectHelpsResult,
    helpsResult => helpsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllHelps,
    selectById: selectHelpById,
    selectIds: selectHelpIds
    // Pass in a selector that returns the notes slice of state
} = helpsAdapter.getSelectors(state => selectHelpsData(state) ?? initialState)