import { useState } from "react";
import { useAddNewPostMutation } from "./postsApiSlice";
import useAuth from '../../hooks/useAuth';

function NewPost({ onCancel, onAddPost }) {
    const [addNewPost, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewPostMutation();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState('private');

    const { email } = useAuth()

    function titleChangeHandler(event) {
        setTitle(event.target.value)
    }

    function contentChangeHandler(event) {
        setContent(event.target.value)
    }
    
    function statusChangeHandler(event) {
        setStatus(event.target.value)
    }

    // function submitHandler(event) {
    //     event.preventDefault();
    //     const postData = {
    //         email: email,
    //         title: title,
    //         content: content,
    //         status: status
    //     };
    //     onAddPost(postData);
    //     onCancel();
    // }

    const submitHandler = async (e) => {
        e.preventDefault();
        await addNewPost({ email, title, content, status });
        onCancel();
    }

    return (
        <>
            <main className="container">
                <h2 className="pt-3">Add New Post</h2>
                <form onSubmit={submitHandler}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="title">Titre:</label>
                        <input
                            className="form-control"
                            id="title"
                            name="title"
                            type="text"
                            required
                            onChange={titleChangeHandler}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="text">Content:</label>
                        <textarea
                            className="form-control"
                            id="content"
                            name="content"
                            rows={3}
                            required
                            onChange={contentChangeHandler}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="status" className="form-label me-3">Status (Access Level)</label>
                        <div className="form-check form-check-inline">
                            <input 
                                id="statusRadio1" 
                                name="status" 
                                className="form-check-input" 
                                type="radio" 
                                value="public"
                                onChange={statusChangeHandler}
                            />
                            <label htmlFor="statusRadio1" className="form-check-label">Public</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input 
                                id="statusRadio2" 
                                name="status"
                                className="form-check-input" 
                                type="radio" 
                                value="private"
                                onChange={statusChangeHandler}
                                checked
                            />
                            <label htmlFor="statusRadio2" className="form-check-label">Private</label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </main>
        </>
    )
}

export default NewPost;