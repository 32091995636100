import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeSlot from './TimeSlot';
import { saveReservationDate } from "./cardSlice";
import ReservationSteps from '../../components/ReservationSteps';
import { useGetPendingReservationsQuery } from "./reservationsApiSlice";

function ReservationDate({ days, closeDays }) {

const {
  data: reservations,
  isLoading,
  isSuccess,
  isError,
  error
} = useGetPendingReservationsQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(getReservations());
  }, [dispatch]);

  // const locale = 'fr-CA';
  // const dateFormat = { year: "numeric", month: "2-digit", day: "2-digit" };

  const referenceDate = new Date();
  const currentDate = referenceDate.toLocaleDateString('fr-CA');
  // const currentDate = new Intl.DateTimeFormat(locale, dateFormat).format(referenceDate);
  const nextDate = new Date(referenceDate);
  nextDate.setDate(nextDate.getDate() + 1);
  const delayTime = 2; // 0 hours
  const delayDate = 60; // 60 days
  
  const currentDay = referenceDate.getDay();
 
  let closingTime;
  if ((currentDay === 4) || (currentDay === 5)) {
    closingTime = 21;
  } else {
    closingTime = 18;
  }

  // console.log(currentDay);
  // console.log(closingTime);

  const currentTime = referenceDate.getHours();
  const availableTime = closingTime - delayTime

  const minDateOption = () => {
    if (currentTime > availableTime) {
      return new Date(nextDate);
    } else {
      return new Date(referenceDate);
    }
  }

  const maxDateOption = new Date(currentDate);
  maxDateOption.setDate(maxDateOption.getDate() + delayDate);

  const { reservationService, reservationLocation, reservationDate } = useSelector((state) => state.card);
  const [date, setDate] = useState(minDateOption());
  const [timeReserved, setTimeReserved] = useState(reservationDate?.timeReserved);

  const calendarDate = date.toLocaleDateString('fr-CA');
  const dateReserved = date.toLocaleDateString('fr-CA');

  let currentTimeDisabled;

  if(currentDate === calendarDate) {
    currentTimeDisabled = currentTime;
  } else {
    currentTimeDisabled = 0;
  }
  
  const timeslotClickHandler = (value) => {
    setTimeReserved(value);
  }

  // const reservationQuantity = reservationService.qty
  // const reservationCapacity = reservationService.capacity;

  // const b14h30 = "14:30";
  // const reservationActiveFor14h30 = reservations?.filter(reservation => (
  //     reservation.reservationService.serviceId === reservationService.serviceId &&
  //     reservation.reservationLocation.locationReserved === reservationLocation.locationReserved &&
  //     reservation.reservationDate.dateReserved === dateReserved &&
  //     reservation.reservationDate.timeReserved === b14h30
  //   )
  // ).length
  // const reservationAvailableFor14h30 = reservationCapacity - reservationActiveFor14h30

  // const b15h30 ="15:30";
  // const reservationActiveFor15h30 = reservations?.filter(reservation => (
  //     reservation.reservationService.serviceId === reservationService.serviceId &&
  //     reservation.reservationLocation.locationReserved === reservationLocation.locationReserved &&
  //     reservation.reservationDate.dateReserved === dateReserved &&
  //     reservation.reservationDate.timeReserved === b15h30
  //   )
  // ).length
  // const reservationAvailableFor15h30 = reservationCapacity - reservationActiveFor15h30

  useEffect(() => {
    if (!reservationLocation.locationReserved) {
      navigate('/reservation-location');
    }
  }, [reservationLocation.locationReserved, navigate]);

  const reservationDateHandler = (e) => {
    e.preventDefault();
    dispatch(saveReservationDate({ dateReserved, timeReserved }));
    navigate('/reservation-client-info');
  }

  const dateResevedDisplay = () => {
    if (timeReserved) {
      return (
       <>
        <div className='h5'>
          Le {dateReserved} {''} à {''} {timeReserved}
        </div>
        <div className='text-uppercase'>Confirmer et Continuer</div>
        </>
      )
    } else {
      return (
        <div>
          Veuillez spécifier un plage horaire pour continuer
        </div>
      )
    }
  }

  return (
    <>
      <div className="container py-5">
        <ReservationSteps step1 step2 step3 step4 />
        <div className="text-center py-3">
            <h1>Quand aimeriez-vous réserver?</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                  Nous vous demandons d'arriver 15 minutes avant le début du soin, afin de vous permettre de bien profiter de votre soin.
                </p>
            </div>
        </div>
        <hr />
        <div className='row mb-3'>
          <div className='col-md-4 py-3'>
              <label className="form-label">À quelle date aimeriez-vous réserver?</label>
              <Calendar
                  calendarType='ISO 8601'
                  minDate={minDateOption()}
                  maxDate={new Date(maxDateOption)}
                  tileDisabled={({date, view}) => view === 'month' && (date.getDate() === 25 || date.getDate() === 26 || date.getDate() === 27) && date.getMonth() === 6}
                  // tileDisabled={tileDisabled}
                  view='month'
                  value={date}
                  onChange={setDate}
                  required
              />
          </div>
          <div className="col-md-8 justify-content-center py-3">
            <label className='form-label'>
              Choisissez la plage horaire qui vous convient:
            </label>
            <br />
            {
              ([1, 2, 3, 4, 5, 6].includes(date.getDay())) && (
                <TimeSlot
                  className={`${timeReserved === "9:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
                  value="09:30"
                  onClick={timeslotClickHandler}
                  disabledButton={`${currentTimeDisabled > 7.30 ? 'disabled' : ''}`}
                />
              )
            }
            <TimeSlot
              className={`${timeReserved === "10:00" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="10:00"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 8.00 ? 'disabled' : ''}`}
            />

            <TimeSlot
              className={`${timeReserved === "10:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="10:30"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 8.30 ? 'disabled' : ''}`}
            />

            <TimeSlot
              className={`${timeReserved === "11:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="11:30"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 9.30 ? 'disabled' : ''}`}
            />

            <TimeSlot
              className={`${timeReserved === "12:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="12:30"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 10.30 ? 'disabled' : ''}`}
            />

            <TimeSlot
              className={`${timeReserved === "13:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="13:30"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 11.30 ? 'disabled' : ''}`}
            />

            <TimeSlot
              className={`${timeReserved === "14:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="14:30"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 12.30 ? 'disabled' : ''}`}
            />
      
            <TimeSlot
              className={`${timeReserved === "15:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="15:30"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 13.30 ? 'disabled' : ''}`}
            />

            <TimeSlot
              className={`${timeReserved === "16:00" ? 'btn-primary' : 'btn-outline-primary'}`} 
              value="16:00"
              onClick={timeslotClickHandler}
              disabledButton={`${currentTimeDisabled > 14.00 ? 'disabled' : ''}`}
            />

            {
              (date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 5) && (
                <>
                  <TimeSlot
                    className={`${timeReserved === "16:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
                    value="16:30"
                    onClick={timeslotClickHandler}
                    disabledButton={`${currentTimeDisabled > 14.30 ? 'disabled' : ''}`}
                  />
                  <TimeSlot
                    className={`${timeReserved === "17:00" ? 'btn-primary' : 'btn-outline-primary'}`} 
                    value="17:00"
                    onClick={timeslotClickHandler}
                    disabledButton={`${currentTimeDisabled > 15.00 ? 'disabled' : ''}`}
                  />
                </>
              )
            }

            {
              (date.getDay() === 4 || date.getDay() === 5) && (
                <>
                 <TimeSlot
                    className={`${timeReserved === "17:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
                    value="17:30"
                    onClick={timeslotClickHandler}
                    disabledButton={`${currentTimeDisabled > 15.30 ? 'disabled' : ''}`}
                  />
                  <TimeSlot
                    className={`${timeReserved === "18:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
                    value="18:30"
                    onClick={timeslotClickHandler}
                    disabledButton={`${currentTimeDisabled > 16.30 ? 'disabled' : ''}`}
                  />
                  <TimeSlot
                    className={`${timeReserved === "19:30" ? 'btn-primary' : 'btn-outline-primary'}`} 
                    value="19:30"
                    onClick={timeslotClickHandler}
                    disabledButton={`${currentTimeDisabled > 17.30 ? 'disabled' : ''}`}
                  />
                  <TimeSlot
                    className={`${timeReserved === "20:00" ? 'btn-primary' : 'btn-outline-primary'}`} 
                    value="20:00"
                    onClick={timeslotClickHandler}
                    disabledButton={`${currentTimeDisabled > 18.00 ? 'disabled' : ''}`}
                  />
                </>
              )
            }
          
            <div className='container py-3'>
              Si vous trouvez une plage d'horaire ici avec <span className="badge text-bg-secondary">?</span> ou vous ne trouvez pas d'horaire de rendez-vous qui vous convient ?. Appelez nous au (418) 255-2318 pour réserver.
            </div>
          </div>
        </div>
      
        <div className='row py-3'>
          <form onSubmit={reservationDateHandler}>
            <button 
              type='submit' 
              className="btn btn-info btn-lg w-100"
              disabled={!timeReserved} 
            >
              {dateResevedDisplay()}
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ReservationDate