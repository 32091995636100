import { useState } from 'react';
import { Link } from "react-router-dom";
import { useGetMyPastReservationsQuery } from "./reservationsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { currentDate } from "../../utils/dateUtils";
import ReservationListPastItem from './ReservationListPastItem';

function ReservationListPast() {

    const {
        data: reservations,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMyPastReservationsQuery();

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }
    
    if (isSuccess) {
        content = (
            <main className='container py-3'>
                <section>
                    { isLoading ? <LoadingSpinner /> : isError && {error} }

                    { reservations?.length > 0 && (
                        <>
                            <h2>Ma réservations</h2>
                            <hr />
                            <table className="table striped bordered hover responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">Service</th>
                                        {/* <th scope="col">Status</th> */}
                                        <th scope="col">Details</th>
                                        <th scope="col">Color Note</th>
                                    </tr>
                                </thead>
                            
                                <tbody>
                                    {
                                        reservations.map((reservation, index) => (
                                            <ReservationListPastItem reservation={reservation} key={index} />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </>
                    )}

                    {reservations?.length === 0 && (
                        <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center">
                            <div className="mx-auto">
                                <h2>Vous n'avez aucune réservation dans le passée</h2>
                                <h5>You have not scheduled any reservation in the past</h5>
                            </div>
                        </div>
                    )}
                </section>
            </main>
        )
    }

    return content
}

export default ReservationListPast;