function Footer() {
    const date = new Date();

    return (
        <>
            <footer className="bg-dark text-white py-3">
                <div className="container d-flex justify-content-between opacity-75">
                    <div className="opacity-75 fw-bold mt-2">
                        Copyright &copy; { date.getFullYear() } Ongles Jessica
                    </div>
                    <div className="mt-2">
                        <a 
                            href="https://www.facebook.com/profile.php?id=100063487752659"
                            className="text-light me-3" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="fa-brands fa-facebook fa-2x"></i>
                        </a>

                        <a
                            href="https://www.instagram.com/ongles_jessica_pyramide_/" 
                            className="text-light" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <i className="fa-brands fa-instagram fa-2x"></i>
                        </a>
                    </div>
                </div>
            </footer>
        </>
    )   
}

export default Footer