import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewProductMutation } from "./productsApiSlice";

const NewProductForm = ({ users }) => {

    const [addNewProduct, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewProductMutation()

    const navigate = useNavigate()

    const [category, setCategory] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [video, setVideo] = useState('')
    const [brand, setBrand] = useState('')
    const [stock, setStock] = useState('')
    const [priceType, setPriceType] = useState('')
    const [price, setPrice] = useState('')
    const [priceMax, setPriceMax] = useState('')
    const [priceDiscount, setPriceDiscount] = useState('')

    const [status, setStatus] = useState('private')
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess) {
            setName('')
            setDescription('')
            setStatus('')
            setUserId('')
            navigate('/dashboard/products')
        }
    }, [isSuccess, navigate])

    const submitRequirements = [name, description, status, userId, priceType, price].every(Boolean) && !isLoading

    const onSaveProductClicked = async (e) => {
        e.preventDefault()
        if (submitRequirements) {
            await addNewProduct({ 
                user: userId,
                category,
                name,
                description,
                image,
                video,
                brand,
                stock,
                priceType,
                price,
                priceMax,
                priceDiscount,
                status, 
            })
        }
    }

    return (
        <>
            <main className="container">
                <p className='text-danger '>{error?.data?.message}</p>
                <Link to={'/dashboard/products'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <h2 className="pt-3">Add New Product</h2>
                <section className="py-3">
                    <form onSubmit={onSaveProductClicked}>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="name">Name of product (obligatoire | required)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="category" className="form-label">Category (obligatoire | required)</label>
                                <select
                                    id="category"
                                    className="form-select"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    required
                                >
                                    <option value="" selected>Please choose a category</option>
                                    <hr />
                                    <option value="NP" >Nail Polishes</option>
                                    <hr />
                                    <option value="NA">Nail Arts</option>
                                    <hr />
                                    <option value="NT">Nail Tools</option>
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="description">Description (obligatoire | required)</label>
                            <textarea 
                                name="description" 
                                id="description" 
                                className="form-control" 
                                value={description} 
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            >
                            </textarea>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="image">Image (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="image"
                                    name="image"
                                    value={image}
                                    onChange={(e) => setImage(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="video">Video Link (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="video"
                                    name="video"
                                    value={video}
                                    onChange={(e) => setVideo(e.target.value)}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="brand">Brand (obligatoire | required)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="brand"
                                    name="brand"
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="stock">Stock (obligatoire | required)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="stock"
                                    name="stock"
                                    value={stock}
                                    onChange={(e) => setStock(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="priceType" className="form-label">Price Types (obligatoire | required)</label>
                                <select
                                    className="form-select"
                                    value={priceType}
                                    onChange={(e) => setPriceType(e.target.value)}
                                    required
                                >
                                    <option value="" selected>Please choose a price type</option>
                                    <hr />
                                    <option value="fixed" >Fixed or Exact Price</option>
                                    <hr />
                                    <option value="range">Variable or Range Price</option>
                                    <hr />
                                    <option value="discount">Discount or Promotion Price</option>
                                </select>
                            </div>
                            {priceType && (
                                <div className="col">
                                    <label className="form-label" htmlFor="price">
                                        {priceType === "fixed" ? "Price Fixed" : priceType === "range" ? "Price Min" : "Price Regular"} (required)
                                    </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="price"
                                        name="price"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                            {priceType === "range" && (
                                <div className="col">
                                <label className="form-label" htmlFor="priceMax">Price Max (optional)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="priceMax"
                                    name="priceMax"
                                    value={priceMax}
                                    onChange={(e) => setPriceMax(e.target.value)}
                                />
                            </div>
                            )}
                            {priceType === "discount" && (
                                <div className="col">
                                    <label className="form-label" htmlFor="priceDiscount">Price Discount (required)</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="priceDiscount"
                                        name="priceDiscount"
                                        value={priceDiscount}
                                        onChange={(e) => setPriceDiscount(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="form-label me-3">Status (Access Level)</label>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio1" 
                                    name="status" 
                                    className="form-check-input" 
                                    type="radio" 
                                    value="public"
                                    onChange={(e) => setStatus(e.target.value)}
                                />
                                <label htmlFor="statusRadio1" className="form-check-label">Public</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio2" 
                                    name="status"
                                    className="form-check-input" 
                                    type="radio" 
                                    value="private"
                                    onChange={(e) => setStatus(e.target.value)}
                                    checked
                                />
                                <label htmlFor="statusRadio2" className="form-check-label">Private</label>
                            </div>
                        </div>
                        <div className="mb-3">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                title="Save"
                                disabled={!submitRequirements}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </section>
            </main>
        </>
    )
}

export default NewProductForm