import React from 'react';
// import noimage from '../images/noimage.jpg';

function PointOfService() {
  return (
        <div>
            <button className="btn btn-outline-primary btn-lg px-4 me-3 gap-3" data-bs-toggle="offcanvas" href="#offcanvasLeft" aria-controls="offcanvasLeft">
                <i className="bi bi-chevron-left"></i>
                La Pyramide
            </button>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasLeft" aria-labelledby="offcanvasLeftLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasLeftLabel">La Pyramide</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="mb-3">
                        {/* <img className="img-fluid rounded" src={noimage} alt='Images Not Available' /> */}
                        <iframe title="Google Maps Pyramide" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2731.8903909304413!2d-71.28487982365425!3d46.786764144230695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb896ddc8f230c3%3A0x67753506a8dd581f!2sOngles%20Jessica!5e0!3m2!1sen!2sca!4v1720017983282!5m2!1sen!2sca" width="360" height="360" allowfullscreen="allowfullscreen" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="mb-3">
                        <span className="text-body-secondary">offre des services professionnels, tels que la manucure, la pédicure et la pose d'ongles, dans une ambiance chaleureuse.</span>
                    </div>
                    <div className="mb-3">
                        <h6 className="fw-bold">Heures d'ouverture</h6>
                        <div className="row">
                            <div className="col-12">
                                <ul className="list-group">
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">lundi au mercredi</h6>
                                        </div>
                                        <span className="text-body-secondary">9h30 à 18h</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">jeudi et vendredi</h6>
                                        </div>
                                        <span className="text-body-secondary">9h30 à 21h</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">samedi</h6>
                                        </div>
                                        <span className="text-body-secondary">9h30 à 17h</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">dimanche</h6>
                                        </div>
                                        <span className="text-body-secondary">10h à 17h</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <h6 className="fw-bold">Adresse</h6>
                        <p>2360 chemin Ste-Foy, Québec, G1V 4H2</p>
                        <div>
                            <a className="btn btn-danger" href="tel:+1-581-741-9081">(581) 741-9081</a>
                        </div>
                    </div>
                </div>
            </div>
        
            <button className="btn btn-outline-primary btn-lg px-4 gap-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                Plaza Laval
                <i className="bi bi-chevron-right"></i>
            </button>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">Plaza Laval</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="mb-3">
                        {/* <img className="img-fluid rounded" src={noimage} alt='Images Not Available' /> */}
                        <iframe title="Google Maps Plaza Laval" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2732.175974231899!2d-71.3035742236545!3d46.78113674461442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb89759ff9a0e25%3A0xa7bcef77723fb80e!2sOngles%20Jessica%20Plaza%20Laval!5e0!3m2!1sen!2sca!4v1720020704611!5m2!1sen!2sca" width="360" height="360" allowfullscreen="allowfullscreen" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="mb-3">
                        <span className="text-body-secondary">offre des services professionnels, tels que la manucure, la pédicure et la pose d'ongles, dans une ambiance chaleureuse.</span>
                    </div>
                    <div className="mb-3">
                        <h6 className="fw-bold">Heures d'ouverture</h6>
                        <div className="row">
                            <div className="col-12">
                                <ul className="list-group">
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">lundi au mercredi</h6>
                                        </div>
                                        <span className="text-body-secondary">9h30 à 18h</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">jeudi et vendredi</h6>
                                        </div>
                                        <span className="text-body-secondary">9h30 à 21h</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">samedi</h6>
                                        </div>
                                        <span className="text-body-secondary">9h30 à 17h</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-secondary my-0">dimanche</h6>
                                        </div>
                                        <span className="text-body-secondary">10h à 17h</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <h6 className="fw-bold">Adresse</h6>
                        <p>2750 chemin Ste-Foy, Québec, G1V 1V6</p>
                        <div>
                            <a className="btn btn-danger" href="tel:+1-418-658-2626">(418) 658-2626</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PointOfService