import { useNavigate } from 'react-router-dom';
import { useGetServicesQuery } from './servicesApiSlice';
import { memo } from 'react';

const Service = ({ serviceId }) => {

    const { service } = useGetServicesQuery("servicesList", {
        selectFromResult: ({ data }) => ({
            service: data?.entities[serviceId]
        }),
    })

    const navigate = useNavigate()

    if (service) {
  
        const handleEdit = () => navigate(`/dashboard/services/${serviceId}`)

        return (
            <tr className="table__row">
                <td>{service.category}</td>
                <td className="table__cell service__title">{service.titre}</td>
                <td className="table__cell service__username">{service.contenu}</td>
                <td className="table__cell service__username">{service.duration}</td>
                <td className="table__cell service__username">{service.priceType}</td>
                <td className="table__cell service__username">
                    { 
                        service.priceType === "discount" ? (
                            <span>
                                <del className='text-muted'>${service.price}</del>
                                <span>${service.priceDiscount}</span>
                            </span>
                        ) : (
                        service.priceType === "range" ? (
                            <span>
                                {service.priceMax ? `$${service.price} - $${service.priceMax}` : `from $${service.price}`
                                }
                            </span>
                        ) : (
                            <span>
                                ${service.price}
                            </span>
                        ))
                    }
                </td>
                <td className="table__cell service__username">{service.capacity}</td>
                <td className="table__cell service__status">
                    {service.status === "private"
                        ? <span className="service__status--completed">Private</span>
                        : <span className="service__status--open">Public</span>
                    }
                </td>
                <td className="table__cell">
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={handleEdit}
                    >
                        Edit
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedService = memo(Service)

export default memoizedService