import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUpdateNoteMutation, useDeleteNoteMutation } from "./notesApiSlice";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const EditNoteForm = ({ note, users }) => {
    const { isManager, isAdmin } = useAuth()

    const [updateNote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateNoteMutation()

    const [deleteNote, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteNoteMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState(note.title)
    const [text, setText] = useState(note.text)
    const [status, setStatus] = useState(note.status)
    const [userId, setUserId] = useState(note.user)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTitle('')
            setText('')
            setUserId('')
            navigate('/dashboard/notes')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onTextChanged = e => setText(e.target.value)
    const onStatusChanged = e => setStatus(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, text, userId].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        if (canSave) {
            await updateNote({ id: note.id, user: userId, title, text, status })
        }
    }

    const onDeleteNoteClicked = async () => {
        await deleteNote({ id: note.id })
    }

    // const created = new Date(note.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    // const updated = new Date(note.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.name}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !text ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="btn btn-danger"
                title="Delete"
                onClick={onDeleteNoteClicked}
            >
                Delete Note
            </button>
        )
    }

    const content = (
        <>
            <main className="container py-3">
                <Link to={'/dashboard/notes'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <p className={errClass}>{errContent}</p>
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            {/* <h2>Edit Note #{note._id}</h2> */}
                            <h2>Edit Note</h2>
                        </div>
                        <div>
                            {deleteButton}
                        </div>
                    </div>
                </section>
       
                <form className="form" onSubmit={e => e.preventDefault()}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="note-title">Title:</label>
                        <input
                            className={`form-control form__input ${validTitleClass}`}
                            id="note-title"
                            name="title"
                            type="text"
                            autoComplete="off"
                            value={title}
                            onChange={onTitleChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="note-text">
                            Text:</label>
                        <textarea
                            className={`form-control form__input form__input--text ${validTextClass}`}
                            id="note-text"
                            name="text"
                            value={text}
                            onChange={onTextChanged}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label form__checkbox-container" htmlFor="note-name">Assigned to:</label>
                        <select
                            id="note-name"
                            name="name"
                            className="form-select"
                            value={userId}
                            onChange={onUserIdChanged}
                        >
                            {options}
                        </select>
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                        <label className="form-check-label" htmlFor="note-completed">Work Complete?</label>
                        <input
                            className="form-check-input"
                            id="note-status"
                            name="status"
                            type="checkbox"
                            checked={status}
                            onChange={onStatusChanged}
                        />
                        </div>
                    </div>
                    <div className="mb-3">
                        <button
                            className="btn btn-primary"
                            title="Save"
                            onClick={onSaveNoteClicked}
                            disabled={!canSave}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </main>
        </>
    )

    return content
}

export default EditNoteForm