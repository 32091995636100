import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const postsAdapter = createEntityAdapter({
    // sortComparer: (a, b) => (a.status === b.status) ? 0 : a.status ? 1 : -1
    // sortComparer: (a, b) => (a.postDate.timeReserved === b.postDate.timeReserved) ? 0 : a.postDate.timeReserved ? 1 : -1
})

const initialState = postsAdapter.getInitialState()

export const postsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPosts: builder.query({
            query: () => ({
                url: '/posts'
            }),
            providesTags: ['Post'],
        }),
        
        addNewPost: builder.mutation({
            query: initialPost => ({
                url: '/posts',
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
            invalidatesTags: ['Post']
            // invalidatesTags: [
            //     { type: 'Reservation', id: "LIST" }
            // ]
        }),

        updatePost: builder.mutation({
            query: (post) => ({
                url: `/posts`,
                method: 'PATCH',
                body: post,
            }),
            invalidatesTags: ['Post'],
            // invalidatesTags: (result, error, arg) => [
            //     { type: 'Post', id: arg.id }
            // ]
        }),

        deletePost: builder.mutation({
            query: ({ id }) => ({
                url: `/posts`,
                method: 'DELETE',
                body: { id }
            }),
            // invalidatesTags: ['Reservation'],
            invalidatesTags: (result, error, arg) => [
                { type: 'POST', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetPostsQuery,
    useGetPendingPostsQuery,
    useGetPresentPostsQuery,
    useGetArchivePostsQuery,
    useGetActivePostsQuery,
    useGetMyPostsQuery,
    useAddNewPostMutation,
    useCreatePostMutation,
    useUpdatePostToPresentMutation,
    useUpdatePostToDoneMutation,
    useUpdatePostToCancelMutation,
    useDeletePostMutation,
} = postsApiSlice

// returns the query result object
export const selectPostsResult = postsApiSlice.endpoints.getPosts.select()

// creates memoized selector
const selectPostsData = createSelector(
    selectPostsResult,
    postsResult => postsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllPosts,
    selectById: selectPostById,
    selectIds: selectPostIds
    // Pass in a selector that returns the notes slice of state
} = postsAdapter.getSelectors(state => selectPostsData(state) ?? initialState)