import { useNavigate } from 'react-router-dom';
import { useGetProductsQuery } from './productsApiSlice';
import { memo } from 'react';

const Product = ({ productId }) => {

    const { product } = useGetProductsQuery("productsList", {
        selectFromResult: ({ data }) => ({
            product: data?.entities[productId]
        }),
    })

    const navigate = useNavigate();

    if (product) {
  
        const handleEdit = () => navigate(`/dashboard/products/${productId}`)

        return (
            <tr>
                <td>{product.name}</td>
                <td>{product.description}</td>
                <td>{product.brand}</td>
                <td>{product.stock}</td>
                <td>{product.priceType}</td>
                <td>
                    { 
                        product.priceType === "discount" ? (
                            <span>
                                <del className='text-muted'>${product.price}</del>
                                <span>${product.priceDiscount}</span>
                            </span>
                        ) : (
                        product.priceType === "range" ? (
                            <span>
                                {product.priceMax ? `$${product.price} - $${product.priceMax}` : `from $${product.price}`
                                }
                            </span>
                        ) : (
                            <span>
                                ${product.price}
                            </span>
                        ))
                    }
                </td>
                <td>
                    {product.status === "private"
                        ? <span>Private</span>
                        : <span>Public</span>
                    }
                </td>
                <td>
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={handleEdit}
                    >
                        Edit
                    </button>
                </td>
                {/* <td>{service.titre}</td>
                <td>{service.contenu}</td>
                <td>{service.duration}</td>
                <td>{service.priceType}</td>
                <td>
                    { 
                        service.priceType === "discount" ? (
                            <span>
                                <del className='text-muted'>${service.price}</del>
                                <span>${service.priceDiscount}</span>
                            </span>
                        ) : (
                        service.priceType === "range" ? (
                            <span>
                                {service.priceMax ? `$${service.price} - $${service.priceMax}` : `from $${service.price}`
                                }
                            </span>
                        ) : (
                            <span>
                                ${service.price}
                            </span>
                        ))
                    }
                </td>
                <td>{service.capacity}</td>
                <td>
                    {service.status === "private"
                        ? <span>Private</span>
                        : <span>Public</span>
                    }
                </td>
                <td>
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={handleEdit}
                    >
                        Edit
                    </button>
                </td> */}
            </tr>
        )

    } else return null
}

const memoizedProduct = memo(Product)

export default memoizedProduct