import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const productsAdapter = createEntityAdapter({
    // Assume IDs are stored in a field other than `product.id` - default implementation
    // selectId: (product) => product.productId,

    // Keep the "all IDs" array sorted based on product names
    sortComparer: (a, b) => a.name.localeCompare(b.name),
    // sortComparer: (a, b) => (a.status === b.status) ? 0 : a.status ? 1 : -1
})

const initialState = productsAdapter.getInitialState()

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            query: () => ({
                url: '/products',
                method: 'GET',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedProducts = responseData.map(product => {
                    product.id = product._id
                    return product
                });
                return productsAdapter.setAll(initialState, loadedProducts)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Product', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Product', id }))
                    ]
                } else return [{ type: 'Product', id: 'LIST' }]
            }
        }),

        getPublicProducts: builder.query({
            query: () => ({
                url: '/products/public',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            // transformResponse: responseData => {
            //     const loadedServices = responseData.map(service => {
            //         service.id = service._id
            //         return service
            //     });
            //     return servicesAdapter.setAll(initialState, loadedServices)
            // },
            providesTags: ['Product'],
            // providesTags: (result, error, arg) => {
            //     if (result?.ids) {
            //         return [
            //             { type: 'Service', id: 'LIST' },
            //             ...result.ids.map(id => ({ type: 'Service', id }))
            //         ]
            //     } else return [{ type: 'Service', id: 'LIST' }]
            // }
        }),

        getProductDetails: builder.query({
            query: (id) => ({
                url: `/products/public/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
                providesTags: ['Service']
                // providesTags: (result, error, id) => {
                //     return [{ type: 'Service', id: id }]
                // },
            }),
        }),

        addNewProduct: builder.mutation({
            query: initialProduct => ({
                url: '/products',
                method: 'POST',
                body: { ...initialProduct,}
            }),

            // invalidatesTags: ['Product'],
            invalidatesTags: [{ type: 'Product', id: "LIST" }],
        }),

        updateProduct: builder.mutation({
            query: initialProduct => ({
                url: '/products',
                method: 'PATCH',
                body: {
                    ...initialProduct,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Product', id: arg.id }
            ]
        }),

        deleteProduct: builder.mutation({
            query: ({ id }) => ({
                url: `/products`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Product', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetProductsQuery,
    useGetPublicProductsQuery,
    useGetProductDetailsQuery,
    useAddNewProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
} = productsApiSlice

// returns the query result object
export const selectProductsResult = productsApiSlice.endpoints.getProducts.select()

// creates memoized selector
const selectProductsData = createSelector(
    selectProductsResult,
    productsResult => productsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllProducts,
    selectById: selectProductById,
    selectIds: selectProductIds
    // Pass in a selector that returns the notes slice of state
} = productsAdapter.getSelectors(state => selectProductsData(state) ?? initialState)