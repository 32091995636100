import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUpdateProductMutation, useDeleteProductMutation } from "./productsApiSlice";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const EditProductForm = ({ product, users }) => {
    const { isManager, isAdmin } = useAuth()

    const [updateProduct, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateProductMutation()

    const [deleteProduct, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteProductMutation()

    const navigate = useNavigate()


    const [category, setCategory] = useState(product.category)
    const [name, setName] = useState(product.name)
    const [description, setDescription] = useState(product.description)
    const [image, setImage] = useState(product.image)
    const [video, setVideo] = useState(product.video)
    const [brand, setBrand] = useState(product.brand)
    const [stock, setStock] = useState(product.stock)
    const [priceType, setPriceType] = useState(product.priceType)
    const [price, setPrice] = useState(product.price)
    const [priceMax, setPriceMax] = useState(product.priceMax)
    const [priceDiscount, setPriceDiscount] = useState(product.priceDiscount)

    const [status, setStatus] = useState(product.status)
    const [userId, setUserId] = useState(users[0].id)

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setName('')
            setDescription('')
            setImage('')
            setVideo('')
            setPriceType('')
            setPrice('')
            setPriceMax('')
            setPriceDiscount('')
            setStatus('')
            setUserId('')
            navigate('/dashboard/products')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const submitRequirements = [name, priceType, price, stock, status, userId].every(Boolean) && !isLoading

    const onSaveProductClicked = async (e) => {
        if (submitRequirements) {
            await updateProduct({ 
                id: product.id,
                user: userId,
                category,
                name,
                description,
                image,
                video,
                brand,
                stock,
                priceType,
                price,
                priceMax,
                priceDiscount,
                status, 
            })
        }
    }

    const onDeleteProductClicked = async () => {
        if(window.confirm('Êtes-vous sûr(e)/ Are you sure?')) {
            await deleteProduct({ id: product.id })
        }
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    // const validTitreClass = !titre ? "form__input--incomplete" : ''
    // const validContenuClass = !contenu ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="btn btn-danger"
                title="Delete"
                onClick={onDeleteProductClicked}
            >
                Delete Product
            </button>
        )
    }

    return (
        <>
            <main className="container py-3">
                <Link to={'/dashboard/products'} className="text-decoration-none">
                    <i className="bi bi-caret-left-fill me-1"></i> Retour | Back
                </Link>
                <p className={errClass}>{errContent}</p>
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>Edit Product</h2>
                        </div>
                        <div>
                            {deleteButton}
                        </div>
                    </div>
                </section>
                <section className="py-3">
                    <form>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="name">Name of product (obligatoire | required)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="category" className="form-label">Category (obligatoire | required)</label>
                                <select
                                    className="form-select"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    required
                                >
                                    <option value="">Please choose a category</option>
                                    <hr />
                                    <option value="NP" selected = {category === 'NP'}>Nail Polishes</option>
                                    <hr />
                                    <option value="NA" selected = {category === 'NA'}>Nail Arts</option>
                                    <hr />
                                    <option value="NT" selected = {category === 'NT'}>Nail Tools</option>
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="description">Description (obligatoire | required)</label>
                            <textarea 
                                name="description" 
                                id="description" 
                                className="form-control" 
                                value={description} 
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            >
                            </textarea>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="image">Image (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="image"
                                    name="image"
                                    value={image}
                                    onChange={(e) => setImage(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="video">Video Link (optional)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="video"
                                    name="video"
                                    value={video}
                                    onChange={(e) => setVideo(e.target.value)}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label" htmlFor="brand">Brand (obligatoire | required)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="brand"
                                    name="brand"
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="col">
                                <label className="form-label" htmlFor="stock">Stock (obligatoire | required)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="stock"
                                    name="stock"
                                    value={stock}
                                    onChange={(e) => setStock(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="priceType" className="form-label">Price Types (obligatoire | required)</label>
                                <select
                                    className="form-select"
                                    value={priceType}
                                    onChange={(e) => setPriceType(e.target.value)}
                                    required
                                >
                                    <option>Please choose a price type</option>
                                    <hr />
                                    <option value="fixed" selected = {priceType === 'fixed'}>Fixed or Exact Price</option>
                                    <hr />
                                    <option value="range" selected = {priceType === 'range'}>Variable or Range Price</option>
                                    <hr />
                                    <option value="discount" selected = {priceType === 'discount'}>Discount or Promotion Price</option>
                                </select>
                            </div>
                            {priceType && (
                                <div className="col">
                                    <label className="form-label" htmlFor="price">
                                        {priceType === "fixed" ? "Price Fixed" : priceType === "range" ? "Price Min" : "Price Regular"} (required)
                                    </label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="price"
                                        name="price"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        required
                                    />
                                </div>
                            )}
                            {priceType === "range" && (
                                <div className="col">
                                <label className="form-label" htmlFor="priceMax">Price Max (optional)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="priceMax"
                                    name="priceMax"
                                    value={priceMax}
                                    onChange={(e) => setPriceMax(e.target.value)}
                                />
                            </div>
                            )}
                            {priceType === "discount" && (
                                <div className="col">
                                <label className="form-label" htmlFor="priceDiscount">Price Discount (required)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="priceDiscount"
                                    name="priceDiscount"
                                    value={priceDiscount}
                                    onChange={(e) => setPriceDiscount(e.target.value)}
                                    required
                                />
                            </div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="status" className="form-label me-3">Status (Access Level)</label>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio1" 
                                    name="status" 
                                    className="form-check-input" 
                                    type="radio" 
                                    value="public"
                                    onChange={(e) => setStatus(e.target.value)}
                                    checked = {status === 'public'}
                                />
                                <label htmlFor="statusRadio1" className="form-check-label">Public</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input 
                                    id="statusRadio2" 
                                    name="status"
                                    className="form-check-input" 
                                    type="radio" 
                                    value="private"
                                    onChange={(e) => setStatus(e.target.value)}
                                    checked = {status === 'private'}
                                />
                                <label htmlFor="statusRadio2" className="form-check-label">Private</label>
                            </div>
                        </div>
                       
                        <div className="mb-3">
                            <button
                                className="btn btn-primary"
                                title="Save"
                                onClick={onSaveProductClicked}
                                disabled={!submitRequirements}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </section>
            </main>
        </>
    )
}

export default EditProductForm