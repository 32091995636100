import { createSlice } from "@reduxjs/toolkit";
import { updateCart } from "../../utils/cartUtils";

const initialState = localStorage.getItem('cart') 
    ? JSON.parse(localStorage.getItem('cart')) 
    : { cartItems: [], shippingAddress: {}, paymentMethod: 'Stripe' };
// const initialState = sessionStorage.getItem('cart') 
//     ? JSON.parse(sessionStorage.getItem('cart')) 
//     : { cartItems: [], shippingAddress: {}, paymentMethod: 'Stripe' };
// const initialState = { cartItems: [] }

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            // const { ...item } = action.payload
            const item = action.payload
            const existItem = state.cartItems.find((x) => x.productId === item.productId);
            
            if (existItem) {
                state.cartItems = state.cartItems.map((x) => x.productId === existItem.productId ? item : x)
            } else {
                state.cartItems = [...state.cartItems, item]
            }

            return updateCart(state, item);
        },
        
        removeFromCart: (state, action) => {
            state.cartItems = state.cartItems.filter((item) => item.productId !== action.payload);
            return updateCart(state);
        },

        quantityIncrement: (state, action) => {
            state.cartItems = state.cartItems.map((item) => item.productId === action.payload ? ({ ...item, qty: item.qty + 1 }) : item);
            return updateCart(state);
        },

        quantityDecrement: (state, action) => {
            state.cartItems = state.cartItems.map((item) => item.productId === action.payload ? ({ ...item, qty: item.qty - 1 }) : item).filter(item => item.qty > 0); // Remove item if quantity becomes zero
            return updateCart(state);
        },

        clearCartItems: (state, action) => {
            state.cartItems = [];
            return updateCart(state);
        },

        saveShippingAddress: (state, action) => {
            state.shippingAddress = action.payload;
            return updateCart(state);
        },

        savePaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
            return updateCart(state);
        }
    },
    extraReducers: (builder) => {}
});

export const { 
    addToCart, 
    quantityIncrement,
    quantityDecrement,
    removeFromCart, 
    clearCartItems, 
    saveShippingAddress, 
    savePaymentMethod 
} = cartSlice.actions;

export default cartSlice.reducer;