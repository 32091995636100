import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { quantityIncrement, quantityDecrement, removeFromCart, clearCartItems } from "./cartSlice";

// import useAuth from '../../hooks/useAuth';
// import usePersist from "../../hooks/usePersist";
// import noimage from '../../images/noimage.jpg';


function ShoppingCart() {
    // const { auth } = useAuth();
    // const [persist] = usePersist();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const quantityIncrementHandler = (itemId) => {
        dispatch(quantityIncrement(itemId));
    };

    const quantityDecrementHandler = (itemId) => {
        dispatch(quantityDecrement(itemId));
    };

    const cart = useSelector((state) => state.cart);
    const { cartItems } = useSelector((state) => state.cart)

    // const addToCartHandler = async(product, qty) => {
    //     dispatch(addToCart({ ...product, qty }));
    // } 

    const removeFromCartHandler = async(productId) => {
        dispatch(removeFromCart(productId));
    };
  
    // const clearCartItemsHandler = () => {
    //     if(window.confirm('Êtes-vous sûr(e)/ Are you sure?')) {
    //         dispatch(clearCartItems());
    //     }
    // };

    const clearCartItemsHandler = () => {
        dispatch(clearCartItems());
    };
  
  
    const checkoutHandler = () => {
        // navigate('/login?redirect=/shipping');
        // navigate(`${!persist ? '/login?redirect=/placeorder' : '/placeorder'}`);
        // navigate(`${status ? '/login?redirect=/shipping' : '/shipping'}`);
        navigate('/login?redirect=/placeorder');
    };

    return (
        <section className="container py-5">
            <div className="row">
                <h1 className="text-center">Panier d'achat</h1>
                <br/>
                <hr />
                {cartItems.length === 0 ? (
                    navigate('/products')
                ) : (
                    <>
                        <div className="row mt-3">
                            <div className="col-md-8">
                                <ul className="list-group">
                                    { cartItems?.map((item, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul className="list-group mb-3">
                                                        <li className="list-group-item d-flex justify-content-between lh-sm bg-light">
                                                            {/* <div className="col-sm-2k">
                                                                { item.image ? (
                                                                    <img className="img-fluid rounded" src={`https://i.ytimg.com/vi/${item.image}/maxresdefault.jpg`} alt={item.title} />
                                                                ) : (
                                                                    <img className="img-fluid rounded" src={noimage} alt="" />
                                                                )}
                                                            </div> */}
                                                            <div>
                                                                <h6>{item.name}</h6>
                                                                <div className='text-muted pb-3'>
                                                                    <small>${Number(item.price).toFixed(2)} / pièce</small>
                                                                </div>
                                                                 <div className="btn btn-outline-dark rounded-pill py-0 bg-light" role="toolbar" aria-label="Toolbar with button groups">
                                                                    <div className="btn-group" role="group" aria-label="Second group">
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn btn-light"
                                                                            onClick={() => quantityDecrementHandler(item.productId)}
                                                                            disabled={item.qty <= 1}
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <button type="button" className="btn btn-light">{item.qty}</button>
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn btn-light"
                                                                            onClick={() => quantityIncrementHandler(item.productId)}
                                                                            disabled={item.qty >= item.stock}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="text-muted text-center">
                                                                <h6>${Number(item.price * item.qty).toFixed(2)}</h6>
                                                                <hr className='py-2'/>
                                                                <button className="btn btn-outline-danger btn-sm" type='button' variant='light' onClick={() => removeFromCartHandler(item.productId)}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    {/* <Link to={`/products/${item.productId}`}>{item.name}</Link> */}
                                                    {/* <h5>{item.name}</h5>
                                                    <div className='row-cols-2'>
                                                        <button className='btn btn-lg btn-light'>
                                                            <select 
                                                                className="form-select" 
                                                                value={item.qty} 
                                                                onChange={(e) => addToCartHandler(item, Number(e.target.value))}
                                                            >
                                                                {[...Array(item.stock).keys()].map((x) => (
                                                                <option  key={x + 1} value={x + 1}>
                                                                    {x + 1}
                                                                </option>
                                                                ))}
                                                            </select>
                                                        </button>
                                                        <div className='col'>
                                                            <select 
                                                                className="form-select" 
                                                                value={item.qty} 
                                                                onChange={(e) => addToCartHandler(item, Number(e.target.value))}
                                                            >
                                                                {[...Array(item.stock).keys()].map((x) => (
                                                                <option  key={x + 1} value={x + 1}>
                                                                    {x + 1}
                                                                </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='col'>
                                                            <p className="text-danger pt-3" type='button' variant='light' onClick={() => removeFromCartHandler(item.productId)}>
                                                                <i className='fas fa-trash'></i>
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {/* <div className="col-md-2 text-center fw-bold">
                                                    ${item.price * item.qty}
                                                </div> */}
                                            </div>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <h3 className="text-center text-uppercase mb-3">Sommaire</h3>
                                        <ul className="list-group mb-3">
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0">Prix des articles</h6>
                                                    <small className="text-muted">({cartItems.reduce((acc, item) => acc + Number(item.qty), 0)} {cartItems.length > 1 ? 'articles' : 'article'})</small>
                                                </div>
                                                <span className="text-muted">${ cartItems.reduce((acc, item) => acc + Number(item.qty) * item.price, 0).toFixed(2) }</span>
                                                {/* <h2>Subtotal ({cartItems.reduce((acc, item) => acc + Number(item.qty), 0)}) {cartItems.length === 1 ? 'item' : 'items'}</h2>
                                                ${cartItems.reduce((acc, item) => acc + Number(item.qty) * item.price, 0).toFixed(2)} */}
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0">Frais de livraison</h6>
                                                    <small className="text-body-secondary">Gratuit à l'achat de plus 100 dollars</small>
                                                </div>
                                                <span className="text-body-secondary">${cart.shippingPrice}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h6 className="my-0">Taxes</h6>
                                                    <small className="text-body-secondary">(GST and QST)</small>
                                                </div>
                                                <span className="text-body-secondary">${cart.taxPrice}</span>
                                            </li>
                                            {/* <li className="list-group-item d-flex justify-content-between bg-light">
                                                <div className="text-success">
                                                    <h6 className="my-0">Code Promo</h6>
                                                    <small>Promotion nouveautés!</small>
                                                </div>
                                                <span className="text-success">− - -</span>
                                            </li> */}
                                            {/* <li className="list-group-item d-flex justify-content-between">
                                                <h6>Total (CAD)</h6>
                                                <strong>${cartItems.reduce((acc, item) => acc + Number(item.qty) * item.price, 0).toFixed(2)}</strong>
                                            </li> */}
                                            <li className="list-group-item d-flex justify-content-between">
                                                <span className="fw-bold">Total (CAD)</span>
                                                <strong>${cart.totalPrice}</strong>
                                            </li>
                                        </ul>
                                        <div className="card-footer">
                                            <div className="row d-grid gap-2">
                                                <button 
                                                    type='button' 
                                                    className='btn btn-info btn-lg mt-3'
                                                    onClick={checkoutHandler}
                                                    disabled={cartItems.length === 0}
                                                >
                                                    Valider et continuer
                                                </button>
                                                <button 
                                                    type='button' 
                                                    className='btn btn-outline-danger btn-lg my-3'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#emptyCart"
                                                    // onClick={() => clearCartItemsHandler()}
                                                >
                                                    Annuler la commande
                                                </button>
                                                  {/* Button trigger modal emptyCart */}
                                                {/* <a className="text-decoration-none" href="#" data-bs-toggle="modal" data-bs-target="#emptyCart">
                                                    <p className="text-danger pt-3">
                                                        <small><i className="bi bi-bag-dash"></i> Empty Cart</small>
                                                    </p>
                                                </a>
                                             */}
                                                <div className="modal fade" id="emptyCart" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content text-center pt-3">
                                                            {/* <div className="modal-header">
                                                                <h5 className="modal-title" id="staticBackdropLabel">Item Remove Comfirmation</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div> */}
                                                            <div className="modal-body">
                                                                <h5>Êtes-vous sûr de vouloir <span className="text-uppercase">VIDER</span>  ton panier?</h5>
                                                                <h6>Are you sure you want to <span className="text-uppercase">EMPTY</span> your shopping cart?</h6>
                                                            </div>
                                                            <hr />
                                                            <div className="modal-header">
                                                                <button 
                                                                    className="btn btn-danger" 
                                                                    onClick={() => clearCartItemsHandler()}
                                                                    data-bs-dismiss="modal"
                                                                >
                                                                    Oui
                                                                </button>
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Non</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link
                                                    to='/products'
                                                    type='button'
                                                    role='button'
                                                    className='btn btn-outline-primary btn-lg'
                                                >
                                                    Continuer les achats
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
    </section>
    )
}

export default ShoppingCart