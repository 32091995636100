import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { saveReservationService, removeFromCard } from "./cardSlice";
import ReservationSteps from '../../components/ReservationSteps';
import noimage from '../../images/noimage.jpg';

function ReservationService() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { reservationService } = useSelector((state) => state.card)

    const reserveServiceHandler = async(service, qty) => {
        dispatch(saveReservationService({
            serviceId: service.serviceId,
            titre: service.titre,
            duration: service.duration,
            price: service.price,
            capacity: service.capacity,
            qty 
        }));
    } 

    const removeFromCardHandler = async(serviceId) => {
        if(window.confirm('Vous êtres sûrs | Are you sure?')) {
            dispatch(removeFromCard(serviceId));
        }
    }
  
    const confirmServiceHandler = () => {
      navigate('/reservation-date');
    };

    return (
        <section className="container py-5">
            <ReservationSteps step1 step2 step3 />
            <div className="text-center py-3">
                <h1>Réserver / Planifier ma visite</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">
                        Pour effectuer une réservation pour un groupe de quatre personnes ou plus, veuillez SVP nous contacter par téléphone au (418) 255-2318.
                    </p>
                </div>
            </div>
            <hr />
            <div className="row">
                { !reservationService?.qty ? ( 
                    <Navigate to="/reservation-services" />
                ) : (
                    <>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                { reservationService.image ? (
                                    <img className="img-fluid rounded" src={`https://i.ytimg.com/vi/${reservationService.image}/maxresdefault.jpg`} alt={reservationService.title} />
                                ) : (
                                    <img className="img-fluid rounded" src={noimage} alt="" />
                                )}
                            </div>
                            <div className="col-md-6">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <h3 className="text-center text-uppercase py-3">Détails du service</h3>
                                        <ul className="list-group mb-3">
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h5 className="my-0">
                                                        {reservationService.titre}
                                                    </h5>
                                                    <small className="text-muted">Temps: {reservationService.duration} minutes</small>
                                                </div>
                                                <span>${(reservationService.price).toFixed(2)}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h5 className="my-0">Quantité</h5>
                                                    <small className="text-muted">Nombre de personnes</small>
                                                </div>
                                                <span className="text-muted">
                                                    <select 
                                                        className="form-select" 
                                                        value={reservationService.qty} 
                                                        onChange={(e) => reserveServiceHandler(reservationService, Number(e.target.value))}
                                                    >
                                                        {[...Array(reservationService.capacity).keys()].map((x) => (
                                                            <option  key={x + 1} value={x + 1}>
                                                                {x + 1}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div className="text-success">
                                                    <h6 className="my-0">Code Promo</h6>
                                                    <small>Promotion nouveautés!</small>
                                                </div>
                                                <span className="text-success">- - -</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                                <div>
                                                    <h5 className="my-0">Totale (CAD)</h5>
                                                    <small>Taxes inclus!</small>
                                                </div>
                                                <span className="fw-bold">
                                                    ${(reservationService.price * Number(reservationService.qty)).toFixed(2)}
                                                </span>
                                            </li>
                                           <br />
                                            <button 
                                                type='button'
                                                className='btn btn-lg btn-info text-uppercase'
                                                onClick={confirmServiceHandler}
                                            >
                                                Confirmer et continuer
                                            </button>
                                        </ul>
                                        <hr />
                                        <div className='py-2'>
                                            <span>
                                                <i className="bi bi-telephone-plus"></i> : Pour effectuer une réservation pour un groupe de quatre personnes ou plus, veuillez SVP nous contacter par téléphone au (418) 255-2318.
                                            </span>
                                        </div>
                                        <hr />
                                        <div className='text-center'>
                                            <button 
                                                className="btn btn-lg text-danger text-uppercase" 
                                                type='button'
                                                // onClick={() => removeFromCardHandler(reservationService._id)}
                                                onClick={() => removeFromCardHandler(reservationService.serviceId)}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    )
}

export default ReservationService