function Post({ title, content, status }) {
    return (
        <tr>
            <td>{title}</td>
            <td>{content}</td>
            <td>{status}</td>
            <td>
                <button
                    className="btn btn-secondary btn-sm"
                    // onClick={handleEdit}
                >
                    Edit
                </button>
            </td>
        </tr>
    );
}

export default Post;