import { useParams } from 'react-router-dom'
import EditHelpForm from './EditHelpForm'
import { useGetHelpsQuery } from './helpsApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
// import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
// import useTitle from '../../hooks/useTitle'

const EditHelp = () => {
    // useTitle('techNotes: Edit Note')

    const { id } = useParams()

    // const { name, isManager, isAdmin } = useAuth()

    const { help } = useGetHelpsQuery("helpsList", {
        selectFromResult: ({ data }) => ({
            help: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!help || !users?.length) return <PulseLoader color={"#FFF"} />


    // if (!isManager && !isAdmin) {
    //     if (note.name !== name) {
    //         return <p className="errmsg">No access</p>
    //     }
    // }

    const content = <EditHelpForm help={help} users={users} />

    return content
}
export default EditHelp