import { useNavigate } from 'react-router-dom';
import { useGetHelpsQuery } from './helpsApiSlice';
import { memo } from 'react';

const Help = ({ helpId }) => {

    const { help } = useGetHelpsQuery("helpsList", {
        selectFromResult: ({ data }) => ({
            help: data?.entities[helpId]
        }),
    })

    const navigate = useNavigate()

    if (help) {
  
        const handleEdit = () => navigate(`/dashboard/helps/${helpId}`)

        return (
            <tr className="table__row">
                <td className="table__cell help__title">{help.question}</td>
                <td className="table__cell help__username">{help.answer}</td>
                <td className="table__cell help__status">
                    {help.status === "private"
                        ? <span className="help__status--completed">Private</span>
                        : <span className="help__status--open">Public</span>
                    }
                </td>
                <td className="table__cell">
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={handleEdit}
                    >
                        Edit
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedHelp = memo(Help)

export default memoizedHelp