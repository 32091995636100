import { useState } from 'react';
import { Link } from "react-router-dom";
import { useGetArchiveReservationsQuery } from "./reservationsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { currentDate } from "../../utils/dateUtils";
import ReservationListArchiveItem from './ReservationListArchiveItem';

function ReservationListArchive() {

    const {
        data: reservations,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetArchiveReservationsQuery('reservationList', {
        // pollingInterval: 60000,
        pollingInterval: 6000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    // const [updateReservationToDone, {
    //     isLoading: isUpdateLoading,
    //     isSuccess: isUpdateSuccess,
    //     isError: isUpdateError,
    //     error: updateError
    // }] = useUpdateReservationToDoneMutation()

    const [date, setDate] = useState(currentDate);
    const [locationReserved, setLocationReserved] = useState('')

    const [search, setSearch] = useState('')

    // const [done, setDone] = useState(false)

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }
    
    if (isSuccess) {
        content = (
            <main className='container py-3'>
                <section>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h2>Reservations</h2>
                        <div className="d-flex align-items-center w-25">
                            <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Search..."
                                onChange={e => setSearch(e.target.value)}
                            />
                            <span style={{ position: "relative", left: "-27px" }}>
                                <i className="bi bi-search"></i>
                            </span>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            {/* <div className='me-2'>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div> */}
                            <div className="me-2">
                                <select
                                    className="form-select"
                                    value={locationReserved}
                                    onChange={(e) => setLocationReserved(e.target.value)}
                                >
                                    <option value="" selected>All locations</option>
                                    <hr />
                                    <option value="1" >La Pyramide</option>
                                    <hr />
                                    <option value="2">Plaza Laval</option>
                                </select>
                            </div>
                            <div>
                                <Link to='/dashboard/reservations/new' className="btn btn-outline-secondary">
                                    Add
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    { isLoading ? <LoadingSpinner /> : isError ? {error} : (
                        <table className="table striped bordered hover responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Employee</th>
                                    {/* <th scope="col">Comment</th>
                                    <th scope="col">Details</th> */}
                                    <th scope="col">Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                        
                            <tbody>
                                {
                                    reservations.filter(reservation => (((locationReserved === '1' || locationReserved === "2") ? reservation.reservationLocation.locationReserved === locationReserved : reservation.reservationLocation.locationReserved !== locationReserved) && (reservation.reservationClientInfo.client.toLowerCase().includes(search) || reservation.reservationClientInfo.phone.toLowerCase().includes(search) || reservation.reservationDate?.timeReserved.toLowerCase().includes(search))
                                    )).sort((a, b) => a.reservationDate.timeReserved.replace(':', '') - b.reservationDate.timeReserved.replace(':', '')).map((reservation, index) => (
                                        <ReservationListArchiveItem reservation={reservation} key={index} />
                                    ))
                                }
                            </tbody>
                        </table>
                    )}
                </section>
            </main>
        )
    }

    return content
}

export default ReservationListArchive;