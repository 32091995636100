import { useParams } from 'react-router-dom'
import EditServiceForm from './EditServiceForm'
import { useGetServicesQuery } from './servicesApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
// import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'

const EditService = () => {

    const { id } = useParams()

    // const { name, isManager, isAdmin } = useAuth()

    const { service } = useGetServicesQuery("servicesList", {
        selectFromResult: ({ data }) => ({
            service: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!service || !users?.length) return <PulseLoader color={"#FFF"} />


    // if (!isManager && !isAdmin) {
    //     if (note.name !== name) {
    //         return <p className="errmsg">No access</p>
    //     }
    // }

    const content = <EditServiceForm service={service} users={users} />

    return content
}
export default EditService