import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveReservationClientInfo} from "./cardSlice";
import ReservationSteps from '../../components/ReservationSteps';
import { NAME_REGEX, PHONE_REGEX } from "../../utils/regexValidation";
import useAuth from '../../hooks/useAuth';
import usePersist from "../../hooks/usePersist";

function ReservationClientInfo() {
  const { status } = useAuth();
  const [persist] = usePersist();

  const commentRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { reservationClientInfo } = useSelector((state) => state.card);

  const [client, setClient] = useState(reservationClientInfo?.client);

  const [phone, setPhone] = useState(reservationClientInfo?.phone);
  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [comment, setComment] = useState(reservationClientInfo?.comment);

  const setFormattedPhone = (newNumber) => {
    const digits = newNumber.replace(/\D/g, '');
    let formatted = digits.substring(0, 3);
    if (digits.length === 3 && newNumber[3] === '-') {
        formatted = `${formatted}-`;
    } else if (digits.length > 3) {
        formatted = `${formatted}-${digits.substring(3, 6)}`;
    }
    if (digits.length === 6 && newNumber[7] === '-') {
        formatted = `${formatted}-`;
    } else if (digits.length > 6) {
        formatted = `${formatted}-${digits.substring(6, 10)}`;
    }

    if (digits.length === 10) {
        commentRef.current.focus();
    }
    setPhone(formatted);
  };

  useEffect(() => {
    // setValidClient(NAME_REGEX.test(client))
    setValidPhone(PHONE_REGEX.test(phone))
  }, [client, phone])

  const reservationClientInfoHandler = (e) => {
    e.preventDefault();
    dispatch(saveReservationClientInfo({ client, phone, comment }));
    navigate(`${!persist ? '/login?redirect=/reservation-summary' : '/reservation-summary'}`);
  }

  return (
    <>
      <div className="container py-5">
        <ReservationSteps step1 step2 step3 step4 step5 />
        <div className="text-center py-3">
            <h1>Réserver / Client Information</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">
                  Pour mieux vous servir, nous vous prions de bien vouloir nous fournir les informations suivantes
                </p>
            </div>
        </div>
        <hr />
        <form onSubmit={reservationClientInfoHandler}>
          <div className='mb-3'>
            <label className="form-label" htmlFor="client">Voter nom et prénom (*)</label>
            <input
              className="form-control form-control-lg"
              type="text"
              placeholder="Nom et prénom"
              id="client"
              value={client}
              required
              onChange={(e) => setClient(e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label className="form-label" htmlFor="phone">Numéro de téléphone (*)</label>
            <input
              className={`form-control form-control-lg ${!phone ? '' : validPhone ? 'is-valid' : 'is-invalid'}`}
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setFormattedPhone(e.target.value)}
              autoComplete="off"
              placeholder="999-999-9999"
              required
              aria-invalid={validPhone ? "false" : "true"}
              aria-describedby="phonenote"
              onFocus={() => setPhoneFocus(true)}
              onBlur={() => setPhoneFocus(false)}
            />
            {/* <div id="phonenote" className={phoneFocus && !validPhone ? "invalid-feedback" : "visually-hidden"}>
                Veuillez spécifier un numéro de téléphone valide (e.g: XXX-XXX-XXXX).
            </div> */}
          </div>
          <div className='mb-3'>
            <label className="form-label">Commentaires (e.g: votre choix d'employé)?</label>
            <textarea
              className="form-control form-control-lg"
              placeholder='Ajoutez un souhait personnalisé ici (option)'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              ref={commentRef}
            >
            </textarea>
          </div>

          <button type='submit' className="btn btn-info btn-lg">
            Continuer
          </button>
        </form>
      </div>
    </>
  )
}

export default ReservationClientInfo