import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useUpdateReservationToCancelMutation, useDeleteReservationMutation } from "./reservationsApiSlice";
// import LoadingSpinner from "../../components/LoadingSpinner";
import { currentDate } from "../../utils/dateUtils";

function ReservationListItem({ reservation }) {
    const [updateReservationToCancel, {
            isLoading,
            isSuccess,
            isError,
            error
    }] = useUpdateReservationToCancelMutation();

    const [deleteReservation, {
        isSuccess: isDeleteSuccess
    }] = useDeleteReservationMutation();

    const navigate = useNavigate()

    // const [pending, setPending] = useState(false)
    // const [employee, setEmployee] = useState('')

    useEffect(() => {
        if (isSuccess || isDeleteSuccess) {
            navigate('/dashboard/reservations')
        }
    }, [isSuccess, isDeleteSuccess, navigate])

    const handleUpdateReservationToCancel = async () => {
        await updateReservationToCancel({
            id: reservation._id
        });
    };

    const handleDeleteReservation = async () => {
        if(window.confirm('Are you sure?')) {
            await deleteReservation({
                id: reservation._id
            });
        }
    };

    return (
        <tr className={`${reservation.reservationLocation.locationReserved === '1' ? 'table-light' : ''}`}>
            <td>{reservation.reservationDate.dateReserved}</td>
            <td>{reservation.reservationDate.timeReserved}</td>
            <td>{reservation.reservationClientInfo.client}</td>
            <td>
                {reservation.reservationServices.length === 0 && reservation.reservationService && reservation.reservationService.titre}
                {reservation.reservationServices?.map(service => `${service.titre} (${service.qty} place${service.qty > 1 ? 's' : ''})`).join(', ')}
            </td>
            <td>{reservation.performer}</td>
            {/* {
                (
                    reservation.reservationClientInfo.comment?.split('').length >= 12) ? (
                        <td>
                            <span 
                                className="d-inline-block text-truncate text-primary" 
                                style={{maxWidth: "90px"}}
                            >
                                {reservation.reservationClientInfo.comment}
                            </span>
                        </td> 
                ) :  (reservation.reservationClientInfo.comment?.split('').length > 0 && reservation.reservationClientInfo.comment?.split('').length < 12) ? (
                    <td>
                        {reservation.reservationClientInfo.comment}
                    </td> 
                ) : (
                    <td>
                        - - -
                    </td>
                )
            } */}
            <td>{reservation.status}</td>
            <td>
                <button className="btn btn-info btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasRight${reservation._id}`} aria-controls={`offcanvasRight${reservation._id}`}>
                    View
                </button>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasRight${reservation._id}`} aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header bg-info">
                        <h5 className="offcanvas-title" id="offcanvasRightLabel">
                            Reservation Details
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td className='col-sm-2 text-end text-muted'>Service</td>
                                    <td>
                                        {reservation.reservationServices.length === 0 && reservation.reservationService && reservation.reservationService.titre}
                                        {reservation.reservationServices?.map(service => `${service.titre} (${service.qty} place${service.qty > 1 ? 's' : ''})`).join(', ')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='col-sm-2 text-end text-muted'>avec</td>
                                    <td>{reservation.performer}</td>
                                </tr>
                                <tr>
                                    <td className='col-sm-2 text-end text-muted'>Quand</td>
                                    <td>{reservation.reservationDate.dateReserved} à {reservation.reservationDate.timeReserved}</td>
                                </tr>
                                <tr>
                                    <td className='col-sm-2 text-end text-muted'>Client</td>
                                    <td>{reservation.reservationClientInfo.client}</td>
                                </tr>
                                <tr>
                                    <td className='col-sm-2 text-end text-muted'>Phone</td>
                                    <td>{reservation.reservationClientInfo.phone}</td>
                                </tr>
                                <tr>
                                    <td className='col-sm-2 text-end text-muted'>Lieu</td>
                                    <td>{reservation.reservationLocation?.locationReserved === "1" ? "La Pyramide (2360 chemin St-Foy, Québec, G1V 4H2)" : "Plaza Laval (2750 chemin St-Foy, Québec, G1V 1V6)"}</td>
                                </tr>
                                <tr>
                                    <td className='col-sm-2 text-end text-muted'>Comment</td>
                                    <td>{reservation.reservationClientInfo.comment}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </td>
            <td>
                <button
                    className="btn btn-secondary btn-sm"
                    onClick={handleUpdateReservationToCancel}
                >
                    Cancel
                </button>
            </td>
            <td>
                <button
                    className="btn btn-danger btn-sm"
                    onClick={handleDeleteReservation}
                >
                    <i className="bi bi-trash"></i>
                </button>
            </td>
        </tr>
    )
}

export default ReservationListItem;