import { useNavigate } from "react-router-dom";

function PageNotFound() {
    const navigate = useNavigate();
    const content = (
        <main className='container py-5'>
            <section>
                <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center bg-body-tertiary">
                    <div className="p-lg-5 mx-auto my-5">
                        <h1 className="display-3 fw-bold">Page non trouvée</h1>
                        <h2 className="display-4 fw-bold">Page not found</h2>
                        <hr className="py-3" />
                        <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            onClick={() => navigate(-1)}
                        >
                            <span className="h3">Retour | Back</span>
                        </button>
                    </div>
                </div>
            </section>
        </main>
    )

    return content
}

export default PageNotFound;