import { Link } from 'react-router-dom';

function ReservationConfirmation() {
   
    return (
        <>
            <div className="container my-5">
                <div className="text-center pt-5">
                    <div id="payment-message" className="hidden"></div>
                    <h1>Confirmation</h1>
                    <hr />
                    <div className="row pt-5">
                        <div className="py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-check-lg text-success" viewBox="0 0 16 16">
                                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z"/>
                            </svg>
                        </div>
                        <h5>La réservation a été complétée avec succès</h5>
                        <p>Votre demande de réservation a été envoyée à votre adress courriel. <br />
                        Assurez-vous de vérifier votre dossier de courrier indésirable, car certains filtres sensibles peuvent l'acheminer vers ce dossier.</p>
                        <p>Vous pouvez aussi consulter votre réservation dans l'État de réservations à venir.</p>
                        {/* <h1 className='py-5'><Link to='/'><strong>Ongles Jessica</strong>.</Link></h1> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReservationConfirmation