import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
// import { useUpdateReservationToPendingMutation } from "./reservationsApiSlice";
// import LoadingSpinner from "../../components/LoadingSpinner";
import { currentDate } from "../../utils/dateUtils";

function ReservationListArchiveItem({ reservation }) {
    // const [updateReservationToPending, {
    //         isLoading,
    //         isSuccess,
    //         isError,
    //         error
    // }] = useUpdateReservationToPendingMutation();

    const navigate = useNavigate()

    const [pending, setPending] = useState(false)
    const [employee, setEmployee] = useState('')

    // useEffect(() => {
    //     if (isSuccess) {
    //         setEmployee('');
    //         setPending(!pending);
    //         navigate('/dashboard/reservations/pending')
    //     }

    // }, [isSuccess, navigate])

    const handleReservationToPending = async () => {
        // await updateReservationToPending({
        //     id: reservation._id,
        //     employee: employee
        // });

        // setEmployee('');
        // setPending(!pending);
    };

    return (
        <tr className={`${reservation.reservationLocation.locationReserved === '1' ? 'table-light' : ''}`}>
            <td>{reservation.reservationDate.dateReserved === currentDate ? "Aujourd'hui" : reservation.reservationDate.dateReserved}</td>
            <td>{reservation.reservationDate.timeReserved}</td>
            <td>{reservation.reservationClientInfo.client}</td>
            <td>{reservation.reservationService.titre} ({reservation.reservationService.qty} place)</td>
            <td></td>
            {/* {
                (reservation.reservationClientInfo.comment?.split('').length >= 12) ? (
                    <td>
                        <span 
                            className="d-inline-block text-truncate text-primary" 
                            style={{maxWidth: "90px"}}
                        >
                            {reservation.reservationClientInfo.comment}
                        </span>
                    </td> 
                ) :  (reservation.reservationClientInfo.comment?.split('').length > 0 && reservation.reservationClientInfo.comment?.split('').length < 12) ? (
                    <td>
                        {reservation.reservationClientInfo.comment}
                    </td> 
                ) : (
                    <td>
                        - - -
                    </td>
                )
            } */}
            {/* <td>
                <button className="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasRight${reservation._id}`} aria-controls={`offcanvasRight${reservation._id}`}>
                    Details
                </button>

                <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasRight${reservation._id}`} aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasRightLabel">
                            {reservation.reservationService.titre}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <h5>{reservation.reservationClientInfo.comment}</h5>
                    </div>
                </div>
            </td> */}
            <td className='text-capitalize'>{reservation.status}</td>
            {/* <td>
                {pending ? 
                    (
                        <div className='mb-3'>
                            <input
                                type='text'
                                className='form-control form-control-sm'
                                placeholder='Employee'
                                value={employee}
                                onChange={(e) => setEmployee(e.target.value)}
                            />
                        </div>
                    ) : ("")
                }
                <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                        setPending(!pending);
                    }}
                >
                    {pending ? "Annuler/Cancel" : "Attend/Pending"}
                </button>
                {pending ? 
                    (
                        <button
                            className='btn btn-primary btn-sm ms-3'
                            onClick={ handleReservationToPending}
                        >
                            Update
                        </button>
                    ) : ("")
                }
            </td> */}
        </tr>
    )
}

export default ReservationListArchiveItem;