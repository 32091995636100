import { useEffect } from 'react';
import { useNavigate, Link, NavLink, useLocation } from 'react-router-dom';
import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import useAuth from '../hooks/useAuth';

const DASH_REGEX = /^\/dash(\/)?$/
const NOTES_REGEX = /^\/dash\/notes(\/)?$/
const USERS_REGEX = /^\/dash\/users(\/)?$/

const HeaderAdmin = () => {
    const { name, isManager, isAdmin } = useAuth()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])

    const onNewNoteClicked = () => navigate('/dashboard/notes/new')
    const onNewUserClicked = () => navigate('/dashboard/users/new')
    const onNotesClicked = () => navigate('/dashboard/notes')
    const onUsersClicked = () => navigate('/dashboard/users')

    // if (isLoading) return <p>Logging Out...</p>

    // if (isError) return <p>Error: {error.data?.message}</p>

    let dashClass = null
    if (!DASH_REGEX.test(pathname) && !NOTES_REGEX.test(pathname) && !USERS_REGEX.test(pathname)) {
        dashClass = "dash-header__container--small"
    }

    let newNoteButton = null
    if (NOTES_REGEX.test(pathname)) {
        newNoteButton = (
            <button
                className="icon-button"
                title="New Note"
                onClick={onNewNoteClicked}
            >
                Add New Note
            </button>
        )
    }

    let newUserButton = null
    if (USERS_REGEX.test(pathname)) {
        newUserButton = (
            <button
                className="icon-button"
                title="New User"
                onClick={onNewUserClicked}
            >
                Add New User
            </button>
        )
    }

    let userButton = null
    if (isManager || isAdmin) {
        if (!USERS_REGEX.test(pathname) && pathname.includes('/dash')) {
            userButton = (
                <button
                    className="icon-button"
                    title="Users"
                    onClick={onUsersClicked}
                >
                    User List
                </button>
            )
        }
    }

    let notesButton = null
    if (!NOTES_REGEX.test(pathname) && pathname.includes('/dash')) {
        notesButton = (
            <button
                className="icon-button"
                title="Notes"
                onClick={onNotesClicked}
            >
                Note List
            </button>
        )
    }

    const logoutButton = (
        <button
            className="dropdown-item"
            title="Logout"
            onClick={sendLogout}
        >
            Logout
        </button>
    )

    const errClass = isError ? "errmsg" : "offscreen"

    let buttonContent
    if (isLoading) {
        buttonContent = <p>Logging Out...</p>
    } else {
        buttonContent = (
            <>
                {newNoteButton}
                {newUserButton}
                {notesButton}
                {userButton}
                {logoutButton}
            </>
        )
    }

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <header>
                {/* <div className={`dash-header__container ${dashClass}`}>
                    <Link to="/dashboard">
                        <h1 className="dash-header__title">Dashboard</h1>
                    </Link>
                    <nav className="dash-header__nav">
                        {buttonContent}
                        {logoutButton}
                    </nav>
                </div> */}
                <nav className="navbar navbar-expand-lg navbar-light text-uppercase bg-light border-bottom">
                    <div className="container-fluid d-flex justify-content-between align-items-center">
                        {/* <Link to='/dashboard' className="navbar-brand align-sefl-center"><i className="bi bi-house"></i></Link> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navMain" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navMain">
                            <div className="flex-fill">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink to='/dashboard' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>Ongles Jessica</NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink to='/reservations' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>Reservations</NavLink>
                                    </li> */}
                                    {/* {
                                        (isManager || isAdmin) ? (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to='/dashboard/users' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>Users</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to='/reservations' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>Reservations</NavLink>
                                                </li>
                                            </>
                                        ) : (
                                            <li className="nav-item">
                                                <NavLink to='/reservations' className={({isActive}) => isActive ? "nav-link text-primary fw-bold" : "nav-link"}>Reservations</NavLink>
                                            </li>
                                        )
                                    } */}
                                </ul>
                            </div>
                            <div className="navbar justify-content-start d-inline-flex d-flex align-items-start">
                                <ul className="navbar-nav ms-auto list-group">
                                    <li className="nav-item dropdown">
                                        <button id="navbarDropdown" className="nav-link dropdown-toggle btn btn-outline-primary fw-bold" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {name} <span className="caret"></span>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                            <Link to='/dashboard' className="dropdown-item">Dashboard</Link>
                                            <div className="dropdown-divider"></div>
                                            <Link to='/auth/profile' className="dropdown-item">Profile</Link>
                                            <div className="dropdown-divider"></div>
                                            {logoutButton}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )

    return content
}

export default HeaderAdmin