import { useGetPublicHelpsQuery } from "./helpsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";

function HelpPublic() {

    const {
        data: helps,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPublicHelpsQuery('publicHelps', {
        // 1 second = 1000
        pollingInterval: 24 * 60 * 60 * 1000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }
 
    if (isSuccess) {
        content = (
            <main className='container py-5'>
                <section id="help-generals">
                    <h1>Les questions fréquemment posées</h1>
                    <p>Vous trouverez ci-dessous les questions les plus fréquemment posées sur le site Web. Si vous ne trouvez pas les informations que vous recherchez ici, veuillez nous envoyer un courriel à <a href="mailto: onglesjessica.pyramide@gmail.com">onglesjessica.pyramide@gmail.com</a>, puis nous vous répondrons dans quelques jours ouvrables.</p>
                </section>
                <hr />
                <section className="py-3">
                    <div className="row">
                        {helps.filter(help => (help.status === "public")).map((help, index) => (
                            <dl key={index} className="row">
                                <dt className="col-sm-4">{ help.question }</dt>
                                <dd className="col-sm-8">{ help.answer }</dd>
                            </dl>
                        ))}
                    </div>
                </section>
            </main>
        )
    }

    return content
}

export default HelpPublic;