import { Link } from "react-router-dom";
import { useGetHelpsQuery } from "./helpsApiSlice";
import Help from "./Help";

const HelpList = () => {

    const {
        data: helps,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetHelpsQuery('helpsList', {
        // 1 second = 1000
        pollingInterval: 24 * 60 * 60 * 1000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <p>Loading...</p>

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }

    if (isSuccess) {
        const { ids } = helps

        const tableContent = ids?.length && ids.map(helpId => <Help key={helpId} helpId={helpId} />)

        content = (
            <main className="container py-3">
                <section className="text-center">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>List of Helps</h2>
                        </div>
                        <div>
                            <Link to='/dashboard/helps/new' className="btn btn-primary">
                                Add New Help
                            </Link>
                        </div>
                    </div>
                </section>
                <hr />
                <table className="table striped bordered hover responsive">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th note__title">Question</th>
                            <th scope="col" className="table__th note__username">Answer</th>
                            <th scope="col" className="table__th note__status">Status</th>
                            <th scope="col" className="table__th note__edit">Edit</th>
                        </tr>

                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </main>
        )
    }

    return content
}
export default HelpList