import { useParams } from 'react-router-dom'
import EditProductForm from './EditProductForm'
import { useGetProductsQuery } from './productsApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import LoadingSpinner from '../../components/LoadingSpinner'
// import useAuth from '../../hooks/useAuth'
// import PulseLoader from 'react-spinners/PulseLoader'

const EditProduct = () => {

    const { id } = useParams();

    // const { name, isManager, isAdmin } = useAuth()

    const { product } = useGetProductsQuery("productsList", {
        selectFromResult: ({ data }) => ({
            product: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!product || !users?.length) return <LoadingSpinner />


    // if (!isManager && !isAdmin) {
    //     if (note.name !== name) {
    //         return <p className="errmsg">No access</p>
    //     }
    // }

    const content = <EditProductForm product={product} users={users} />

    return content
}

export default EditProduct