import NewProductForm from './NewProductForm';
import { useGetUsersQuery } from '../users/usersApiSlice';
import LoadingSpinner from "../../components/LoadingSpinner";
// import PulseLoader from 'react-spinners/PulseLoader';

const NewProduct = () => {

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    // if (!users?.length) return <PulseLoader color={"#FFF"} />
    // if (users?.length === 0) return <LoadingSpinner />
    // if (!users?.length) return <LoadingSpinner />
    
    if (!users?.length) {
        return <LoadingSpinner />
    }

    const content = <NewProductForm users={users} />

    return content
}

export default NewProduct