import { useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReservationSteps from '../../components/ReservationSteps';
// import LoadingSpinner from "../../components/LoadingSpinner";
import { useAddNewReservationMutation } from "./reservationsApiSlice";
import useAuth from '../../hooks/useAuth';

function ReservationSummary() {
    const [addNewReservation, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewReservationMutation();

    const { email } = useAuth();

    const card = useSelector((state) => state.card);

    // const { cardItems, reservationService, reservationLocation, reservationDate, reservationClientInfo } = useSelector((state) => state.card);

    const { cardItems, totalPrice, reservationLocation, reservationDate, reservationClientInfo } = useSelector((state) => state.card);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (card.cardItems.length === 0) {
            navigate('/reservation-services');
        } else if (!reservationLocation.locationReserved) {
            navigate('/reservation-location');
        } else if (!reservationDate.dateReserved) {
            navigate('/reservation-date');
        } else if (!reservationClientInfo.phone) {
            navigate('/reservation-client-info');
        }
    }, [navigate, card.cardItems.length, reservationLocation.locationReserved, reservationDate.dateReserved, reservationClientInfo.phone]);

    // useEffect(() => {
    //     if (!cardItems[0]?.titre) {
    //         navigate('/reservation-services');
    //     } else if (!reservationLocation.locationReserved) {
    //         navigate('/reservation-location');
    //     } else if (!reservationDate.dateReserved) {
    //         navigate('/reservation-date');
    //     } else if (!reservationClientInfo.phone) {
    //         navigate('/reservation-client-info');
    //     }
    // }, [navigate, cardItems, reservationLocation.locationReserved, reservationDate.dateReserved, reservationClientInfo.phone]);

    useEffect(() => {
        if (isSuccess) {
            localStorage.removeItem('card');
            navigate('/dashboard/reservation-confirmation');
        }
    }, [isSuccess, dispatch, navigate]);

    const reservationFulfilment = [cardItems.length === 0].every(Boolean) && !isLoading
    // const reservationFulfilment = [reservationService.length === 0].every(Boolean) && !isLoading
    
    const reservationSummaryHandler = async (e) => {
        e.preventDefault();
        await addNewReservation({
            email,
            reservationServices: cardItems,
            reservationLocation,
            reservationDate,
            reservationClientInfo,
            totalPrice
        });
    }

    return (
        <> 
            <div className='container py-5'>
                <ReservationSteps step1 step2 step3 step4 step5 step6 />
                <div className="text-center py-3">
                    <h1>Réservation - Sommaire</h1>
                    <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Nous vous demandons de vérifier les informations ci-dessus. Si vous avez des modifications à apporter, veuillez les corriger.
                        </p>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div>
                        <ul className="list-group h6">
                            {card.cardItems.length === 0 && <h4>Votre panier est vide | Your cart is empty!</h4>}
                            {card?.cardItems.map((item, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between lh-sm py-3">
                                    <div>
                                        <i className="bi bi-calendar2-check"></i> {' '}
                                        Service: {item.titre} ({item.qty} {item.qty > 1 ? 'personnes' : 'personne'} x ${Number(item.price).toFixed(2)})
                                    </div>
                                    {/* <span className="text-body-secondary fw-bold">${Number(item.qty * item.price).toFixed(2)}</span> */}
                                    <span>
                                        <NavLink to='/reservation-services'>
                                            <i className="bi bi-pencil-square"></i>
                                        </NavLink>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='pt-3'>
                        <ul className='list-group h6'>
                            <li className='list-group-item d-flex justify-content-between lh-sm py-3'>
                                <div>
                                    <i className="bi bi-geo-alt"></i> {' '}
                                    Endroit:  {reservationLocation?.locationReserved === "1" ? 'La Pyramide (2360 chemin St-Foy, Québec, G1V 4H2)' : 'Plaza Laval (2750 chemin St-Foy, Québec, G1V 1V6)'}
                                </div>
                                <span>
                                    <NavLink to='/reservation-location'>
                                        <i className="bi bi-pencil-square"></i>
                                    </NavLink>
                                </span>
                            </li>
                            <li className='list-group-item d-flex justify-content-between lh-sm py-3'>
                                <div>
                                    <i className="bi bi-clock-history"></i> {' '}
                                    Date: le {reservationDate?.dateReserved} à {reservationDate?.timeReserved}
                                </div>
                                <span>
                                    <NavLink to='/reservation-date'>
                                        <i className="bi bi-pencil-square"></i>
                                    </NavLink>
                                </span>
                            </li>
                            <li className='list-group-item d-flex justify-content-between lh-sm py-3'>
                                <div>
                                    <i className="bi bi-person-lines-fill"></i> {' '}
                                    Client Info: {reservationClientInfo?.client} (Tel: {reservationClientInfo?.phone})
                                </div>
                                <span>
                                    <NavLink to='/reservation-client-info'>
                                        <i className="bi bi-pencil-square"></i>
                                    </NavLink>
                                </span>
                            </li>
                            <li className='list-group-item d-flex justify-content-between lh-sm py-3'>
                                <div>
                                    <i className="bi bi-chat-square-text"></i> {' '}
                                    Commentaire: {reservationClientInfo?.comment}
                                </div>
                                <span>
                                    <NavLink to='/reservation-client-info'>
                                        <i className="bi bi-pencil-square"></i>
                                    </NavLink>
                                </span>
                            </li>
                        </ul>
                    </div>
                   
                    <button
                        type='button'
                        className='btn btn-info btn-lg text-uppercase my-5'
                        disabled={reservationFulfilment}
                        onClick={reservationSummaryHandler}
                    >
                        Passer la commande
                    </button>
                </div>
            </div>
        </>
    )
}

export default ReservationSummary