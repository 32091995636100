import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductOrderSteps from '../../components/ProductOrderSteps';

import { useCreateOrderMutation } from "./ordersApiSlice";
import { clearCartItems } from "../products/cartSlice";
import LoadingSpinner from "../../components/LoadingSpinner";

import useAuth from '../../hooks/useAuth';

function PlaceOrder() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { name, email } = useAuth();

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');

    const [paymentMethod, setPaymentMethod] = useState('Cash');

    const cart = useSelector((state) => state.cart);

    // const [addNewProduct, {
    //     isLoading,
    //     isSuccess,
    //     isError,
    //     error
    // }] = useAddNewProductMutation()

    const [createOrder, {isLoading, error}] = useCreateOrderMutation();

    // useEffect(() => {
    //     if (!cart.shippingAddress.address) {
    //         navigate('/shipping');
    //     } else if (!cart.paymentMethod) {
    //         navigate('/payment');
    //     }
    // }, [cart.shippingAddress, cart.paymentMethod, navigate]);

    const placeOrderHandler = async () => {
        try {
            const res = await createOrder({
                email,
                orderItems: cart.cartItems,
                shippingAddress: { address, city, postalCode, country },
                paymentMethod: paymentMethod,
                itemsPrice: cart.itemsPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
            }).unwrap(); // we add unwrap() since it return a promise

            dispatch(clearCartItems());
            navigate(`/order/${res._id}`);

        } catch (error) {
            console.error('Error submitting the order', error);
        }
    };

    return (
        <section className="container py-5">
            <h1 className="text-center">Livraison et Ramassage</h1>
            <hr className="my-4" />
            <div className="row g-5">
                <div className="col-md-5 col-lg-4 order-md-last">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                        <span>Résumé de la commande</span>
                        <span className="badge bg-primary rounded-pill">
                            {cart.cartItems.reduce((a, c) => a + c.qty, 0)}
                        </span>
                    </h4>

                    <ul className="list-group mb-3">
                        {cart.cartItems.length === 0 && <h4>Your cart is empty!</h4>}
                        {cart?.cartItems.map((item, index) => (
                            <li className="list-group-item d-flex justify-content-between lh-sm">
                                <div>
                                    <h6 className="my-0">{item.name}</h6>
                                    <small className="text-body-secondary">({item.qty} {item.qty > 1 ? 'articles' : 'article'} x ${Number(item.price).toFixed(2)})</small>
                                </div>
                                <span className="text-body-secondary fw-bold">${Number(item.qty * item.price).toFixed(2)}</span>
                            </li>
                        ))}
                    </ul>

                    <hr className="my-4" />
                    
                    {error && 
                             <ul className="list-group mb-3">
                             <li className="list-group-item d-flex justify-content-between lh-sm">
                                <h6 className="my-0">{error}</h6>
                                 {/* <div>
                                     <h6 className="my-0">{error}</h6>
                                     <small className="text-body-secondary"></small>
                                 </div>
                                 <span className="text-body-secondary fw-bold"></span> */}
                             </li>
                         </ul>
                    }
                   
                    <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0">Prix des articles </h6>
                                <small className="text-body-secondary">Avant taxes</small>
                            </div>
                            <span className="text-body-secondary">${cart.itemsPrice}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0">Frais de livraison</h6>
                                <small className="text-body-secondary">Gratuit à l'achat de plus 100 dollars</small>
                            </div>
                            <span className="text-body-secondary">${cart.shippingPrice}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                            <div>
                                <h6 className="my-0">Taxes</h6>
                                <small className="text-body-secondary">(TPS et TVQ)</small>
                            </div>
                            <span className="text-body-secondary">${cart.taxPrice}</span>
                        </li>
                        {/* <li className="list-group-item d-flex justify-content-between bg-body-tertiary">
                            <div className="text-success">
                                <h6 className="my-0">Promo code</h6>
                                <small>EXAMPLECODE</small>
                            </div>
                            <span className="text-success">---</span>
                        </li> */}
                        <li className="list-group-item d-flex justify-content-between">
                            <span className="fw-bold">Total (CAD)</span>
                            <strong>${cart.totalPrice}</strong>
                        </li>
                    </ul>

                    <button 
                        className="w-100 btn btn-primary btn-lg" 
                        type="button"
                        onClick={placeOrderHandler}
                        disabled={cart.cartItems.length ===  0}
                    >
                        {isLoading ? (<LoadingSpinner />) : 'Passer à la caisse'}
                    </button>
                </div>
                <div className="col-md-7 col-lg-8">
                    <h4 className="mb-3">Livraison et rammassage</h4>
                    <form className="needs-validation" novalidate>
                        <div className="row g-3">
                            <div className="col-sm-6">
                                <label htmlhtmlFor="name" className="form-label">Prénom et nom de famille</label>
                                <input 
                                    id="name"
                                    name="name"
                                    type="text" 
                                    className="form-control" 
                                    value={name}
                                    disabled
                                />
                            </div>

                            <div className="col-sm-6">
                                <label htmlFor="email" className="form-label">Adresse courriel</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    value={email} 
                                    disabled
                                />
                            </div>

                            <div className="col-12">
                                <label htmlFor="address" className="form-label">Adresse</label>
                                <input
                                    id="address"
                                    name="address"
                                    type="text" 
                                    className="form-control" 
                                    placeholder=""
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter your shipping address.
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="city" className="form-label">Ville</label>
                                <select
                                    id="city"
                                    className="form-select"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                >
                                    <option value="">Sélectionner...</option>
                                    <option value="Quebec" selected>Quebec</option>
                                </select>
                                <div className="invalid-feedback">
                                    Please provide a valid state.
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="postalCode" className="form-label">Code postal</label>
                                <input
                                    id="postalCode"
                                    name="postalCode"
                                    type="text" 
                                    className="form-control" 
                                    placeholder=""
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Postal code required.
                                </div>
                            </div>

                            <div className="col-md-5">
                                <label htmlFor="country" className="form-label">Pays</label>
                                <select
                                    id="country"
                                    className="form-select"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                >
                                    <option value="">Sélectionner...</option>
                                    <option value="Canada" selected>Canada</option>
                                </select>
                                <div className="invalid-feedback">
                                    Please select a valid country.
                                </div>
                            </div>
                        </div>

                        <hr className="my-4" />

                        <h4 className="mb-3">Mode de paiement</h4>

                        <div className="mb-3">
                            {/* <label htmlFor="legend" className="form-label me-3 fs-4">Sélectionner votre mode de paiement</label> */}
                            <div className="form-check">
                                <input 
                                    id="Stripe" 
                                    name="paymentMethod" 
                                    type="radio" 
                                    className="form-check-input"
                                    value="Stripe"
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    required
                                />
                                <label className="form-check-label" htmlFor="Stripe">Card de crédit</label>
                            </div>
                            <div className="form-check my-3">
                                <input 
                                    id="PayPal" 
                                    name="paymentMethod" 
                                    type="radio" 
                                    className="form-check-input" 
                                    value="PayPal"
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    required 
                                />
                                <label className="form-check-label" htmlFor="PayPal">PayPal</label>
                            </div>
                            <div className="form-check">
                                <input 
                                    id="Cash" 
                                    name="paymentMethod" 
                                    type="radio" 
                                    className="form-check-input"
                                    value="Cash"
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    required
                                    checked
                                />
                                <label className="form-check-label" htmlFor="Cash">Paiement à la livraison</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
};

export default PlaceOrder